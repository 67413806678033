import React, { useEffect, useState } from "react";
import { auth } from "../../../../firebase";
import axios from "axios";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCardTitle,
  CCol,
  CRow,
  CSpinner,
  CTable,
  CTableBody,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CFormInput
} from "@coreui/react";
import { cilReload } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import AppConfirmModal from "../../../../components/AppConfirmModal";
import LiscenceKeyModel from "../../../../components/LiscenceKeyModel";
import AppMessageModal from "../../../../components/AppMessageModal";
import UpdateUser from "./UpdateUser";
import { AiFillEye } from 'react-icons/ai';
import { MdDeleteForever } from 'react-icons/md';
import { CiEdit } from 'react-icons/ci';
import { deleteUserNotificationToken, createLiscenceKey, updateLiscenceKey, deleteRegisteredSystem } from "../../../../utils/api";

const AllUsers = () => {
  const [loading, setLoading] = useState(false);
  const [loadingForLiscence, setLoadingForLiscence] = useState(false);
  const [userList, setUserList] = useState(undefined);
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");
  const [confirmModal, setConfirmModal] = useState(false);
  const [messageModal, setMessageModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [activeFirebaseID, setActiveFirebaseID] = useState(undefined);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredUserList, setFilteredUserList] = useState([]);
  const [updateUser, setUpdateUser] = useState(false);
  const [updateFirebaseID, setUpdateFirebaseID] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [LiscenceKey, setLiscenceKey] = useState("");
  const [LiscenceKeyId, setLiscenceKeyId] = useState("");
  const [LiscenceKeyMessage, setLiscenceKeyMessage] = useState("");
  const [updateMessage, setUpdateMessage] = useState("");

  useEffect(() => {
    getUserList();
  }, []);

  useEffect(() => {
    handleSearch();
  }, [searchTerm]);

  async function getUserList() {
    setUserList(undefined);
    setLoading(true);
    let company_id = window.location.href.split("/").pop();
    try {
      await new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          } else {
            reject(new Error("User is not authenticated."));
          }
        });
      });

      let authToken = await auth.currentUser.getIdToken();
      let result = await axios.get(
        window.config.commonEndpoint + "input/all_users",
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      setFilteredUserList(result.data.data.users);
      setUserList(result.data.data.users);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  const handleSearch = () => {
    if (userList) {
      const filteredUserList = userList.filter((user) => {
        const name = `${user.first_name || ""} ${user.last_name || ""}`;
        return (
          name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          (user.email &&
            user.email.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (user.phone_number && user.phone_number.includes(searchTerm)) ||
          user.user_type.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.user_company_type.toLowerCase().includes(searchTerm.toLowerCase()) || 
          (user?.identification_address && user?.identification_address.includes(searchTerm))
        );
      });
      setFilteredUserList(filteredUserList);
      setPage(0);
    }
  };

  function handleDeleteUser(firebaseID) {
    setActiveFirebaseID(firebaseID);
    setMessage("Are you sure you want to delete this user?");
    setTitle("Delete User");
    setConfirmModal(true);
  }
  const openModal = async (userId) => {
    setIsModalOpen(true);
    setLoadingForLiscence(true)
    const payload = {
      userId: userId,
      generatedBy: localStorage.getItem('user_id')
    }
    const response = await createLiscenceKey(payload)
    if (response.data.status) {
      setLiscenceKeyMessage("Successfully got License Key")
      setLiscenceKey(response?.data?.data?.liscence_key)
    }
    setLoadingForLiscence(false)
  };

  const updateliscenceKey = async () => {
    const payload = {
      liscence_key: LiscenceKey
    }
    const response = await updateLiscenceKey(payload)
    if (response.data.status) {
      setLiscenceKey('')
      setLiscenceKeyMessage('')
      setUpdateMessage(response.data.message)
    }
  };
  async function deleteUser() {
    setDeleteLoading(true);
    setConfirmModal(false);
    try {
      await new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          } else {
            reject(new Error("User is not authenticated."));
          }
        });
      });

      let authToken = await auth.currentUser.getIdToken();
      //   console.log({
      //     firebase_user_id: activeFirebaseID,
      //   });
      let result = await axios.post(
        window.config.commonEndpoint + "input/delete_user_firebase_id",
        { firebase_user_id: activeFirebaseID },
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      if (result.status === 200) {
        setMessage("User deleted successfully.");
        setTitle("Success");
        setMessageModal(true);
        setDeleteLoading(false);
      }
      //   console.log(result);
      getUserList();
    } catch (error) {
      setMessage("Something went wrong. Please try again later.");
      setTitle("Error");
      setMessageModal(true);
      setDeleteLoading(false);
    }
  }

  function handleConfirm() {
    setUpdateUser(false);
    getUserList();
  }
  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onHandleNotificationToken = (firebase_user_id) => {
    console.log(firebase_user_id);
    setActiveFirebaseID(firebase_user_id);
    setMessage("Are you sure you want to delete user notification token ?");
    setTitle("Delete Notification Token");
    setConfirmModal(true);
  }

  const onHandleRegisteredSystem = (liscence_key) => {
    setLiscenceKeyId(liscence_key);
    setMessage("Are you sure you want to delete this device ID ?");
    setTitle("Delete Device ID");
    setConfirmModal(true);
  }

  const onDeleteNotificationToken = async () => {
    console.log(activeFirebaseID);
    setConfirmModal(false);
    try {
      const responce = await deleteUserNotificationToken(activeFirebaseID)
      if (responce.status === 200) {
        setMessage("User notification token deleted successfully.");
        setTitle("Success");
      } else if (responce?.response?.status === 400) {
        setMessage(responce?.response?.data?.message);
        setTitle("Warning");
      } else {
        setMessage("Something went wrong. Please try again later.");
        setTitle("Error");
      }
    } catch (error) {
      setMessage("Something went wrong. Please try again later.");
      setTitle("Error");
    }
    setMessageModal(true);
  }

  const deleteDeviceID = async () => {
    setConfirmModal(false);
    try {
      const responce = await deleteRegisteredSystem(LiscenceKeyId)
      if (responce.status === 200) {
        setMessage("Device ID deleted successfully.");
        setTitle("Success");
      } else if (responce?.response?.status === 400) {
        setMessage(responce?.response?.data?.message);
        setTitle("Warning");
      } else {
        setMessage("Something went wrong. Please try again later.");
        setTitle("Error");
      }
    } catch (error) {
      setMessage("Something went wrong. Please try again later.");
      setTitle("Error");
    }
    setMessageModal(true);
  }

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const visibleUserList = filteredUserList && filteredUserList.slice(startIndex, endIndex);
  return (
    <>
      <CCard>
        <CCardHeader>
          <CRow className="justify-items-between">
            <CCol xs={7}>
              <CCardTitle>
                <h3 className="APP-COLOR">
                  <strong>Users List</strong>
                </h3>
              </CCardTitle>
            </CCol>
            <CCol xs={2}>
              <CButton color="light" onClick={() => getUserList()}>
                <CIcon size="lg" icon={cilReload} />
              </CButton>
            </CCol>
            <CCol xs={3}>
              <CCol>
                <CFormInput
                  className="mx-2"
                  type="text"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={(event) => setSearchTerm(event.target.value)}
                />
              </CCol>
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardBody>
          <CTable align="middle">
            <CTableHead style={{ background: "aliceblue" }}>
              <CTableRow>
                <CTableHeaderCell>S.No</CTableHeaderCell>
                <CTableHeaderCell>Name</CTableHeaderCell>
                <CTableHeaderCell>Email/Phone</CTableHeaderCell>
                <CTableHeaderCell>User Type</CTableHeaderCell>
                <CTableHeaderCell>Company</CTableHeaderCell>
                <CTableHeaderCell>Actions</CTableHeaderCell>
                <CTableHeaderCell>Notification Token</CTableHeaderCell>
                <CTableHeaderCell>License Key</CTableHeaderCell>
                <CTableHeaderCell>Desktop Device ID</CTableHeaderCell>
                <CTableHeaderCell>Remove Device ID</CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            {loading ? (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
                <CSpinner />
              </div>
            ) : (
              <CTableBody>
                {visibleUserList === undefined ||
                  visibleUserList.length === 0 ? (
                  <CTableHeaderCell>No data to display</CTableHeaderCell>
                ) : (
                  visibleUserList.map((user, index) => {
                    return (
                      <CTableRow key={index}>
                        <CTableHeaderCell>{startIndex + index + 1}</CTableHeaderCell>
                        <CTableHeaderCell>
                          {user.first_name + " " + user.last_name}
                        </CTableHeaderCell>
                        <CTableHeaderCell>
                          {user.email === null
                            ? user.phone_number
                            : `${user.email} ${user.phone_number ? ' / ' + user.phone_number : ''}`}
                        </CTableHeaderCell>
                        <CTableHeaderCell>{user.user_type}</CTableHeaderCell>
                        <CTableHeaderCell>{user.company_name ? user.company_name : '-'}</CTableHeaderCell>
                        <CTableHeaderCell>
                          <CButton
                            className="APP-BACKGROUND text-white"
                            variant="outline"
                            onClick={() => {
                              setUpdateUser(true);
                              setUpdateFirebaseID(user.firebase_user_id);
                            }}
                          >
                            <CiEdit size={22} />
                            {/* Update   */}
                            {/*cilXCircle*/}
                          </CButton>
                          <CButton
                            className="APP-BACKGROUND-2 APP-BORDER text-white"
                            variant="outline"
                            style={{ marginLeft: '10px' }}
                            onClick={() => {
                              handleDeleteUser(user.firebase_user_id);
                            }}
                          >
                            <MdDeleteForever size={22} />
                          </CButton>
                        </CTableHeaderCell>
                        <CTableHeaderCell>
                          <CButton
                            color="warning"
                            style={{ marginLeft: '10px' }}
                            onClick={() => {
                              onHandleNotificationToken(user.firebase_user_id);
                            }}
                          >
                            <MdDeleteForever size={22} />
                          </CButton>
                        </CTableHeaderCell>
                        <CTableHeaderCell>
                          <CButton
                            className="APP-BACKGROUND"
                            style={{ marginLeft: '10px' }}
                            onClick={() => {
                              openModal(user.firebase_user_id);
                            }}
                          >
                            <AiFillEye size={22} />
                          </CButton>
                        </CTableHeaderCell>
                        <CTableHeaderCell>
                          {user?.identification_address || '-'}
                        </CTableHeaderCell>
                        <CTableHeaderCell>
                          <CButton
                            className="APP-BACKGROUND-2 APP-BORDER text-white"
                            style={{ marginLeft: '10px' }}
                            onClick={() => {
                              onHandleRegisteredSystem(user.liscence_key);
                            }}
                          >
                            <MdDeleteForever size={22} />
                          </CButton>
                        </CTableHeaderCell>
                      </CTableRow>
                    );
                  })
                )}
              </CTableBody>
            )}
          </CTable>
          <LiscenceKeyModel
            isOpen={isModalOpen}
            loadingForLiscence={loadingForLiscence}
            onCancel={() => setIsModalOpen(false)}
            LiscenceKey={LiscenceKey}
            onConfirm={updateliscenceKey}
            title={title}
            message={LiscenceKeyMessage}
            updateMessage={updateMessage}
          />
          {filteredUserList && filteredUserList.length && (
            <div style={{ marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              <span style={{ marginRight: '10px' }}>Rows per page</span>
              <select value={rowsPerPage} onChange={handleChangeRowsPerPage} style={{ marginRight: '5px' }}>
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={50}>50</option>
              </select>
              <span style={{ margin: '0 10px' }}>
                {startIndex + 1}-{Math.min(endIndex, filteredUserList.length)} of {filteredUserList.length}
              </span>
              <button
                disabled={page === 0}
                onClick={() => handleChangePage(page - 1)}
                style={{ marginRight: '5px', fontWeight: 'bold', fontSize: '20px', backgroundColor: 'transparent', color: 'black', border: 'none', cursor: 'pointer' }}
              >
                {"<"}
              </button>
              <button
                disabled={endIndex >= filteredUserList.length}
                onClick={() => handleChangePage(page + 1)}
                style={{ marginLeft: '5px', padding: '4px', fontWeight: 'bold', fontSize: '20px', backgroundColor: 'transparent', color: 'black', border: 'none', cursor: 'pointer' }}
              >
                {">"}
              </button>
            </div>
          )}
        </CCardBody>
      </CCard>
      {/* Confirm Modal */}
      <AppConfirmModal
        isOpen={confirmModal}
        onCancel={() => setConfirmModal(false)}
        onConfirm={
          title === "Delete User" ? deleteUser :
            title === "Delete Notification Token" ? onDeleteNotificationToken :
              title === "Delete Device ID" ? deleteDeviceID :
                null
        }
        title={title}
        message={message}
      />
      {/* Message Modal */}
      <AppMessageModal
        isOpen={messageModal}
        handleClose={() => setMessageModal(false)}
        title={title}
        message={message}
      />
      {/* Update Company Modal */}
      <UpdateUser
        isOpen={updateUser}
        handleCancel={() => setUpdateUser(false)}
        handleConfirm={() => handleConfirm()}
        userId={updateFirebaseID}
      />
      {/* Confirm Modal */}
      {/* <AppConfirmModal isOpen={confirmModal} title={title} message={message} onCancel={() => setConfirmModal(false)} onConfirm={() => handleCompanyDelete()} /> */}
    </>
  );
};

export default AllUsers;
