import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//UI Elements
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CCardTitle,
  CImage,
  CFormSwitch,
  CFormLabel,
  CFormInput,
  CCollapse,
  CSpinner,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilReload } from "@coreui/icons";
//HTTP Libraries
import axios from "axios";
//Firebase
import { auth } from "../../../../firebase";
//Components
import AppMessageModal from "../../../../components/AppMessageModal";
import AppConfirmModal from "../../../../components/AppConfirmModal";

const GetDefaultMetadata = () => {
  const navigate = useNavigate();
  //States
  const [loading, setLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [companyMetadata, setCompanyMetadata] = useState("");

  //Functions
  useEffect(() => {
    getCompanyMetadata();
  }, []);

  async function getCompanyMetadata() {
    let company_id = window.location.href.split("/").pop();
    setLoading(true);
    try {
      await new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          } else {
            reject(new Error("User is not authenticated."));
          }
        });
      });

      let authToken = await auth.currentUser.getIdToken();
      let result = await axios.get(
        window.config.commonEndpoint + "scans/get_default_metadata",
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      setCompanyMetadata(result.data.data.metadata);
      console.log(result.data.data.metadata);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  async function deleteCompanyMetadata(id) {
    let company_id = window.location.href.split("/").pop();
    setLoading(true);
    try {
      await new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          } else {
            reject(new Error("User is not authenticated."));
          }
        });
      });

      let authToken = await auth.currentUser.getIdToken();
      let result = await axios.post(
        window.config.commonEndpoint + "scans/delete_default_metadata",
        { id: id },
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      console.log(result.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <CCard>
        <CCard>
          <CCardHeader>
            <CCardTitle>
              <CRow>
                <CCol>
                  <h3>
                    <strong>Default Metadata</strong>
                  </h3>
                </CCol>
                <CCol xs={2}>
                  <CButton color="light" onClick={() => getCompanyMetadata()}>
                    <CIcon size="lg" icon={cilReload} />
                  </CButton>
                </CCol>
              </CRow>
            </CCardTitle>
          </CCardHeader>
          <CCardBody>
            {loading ? (
              <CSpinner color="dark" />
            ) : (
              <CTable align="middle">
                <CTableHead>
                  <CTableRow>
                    <CTableHeaderCell>Metadata Value</CTableHeaderCell>
                    <CTableHeaderCell>Metadata Type</CTableHeaderCell>
                    <CTableHeaderCell>Metadata Key</CTableHeaderCell>
                    <CTableHeaderCell>-</CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                {loading ? (
                  <CSpinner color="dark" />
                ) : (
                  <CTableBody>
                    {companyMetadata === undefined ||
                    companyMetadata.length === 0 ? (
                      <CTableHeaderCell>No data to display</CTableHeaderCell>
                    ) : (
                      companyMetadata.map((metadata, index) => {
                        return (
                          <CTableRow key={index}>
                            <CTableDataCell>
                              {metadata.metadata_value}
                            </CTableDataCell>
                            <CTableDataCell>
                              {metadata.metadata_type}
                            </CTableDataCell>
                            <CTableDataCell>
                              {metadata.metadata_key}
                            </CTableDataCell>
                            <CTableDataCell>
                              <CButton
                                className="APP-BACKGROUND-2 APP-BORDER text-white"
                                onClick={() => {
                                  deleteCompanyMetadata(metadata.id);
                                }}
                              >
                                Delete
                              </CButton>
                            </CTableDataCell>
                          </CTableRow>
                        );
                      })
                    )}
                  </CTableBody>
                )}
              </CTable>
            )}
          </CCardBody>
        </CCard>
      </CCard>
      <br />
    </>
  );
};

export default GetDefaultMetadata;
