import { CCardBody, CFormInput, CFormLabel, CCard, CButton, CCardFooter, CRow, CCol, CSpinner } from "@coreui/react";
import axios from "axios";
import React, { Component, useState } from "react";
import { auth } from "../../../../firebase";
import CropsList from "../Crops";
import AppMessageModal from "../../../../components/AppMessageModal";
import AppConfirmModal from "../../../../components/AppConfirmModal";


const AddCrop = () => {
    const [cropName, setCropName] = useState('');
    const [cropVariety, setCropVariety] = useState('');
    const [loading, setLoading] = useState(false);
    const [messagetitle, setMessageTitle] = useState('');
    const [message, setMessage] = useState('');
    const [messageModal, setMessageModal] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);

    const changeCropName = (e) => {
        setCropName((e.target.value).trimStart());
    }

    const changeCropVariety = (e) => {
        setCropVariety((e.target.value).trimStart());
    }

    async function handleCropConfirm() {
        setConfirmModal(false);
        try {
            await new Promise((resolve, reject) => {
                auth.onAuthStateChanged((user) => {
                    if (user) {
                        resolve(user);
                    } else {
                        reject(new Error("User is not authenticated."));
                    }
                });
            });

            let authToken = await auth.currentUser.getIdToken();
            let result = await axios.post(window.config.commonEndpoint + 'crops/add/crop/master', { crop: cropName.trim(), variety: cropVariety.trim() }, { headers: { Authorization: `Bearer ${authToken}` } });
            if (result.status == 200) {
                setConfirmModal(false);
                setMessageTitle('Success');
                setMessage('Crop added successfully');
                setCropName('');
                setCropVariety('');
                setMessageModal(true);
            }
        } catch (error) {
            setMessageTitle('Error');
            setMessage('Error adding crop');
            setMessageModal(true);
        }
    }

    function handleCropAdded() {
        setConfirmModal();
    }

    async function createCrop() {
        if (cropName === '' || cropVariety === '') {
            setMessageTitle('Error');
            setMessage('Please enter crop name and variety fields');
            setMessageModal(true);
        } else if ((cropName.length <= 2 || cropName.length > 25) || (cropVariety.length <= 2 || cropVariety.length > 25)) {
            setMessageTitle('Error');
            setMessage('Please enter crop and its variety is length greater than 2 and less than 25.');
            setMessageModal(true);
        } else {
            setLoading(true);
            setMessageTitle('Confirm');
            setMessage('Are you sure you want to add this crop?');
            setConfirmModal(true);
            setLoading(false);
        }
    }


    return (
        <div style={{marginTop: 5}}>
            <CCard>
                <CCardBody>
                    <CRow>
                        <CCol>
                            <CFormLabel><h5>Enter Crop Name</h5></CFormLabel>
                            <CFormInput type="text" value={cropName} placeholder="Wheat,Chana..." onChange={changeCropName} />
                        </CCol>
                        <CCol>
                            <CFormLabel><h5>Enter Crop Variety</h5></CFormLabel>
                            <CFormInput type="text" value={cropVariety} placeholder="IR8, default" onChange={changeCropVariety} />
                        </CCol>
                    </CRow>
                </CCardBody>
                <CCardFooter>
                    <CButton disabled={!cropName || !cropVariety} color="success" onClick={() => createCrop()}>{loading ? <CSpinner color="light" /> : 'Add Crop'}</CButton>
                </CCardFooter>
            </CCard>
            <AppMessageModal message={message} title={messagetitle} handleClose={() => setMessageModal(false)} isOpen={messageModal} />
            <AppConfirmModal message={message} title={messagetitle} onCancel={() => setConfirmModal(false)} onConfirm={() => handleCropConfirm()} isOpen={confirmModal} />
        </div>
    );
}

export default AddCrop;