import React, { useEffect, useState } from "react";
import { auth } from "../../../../firebase";
import axios from "axios";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCardTitle,
  CCol,
  CRow,
  CSpinner,
  CTable,
  CTableBody,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CFormInput,
  CForm,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
  CContainer,
} from "@coreui/react";
import { cilReload } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import AppConfirmModal from "../../../../components/AppConfirmModal";
import AppMessageModal from "../../../../components/AppMessageModal";

const TradingUserList = () => {
  //States
  const [loading, setLoading] = useState(false);
  const [userList, setUserList] = useState(undefined);
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");
  const [confirmModal, setConfirmModal] = useState(false);
  const [messageModal, setMessageModal] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [roleUpdateLoading, setRoleUpdateLoading] = useState(false);
  const [activeCompany, setActiveCompany] = useState(undefined);
  const [activeFirebaseID, setActiveFirebaseID] = useState(undefined);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredUserList, setFilteredUserList] = useState([]);
  const [userRoles, setUserRoles] = useState({});

  //Functions
  useEffect(() => {
    getUserList();
  }, []);

  useEffect(() => {
    handleSearch();
  }, [searchTerm]);

  const handleUserRoleChange = (firebase_user_id, role) => {
    setUserRoles((prevUserRoles) => ({
      ...prevUserRoles,
      [firebase_user_id]: role,
    }));
  };

  async function getUserList() {
    setUserList(undefined);
    setLoading(true);
    let company_id = window.location.href.split("/").pop();
    try {
      await new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          } else {
            reject(new Error("User is not authenticated."));
          }
        });
      });

      let authToken = await auth.currentUser.getIdToken();
      let result = await axios.post(
        window.config.commonEndpoint + "company/get/users",
        { company_id: company_id },
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      setUserList(result.data.data.users);
      setFilteredUserList(result.data.data.users);
      // console.log(result.data.data.users);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  function handleApproveUser(firebaseID, companyID) {
    setActiveFirebaseID(firebaseID);
    setActiveCompany(companyID);
    setMessage("Are you sure you want to approve this user?");
    setTitle("Approve User");
    setConfirmModal(true);
  }

  function handleRejectUser(firebaseID, companyID) {
    setActiveFirebaseID(firebaseID);
    setActiveCompany(companyID);
    setMessage("Are you sure you want to reject this user?");
    setTitle("Reject User");
    setConfirmModal(true);
  }

  function handleEditUserRole(firebaseID, companyID) {
    setActiveFirebaseID(firebaseID);
    setActiveCompany(companyID);
    setMessage("Are you sure you want to edit this user's role?");
    setTitle("Edit User Role");
    setConfirmModal(true);
  }

  async function approveUser() {
    setApproveLoading(true);
    setConfirmModal(false);
    try {
      await new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          } else {
            reject(new Error("User is not authenticated."));
          }
        });
      });

      let authToken = await auth.currentUser.getIdToken();
      let result = await axios.post(
        window.config.commonEndpoint + "company/verify/user",
        { firebase_user_id: activeFirebaseID, company_id: activeCompany },
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      if (result.status === 200) {
        setMessage("User approved successfully.");
        setTitle("Success");
        setMessageModal(true);
        setApproveLoading(false);
      }
      console.log(result);
      getUserList();
    } catch (error) {
      setMessage("Something went wrong. Please try again later.");
      setTitle("Error");
      setMessageModal(true);
      setApproveLoading(false);
    }
  }

  async function rejectUser() {
    setRejectLoading(true);
    setConfirmModal(false);
    try {
      await new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          } else {
            reject(new Error("User is not authenticated."));
          }
        });
      });

      let authToken = await auth.currentUser.getIdToken();
      let result = await axios.post(
        window.config.commonEndpoint + "company/unverify/user",
        { firebase_user_id: activeFirebaseID, company_id: activeCompany },
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      if (result.status === 200) {
        setMessage("User rejected successfully.");
        setTitle("Success");
        setMessageModal(true);
        setRejectLoading(false);
      }
      console.log(result);
      getUserList();
    } catch (error) {
      setMessage("Something went wrong. Please try again later.");
      setTitle("Error");
      setMessageModal(true);
      setRejectLoading(false);
    }
  }

  const handleSearch = () => {
    if (userList) {
      const filteredUserList = userList.filter((user) => {
        const name = `${user.first_name || ""} ${user.last_name || ""}`;
        return (
          name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          (user.email &&
            user.email.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (user.phone_number && user.phone_number.includes(searchTerm))
        );
      });
      setFilteredUserList(filteredUserList);
    }
  };

  async function editUserRole(firebaseID, userRole) {
    setRoleUpdateLoading(true);
    console.log({ firebase_user_id: firebaseID, user_role: userRole });
    try {
      await new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          } else {
            reject(new Error("User is not authenticated."));
          }
        });
      });
      
      let authToken = await auth.currentUser.getIdToken();
      let result = await axios.post(
        window.config.endpoint + "update/user",
        { firebase_user_id: firebaseID, user_role: userRole },
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      console.log(result);
    } catch (error) {
      console.log(error);
      setRoleUpdateLoading(false);
    }
  }

  return (
    <>
      <div style={{ maxHeight: "430px", overflowY: "auto" }}>
        <CCard>
          <CCardHeader>
            <CRow className="justify-items-between">
              <CCol xs={7}>
                <CCardTitle>
                  <h3>
                    <strong>Trading Users List</strong>
                  </h3>
                </CCardTitle>
              </CCol>
              <CCol xs={2}>
                <CButton color="light" onClick={() => getUserList()}>
                  <CIcon size="lg" icon={cilReload} />
                </CButton>
              </CCol>
              <CCol xs={3}>
                <CCol>
                  <CFormInput
                    className="mx-2"
                    type="text"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={(event) => setSearchTerm(event.target.value)}
                  />
                </CCol>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <CTable align="middle">
              <CTableHead>
                <CTableRow>
                  <CTableHeaderCell>S.No</CTableHeaderCell>
                  <CTableHeaderCell>Name</CTableHeaderCell>
                  <CTableHeaderCell>Email/Phone</CTableHeaderCell>
                  <CTableHeaderCell>-</CTableHeaderCell>
                  <CTableHeaderCell>-</CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              {loading ? (
                <CSpinner color="dark" />
              ) : (
                <CTableBody>
                  {filteredUserList === undefined ||
                  filteredUserList.length === 0 ? (
                    <CTableHeaderCell>No data to display</CTableHeaderCell>
                  ) : (
                    filteredUserList.map((user, index) => {
                      const userRole =
                        userRoles[user.firebase_user_id] ||
                        user.user_role ||
                        "";
                      return (
                        <CTableRow key={index}>
                          <CTableHeaderCell>{index + 1}</CTableHeaderCell>
                          <CTableHeaderCell>
                            {user.first_name + " " + user.last_name}
                          </CTableHeaderCell>
                          <CTableHeaderCell>
                            {user.phone_number === null
                              ? user.email
                              : user.phone_number}
                          </CTableHeaderCell>
                          <CTableHeaderCell>
                            <CDropdown>
                              <CDropdownToggle color="secondary">
                                {userRole ? userRole : "Select Role"}
                              </CDropdownToggle>
                              <CDropdownMenu>
                                <CDropdownItem
                                  key={index}
                                  onClick={() =>
                                    handleUserRoleChange(
                                      user.firebase_user_id,
                                      "APMC-Admin"
                                    )
                                  }
                                >
                                  APMC-Admin
                                </CDropdownItem>
                                <CDropdownItem
                                  key={index}
                                  onClick={() =>
                                    handleUserRoleChange(
                                      user.firebase_user_id,
                                      "PACS-Admin"
                                    )
                                  }
                                >
                                  PACS-Admin
                                </CDropdownItem>
                                <CDropdownItem
                                  key={index}
                                  onClick={() =>
                                    handleUserRoleChange(
                                      user.firebase_user_id,
                                      "Trader"
                                    )
                                  }
                                >
                                  Trader
                                </CDropdownItem>
                                <CDropdownItem
                                  key={index}
                                  onClick={() =>
                                    handleUserRoleChange(
                                      user.firebase_user_id,
                                      "Farmer"
                                    )
                                  }
                                >
                                  Farmer
                                </CDropdownItem>
                              </CDropdownMenu>
                            </CDropdown>
                          </CTableHeaderCell>
                          <CTableHeaderCell>
                            <CButton
                              color="warning"
                              onClick={() =>
                                editUserRole(user.firebase_user_id, userRole)
                              }
                              disabled={roleUpdateLoading}
                            >
                              Edit Role
                            </CButton>
                          </CTableHeaderCell>
                        </CTableRow>
                      );
                    })
                  )}
                </CTableBody>
              )}
            </CTable>
          </CCardBody>
        </CCard>
        {/* Confirm Modal */}
        <AppConfirmModal
          isOpen={confirmModal}
          onCancel={() => setConfirmModal(false)}
          onConfirm={
            title === "Approve User" ? () => approveUser() : () => rejectUser()
          }
          title={title}
          message={message}
        />
        {/* Message Modal */}
        <AppMessageModal
          isOpen={messageModal}
          handleClose={() => setMessageModal(false)}
          title={title}
          message={message}
        />
        <br />
      </div>
    </>
  );
};

export default TradingUserList;
