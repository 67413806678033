import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//UI Elements
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CCardTitle,
  CImage,
  CFormSwitch,
  CFormLabel,
  CFormInput,
  CCollapse,
  CSpinner,
  CFormTextarea,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilReload } from "@coreui/icons";
//HTTP Libraries
import axios from "axios";
//Firebase
import { auth } from "../../../../firebase";
//Components
import AppMessageModal from "../../../../components/AppMessageModal";
import AppConfirmModal from "../../../../components/AppConfirmModal";

const WebhookDetails = (props) => {
  const navigate = useNavigate();
  //States
  const [loading, setLoading] = useState(true);
  const [eventEnabled, setEventEnabled] = useState(false);
  const [eventEndpoint, setEventEndPoint] = useState("");
  const [validateEnabled, setValidateEnabled] = useState(false);
  const [validateEndpoint, setValidateEndpoint] = useState("");
  const [webhookDetails, setWebhookDetails] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const [showKey, setShowKey] = useState(false);
  const [copied, setCopied] = useState(false);
  const [handleViaBody, setHandleViaBody] = useState(false);
  //Functions

  //Functions

  useEffect(() => {
    getWebHookDetails();
  }, []);

  async function getWebHookDetails() {
    let company_id = window.location.href.split("/").pop();
    setLoading(true);
    try {
      await new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          } else {
            reject(new Error("User is not authenticated."));
          }
        });
      });

      let authToken = await auth.currentUser.getIdToken();
      let result = await axios.post(
        window.config.commonEndpoint + "company/webhook/get/details",
        {
          company_id: company_id,
        },
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      setWebhookDetails(result.data.data.webhookDetails);
      if (result.data.data.webhookDetails) {
        setEventEnabled(result.data.data.webhookDetails.event_enabled);
        setEventEndPoint(result.data.data.webhookDetails.event_endpoint);
        setValidateEnabled(result.data.data.webhookDetails.validate_enabled);
        setValidateEndpoint(result.data.data.webhookDetails.validate_endpoint);
        setHandleViaBody(result.data.data.webhookDetails.handle_via_body);
        setSecretKey(result.data.data.webhookDetails.secret_key);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  function handleCopy() {
    navigator.clipboard.writeText(secretKey);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
  }

  async function addWebhookDetails() {
    let company_id = window.location.href.split("/").pop();
    setLoading(true);
    try {
      await new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          } else {
            reject(new Error("User is not authenticated."));
          }
        });
      });
      
      let authToken = await auth.currentUser.getIdToken();
      let result = await axios.post(
        window.config.commonEndpoint + "company/webhook/add/details",
        {
          company_id: company_id,
          event_enabled: eventEnabled,
          event_endpoint: eventEndpoint,
          validate_enabled: validateEnabled,
          validate_endpoint: validateEndpoint,
          handle_via_body: handleViaBody,
        },
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      console.log(result.data.data.webhookDetails);
      getWebHookDetails();
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  return (
    <>
      <CCard>
        <CCardHeader>
          <CRow className="justify-items-between">
            <CCol xs={7}>
              <CCardTitle>
                <CRow>
                  <CCol>
                    <h3>
                      <strong>Webhook Details</strong>
                    </h3>
                  </CCol>
                  <CCol xs={2}>
                    <CButton color="light" onClick={() => getWebHookDetails()}>
                      <CIcon size="lg" icon={cilReload} />
                    </CButton>
                  </CCol>
                </CRow>
              </CCardTitle>
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardBody>
          <br />
          <CRow>
            <CCol>
              <CFormLabel>
                <strong>Event Enabled</strong>
              </CFormLabel>
              <CFormSwitch
                disabled={webhookDetails !== null}
                label="Enable Event?"
                checked={eventEnabled}
                onChange={(e) => setEventEnabled(e.target.checked)}
              />
            </CCol>
            <CCol>
              <CFormLabel>
                <strong>Event Enpoint</strong>
              </CFormLabel>
              <CFormInput
                disabled={webhookDetails !== null}
                type="text"
                value={eventEndpoint}
                onChange={(e) => setEventEndPoint(e.target.value)}
                placeholder="Enter Event Endpoint"
              />
            </CCol>
          </CRow>
          <br />
          {webhookDetails && (
            <>
              <CRow>
                <CCol>
                  <CFormLabel>
                    <strong>Secret Key</strong>
                  </CFormLabel>
                  <CFormInput
                    placeholder={
                      showKey
                        ? webhookDetails.secret_key || ""
                        : (webhookDetails.secret_key || "")
                            .replace(/^(sk_[^_]*_).*/, "$1") // extract prefix up to second "_"
                            .concat(
                              (webhookDetails.secret_key || "")
                                .slice(11)
                                .replace(/./g, "*")
                            )
                    }
                    aria-label="Readonly textarea example"
                    readOnly
                  />
                </CCol>
              </CRow>
              <br />
              <CRow>
                <CCol>
                  <CButton onClick={() => setShowKey(!showKey)}>
                    {showKey ? "Hide" : "Reveal"} Key
                  </CButton>
                </CCol>
                <CCol>
                  <CButton color="success" onClick={handleCopy}>
                    {copied ? "Copied!" : "Copy"}
                  </CButton>
                </CCol>
              </CRow>
            </>
          )}
          <br />
          <CRow>
            <CCol>
              <CFormLabel>
                <strong>Validate Enabled</strong>
              </CFormLabel>
              <CFormSwitch
                disabled={webhookDetails !== null}
                label="Validate Enabled?"
                checked={validateEnabled}
                onChange={(e) => setValidateEnabled(e.target.checked)}
              />
            </CCol>
            <CCol>
              <CFormLabel>
                <strong>Validate Enpoint</strong>
              </CFormLabel>
              <CFormInput
                disabled={webhookDetails !== null}
                type="text"
                value={validateEndpoint}
                onChange={(e) => setValidateEndpoint(e.target.value)}
                placeholder="Enter Validate Endpoint"
              />
            </CCol>
          </CRow>
          <br />
          <CRow>
            <CCol>
              <CFormLabel>
                <strong>Handle Response via Body</strong>
              </CFormLabel>
              <CFormSwitch
                disabled={webhookDetails !== null}
                label="Response via Body"
                checked={handleViaBody}
                onChange={(e) => setHandleViaBody(e.target.checked)}
              />
            </CCol>
          </CRow>
          <br />
          {!webhookDetails ? (
            <CButton
              className="APP-BACKGROUND APP-BORDER-2"
              onClick={() => addWebhookDetails()}
              disabled={loading}
            >
              {loading ? <CSpinner color="light" /> : "Add Input"}
            </CButton>
          ) : (
            // <CButton
            //   color="danger"
            //   onClick={() => {
            //     console.log("delete clicked");
            //   }}
            // >
            //   Delete
            // </CButton>
            ""
          )}
        </CCardBody>
      </CCard>
      <br />
    </>
  );
};

export default WebhookDetails;
