import { CButton, CCol, CFormInput, CFormSelect, CFormLabel, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle, CRow } from "@coreui/react";
import React, { useState } from "react";
import AppMessageModal from "../../../../components/AppMessageModal";
import AppConfirmModal from "../../../../components/AppConfirmModal";
import { auth } from "../../../../firebase";
import axios from "axios";

const AddCropWiseWeightConfiguration = ({ isOpen, handleCancel, handleConfirm }) => {
    //States
    const [minRange, setMinRange] = useState('');
    const [maxRange, setMaxRange] = useState('');
    const [message, setMessage] = useState('');
    const [title, setTitle] = useState('');
    const [messageModal, setMessageModal] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);
    const [version, setVersion] = useState('');
    const [crop, setCrop] = useState('');
    const [variety, setVariety] = useState('');

    const handleVersion = event => {
        setVersion(event.target.value);
    };

    const handleCrop = event => {
        setCrop(event.target.value);
    };
    const versionOptions = [
        { value: "v1", label: "Version 1" },
        { value: "v2", label: "Version 2" },
        // Add more options as needed
    ];

    const cropOptions = [
        { value: "wheat", label: "Wheat" },
        { value: "corn", label: "Corn" },
        // Add more options as needed
    ];
    //Functions

    const handleMinRange = event => {
        const newMinRange = event.target.value;
        setMinRange(newMinRange);
    };

    const handleMaxRange = event => {
        const newMaxRange = event.target.value;
        setMaxRange(newMaxRange);
    };

    function handleMachineConfirm() {
        if (minRange === '') {
            setTitle('Error');
            setMessage('Machine Name cannot be empty');
            setMessageModal(true);
            return;
        }
        if (maxRange === '') {
            setTitle('Error');
            setMessage('Machine Code cannot be empty');
            setMessageModal(true);
            return;
        }
        setTitle('Confirm');
        setMessage('Are you sure you want to add this machine?');
        setConfirmModal(true);
    }
    const handleVariety = event => {
        setVariety(event.target.value);
      };
    const varietyOptions = [
        { value: 'variety1', label: 'Variety 1' },
        { value: 'variety2', label: 'Variety 2' },
        // Add more options as needed
      ];
    return (
        <>
            <CModal alignment="center" backdrop='static' size="lg" visible={isOpen} onClose={() => handleCancel()}>
                <CModalHeader onClose={() => handleCancel()}>
                    <CModalTitle><h3><strong>Add a New CropWise Weight Config</strong></h3></CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <CRow>
                        <CCol>
                            <CFormLabel>Version</CFormLabel>
                            <CFormSelect value={version} onChange={handleVersion}>
                                {versionOptions.map(option => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </CFormSelect>
                        </CCol>
                        <CCol>
                            <CFormLabel>Crop</CFormLabel>
                            <CFormSelect value={crop} onChange={handleCrop}>
                                {cropOptions.map(option => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </CFormSelect>
                        </CCol>
                        <CCol>
                            <CFormLabel>Variety</CFormLabel>
                            <CFormSelect value={variety} onChange={handleVariety}>
                                {varietyOptions.map(option => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </CFormSelect>
                        </CCol>
                        <CCol>
                            <CFormLabel>Min Range</CFormLabel>
                            <CFormInput value={minRange} onChange={handleMinRange} />
                        </CCol>
                        <CCol>
                            <CFormLabel>Max Range</CFormLabel>
                            <CFormInput value={maxRange} onChange={handleMaxRange} />
                        </CCol>
                    </CRow>
                </CModalBody>
                <CModalFooter>
                    <CButton color="success" onClick={() => handleMachineConfirm()} >Confirm</CButton>{' '}
                    <CButton color="danger" onClick={() => handleCancel()}>Cancel</CButton>
                </CModalFooter>
            </CModal>
            {/* Message Modal */}
            <AppMessageModal isOpen={messageModal} title={title} message={message} handleClose={() => setMessageModal(false)} />
            {/* Confirm Modal */}
            <AppConfirmModal isOpen={confirmModal} title={title} message={message} onCancel={() => setConfirmModal(false)} />
        </>
    );
}

export default AddCropWiseWeightConfiguration;