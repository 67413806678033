import { CButton, CCol, CFormInput, CFormLabel, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle, CRow } from "@coreui/react";
import React, { useState } from "react";
import AppMessageModal from "../../../../components/AppMessageModal";
import AppConfirmModal from "../../../../components/AppConfirmModal";
import { auth } from "../../../../firebase";
import axios from "axios";
import { createVersion } from "../../../../utils/api";
const AddVersion = ({ isOpen, handleCancel, handleConfirm }) => {
    //States
    const [machineName, setMachineName] = useState('');
    const [machineVersion, setMachineVersion] = useState('');
    const [message, setMessage] = useState('');
    const [title, setTitle] = useState('');
    const [messageModal, setMessageModal] = useState(false);
    const [messagetitle, setMessageTitle] = useState('');
    const [confirmModal, setConfirmModal] = useState(false);
    //Functions

    const handleMachineName = (e) => {
        setMachineName(e.target.value);
    }

    const handleMachineVersion = (e) => {
        setMachineVersion(e.target.value);
    }

    function handleMachineConfirm() {
        if (machineName === '') {
            setTitle('Error');
            setMessage('Machine Name cannot be empty');
            setMessageModal(true);
            return;
        }
        if (machineVersion === '') {
            setTitle('Error');
            setMessage('version cannot be empty');
            setMessageModal(true);
            return;
        }
        setTitle('Confirm');
        setMessage('Are you sure you want to add this version?');
        setConfirmModal(true);
    }

    async function createVersions() {
        try {
            const payload = {
                machine_version: machineName,
                machine_name: machineVersion
            }
            let result = await createVersion(payload)
            console.log(result, 'resultresultresultresultresult');
            // if (result.status == 200) {
            //     setTitle('Success');
            //     setMessage('Machine created successfully');
            //     setMessageModal(true);
            //     handleConfirm();
            // }
        } catch (error) {
            setTitle('Error');
            setMessage('Error while creating machine');
            setMessageModal(true);
        }
    }

    return (
        <>
            <CModal alignment="center" backdrop='static' size="lg" visible={isOpen} onClose={() => handleCancel()}>
                <CModalHeader onClose={() => handleCancel()}>
                    <CModalTitle><h3><strong>Add a new version</strong></h3></CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <CRow>
                        <CCol>
                            <CFormLabel>Machine Name</CFormLabel>
                            <CFormInput value={machineName} onChange={handleMachineName} placeholder="App, Easy etc." />
                        </CCol>
                        <CCol>
                            <CFormLabel>Machine Version</CFormLabel>
                            <CFormInput value={machineVersion} onChange={handleMachineVersion} placeholder="V1, V2 etc." />
                        </CCol>
                    </CRow>
                </CModalBody>
                <CModalFooter>
                    <CButton color="success" onClick={() => handleMachineConfirm()} >Confirm</CButton>{' '}
                    <CButton color="danger" onClick={() => handleCancel()}>Cancel</CButton>
                </CModalFooter>
            </CModal>
            {/* Message Modal */}
            <AppMessageModal isOpen={messageModal} title={title} message={message} handleClose={() => setMessageModal(false)} />
            {/* Confirm Modal */}
            <AppConfirmModal isOpen={confirmModal} title={title} message={message} onCancel={() => setConfirmModal(false)} onConfirm={() => createVersions()} />
        </>
    );
}

export default AddVersion;