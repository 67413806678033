import React, { useState, useEffect } from 'react';
import {
    CCard, CSpinner, CCardBody, CFormInput, CFormLabel, CButton, CCardTitle, CCardHeader, CCol, CRow, CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem
} from "@coreui/react";
import { getAllMachine, getCropInstructionConfigurations, createCropInstructionConfig } from "../../../../utils/api";
import AppMessageModal from "../../../../components/AppMessageModal";

function CropInstructionConfigurations() {
    const [allMachines, setAllMachines] = useState([]);
    const [selectedMachine, setSelectedMachine] = useState(null);
    const [minThreshold, setMinThreshold] = useState(0);
    const [midThreshold, setMidThreshold] = useState(0);
    const [maxThreshold, setMaxThreshold] = useState(0);
    const [paperType, setPaperType] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [viewDetails, setViewDetails] = useState(false);
    const [messageModal, setMessageModal] = useState(false);
    const [messagetitle, setMessageTitle] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        getMachines();
    }, []);

    async function getMachines() {
        try {
            const result = await getAllMachine()
            setAllMachines(result?.data?.data?.machines);
        } catch (error) {
            console.log(error);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const payload = {
            crop_ids: [window.location.href.split("/").pop()],
            machine_code: selectedMachine.machine_code,
            min_threshold: minThreshold,
            mid_threshold: midThreshold,
            max_threshold: maxThreshold,
            paper_type: paperType
        };
        try {
            const response = await createCropInstructionConfig(payload);
            if (response?.status === 200) {
                setMessageTitle('Success');
                setMessage(response.data.message);
                setMessageModal(true);
            }
        } catch (error) {
            // Handle the error
        }
    };

    const ViewCropInstructionConfiguration = async () => {
        try {
            setLoading(true);
            setViewDetails(true)
            const crop_ids = window.location.href.split("/").pop()
            const machine_code = selectedMachine.machine_code
            const response = await getCropInstructionConfigurations([crop_ids], machine_code);
            if (response?.data?.data?.length > 0) {
                setMinThreshold(response.data?.data[0].min_threshold);
                setMidThreshold(response.data?.data[0].mid_threshold);
                setMaxThreshold(response.data?.data[0].max_threshold);
                setPaperType(response.data?.data[0].paper_type);
                setEditMode(true);
            } else {
                setEditMode(false);
                setMinThreshold();
                setMidThreshold();
                setMaxThreshold();
                setPaperType('');
            }
            setLoading(false);
        } catch (error) {
            // Handle the error
        }
    };
    const toggleEditMode = () => {
        setEditMode(!editMode);
    };
    return (
        <div>
            <AppMessageModal message={message} title={messagetitle} handleClose={() => setMessageModal(false)} isOpen={messageModal} />
            <CCard className="mt-4 mb-2">
                <CCardHeader>
                    <CRow className="justify-items-between">
                        <CCol>
                            <CCardTitle>
                                <h3>
                                    <strong>Default Crop Instruction Configurations</strong>
                                </h3>
                            </CCardTitle>
                        </CCol>
                    </CRow>
                </CCardHeader>
                <CCardBody>
                    <CCol>
                        <CDropdown>
                            <CDropdownToggle color="secondary">
                                {selectedMachine
                                    ? `${selectedMachine.machine_name} - ${selectedMachine.machine_code}`
                                    : "Select Machine"}
                            </CDropdownToggle>
                            <CDropdownMenu>
                                {allMachines &&
                                    allMachines.map((machine, index) => (
                                        <CDropdownItem
                                            key={index}
                                            onClick={() => setSelectedMachine(machine)}
                                        >
                                            {machine.machine_name} - {machine.machine_code}
                                        </CDropdownItem>
                                    ))}
                            </CDropdownMenu>
                        </CDropdown>
                        <CButton color="primary" style={{ marginLeft: '20px' }} onClick={ViewCropInstructionConfiguration}>View</CButton>
                    </CCol>
                    {
                        loading ?
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
                                <CSpinner color="dark" />
                            </div>
                            :
                            <>
                                {viewDetails &&
                                    <div className="mt-4 d-flex justify-content-between align-items-center">
                                        <div>
                                            <CFormLabel className="mr-2">Min Threshold</CFormLabel><span className="text-danger">*</span>
                                            <CFormInput type="number" value={minThreshold} onChange={(e) => setMinThreshold(e.target.value)} disabled={editMode} />
                                        </div>
                                        <div>
                                            <CFormLabel className="mr-2">Mid Threshold</CFormLabel><span className="text-danger">*</span>
                                            <CFormInput type="number" value={midThreshold} onChange={(e) => setMidThreshold(e.target.value)} disabled={editMode} />
                                        </div>
                                        <div>
                                            <CFormLabel className="mr-2">Max Threshold</CFormLabel><span className="text-danger">*</span>
                                            <CFormInput type="number" value={maxThreshold} onChange={(e) => setMaxThreshold(e.target.value)} disabled={editMode} />
                                        </div>
                                        <div>
                                            <CFormLabel className="mr-2">Paper Type</CFormLabel><span className="text-danger">*</span>
                                            <select className="form-control" value={paperType} onChange={(e) => setPaperType(e.target.value)} disabled={editMode}>
                                                <option value="">Select</option>
                                                <option value="A4">A4</option>
                                                <option value="A5">A5</option>
                                            </select>
                                        </div>
                                        {editMode ?
                                            <CButton className="mt-4" type="submit" color="primary" onClick={toggleEditMode}>
                                                Edit
                                            </CButton> :
                                            <CButton className="mt-4" type="submit" color="primary" onClick={handleSubmit} disabled={!minThreshold || !midThreshold || !maxThreshold || !paperType}>
                                                Save
                                            </CButton>}
                                    </div>
                                }
                            </>
                    }
                </CCardBody>
            </CCard>
        </div>
    );
}

export default CropInstructionConfigurations;
