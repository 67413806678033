import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CCardTitle,
  CImage,
  CFormSwitch,
  CFormLabel,
  CFormInput,
  CCollapse,
  CSpinner,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
  CContainer,
} from "@coreui/react";
import React, { useState, useEffect } from "react";
import CIcon from "@coreui/icons-react";
import { cilReload } from "@coreui/icons";
//HTTP Libraries
import axios from "axios";
//Firebase
import { auth } from "../../../../firebase";

const GetNewRequest = () => {
  const [loading, setLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [allMachines, setAllMachines] = useState([]);
  const [requests, setRequests] = useState("");
  const [selectedMachine, setSelectedMachine] = useState(null);

  useEffect(() => {
    getMachines();
  }, []);

  async function getRequests() {
    let crop_id = window.location.href.split("/").pop();
    setLoading(true);
    try {
      await new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          } else {
            reject(new Error("User is not authenticated."));
          }
        });
      });

      let authToken = await auth.currentUser.getIdToken();
      // console.log(authToken);

      let result = await axios.post(
        window.config.commonEndpoint + "crops/get/request_configurations",
        {
          crop_id: crop_id,
          machine_code: selectedMachine.machine_code,
        },
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      setLoading(false);
      console.log(result.data.data.requestConfigurations);
      setRequests(result.data.data.requestConfigurations);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  async function getMachines() {
    let crop_id = window.location.href.split("/").pop();

    try {
      await new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          } else {
            reject(new Error("User is not authenticated."));
          }
        });
      });
      
      let authToken = await auth.currentUser.getIdToken();
      let result = await axios.get(
        window.config.commonEndpoint + "machines/all",
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      // console.log(result.data.data);
      setAllMachines(result.data.data.machines);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div>
      <CCard>
        <CCard>
          <CCardHeader>
            <CCardTitle>
              <CRow>
                <CCol>
                  <h3>
                    <strong>Get Requests</strong>
                  </h3>
                </CCol>
                <CCol xs={2}>
                  {/* <CButton color="light" onClick={() => getAllMachines()}>
                    <CIcon size="lg" icon={cilReload} />
                  </CButton> */}
                </CCol>
              </CRow>
              <CContainer>
                <CRow>
                  <CCol>
                    <CDropdown>
                      <CDropdownToggle color="secondary">
                        {selectedMachine
                          ? `${selectedMachine.machine_name} - ${selectedMachine.machine_code}`
                          : "Select Machine"}
                      </CDropdownToggle>
                      <CDropdownMenu>
                        {allMachines &&
                          allMachines.map((machine, index) => (
                            <CDropdownItem
                              key={index}
                              onClick={() => setSelectedMachine(machine)}
                            >
                              {machine.machine_name} - {machine.machine_code}
                            </CDropdownItem>
                          ))}
                      </CDropdownMenu>
                    </CDropdown>
                  </CCol>
                  <CCol>
                    <CButton
                      color="success"
                      onClick={() => getRequests()}
                      disabled={loading}
                    >
                      {loading ? <CSpinner color="light" /> : "Get New Request"}
                    </CButton>
                  </CCol>
                </CRow>
              </CContainer>
            </CCardTitle>
          </CCardHeader>
          <CCardBody>
            {loading ? (
              <CSpinner color="dark" />
            ) : (
              <CTable align="middle">
                <CTableHead>
                  <CTableRow>
                    <CTableHeaderCell>Display Name</CTableHeaderCell>
                    <CTableHeaderCell>Key Name</CTableHeaderCell>
                    <CTableHeaderCell>Value</CTableHeaderCell>
                  </CTableRow>
                </CTableHead>

                {loading ? (
                  <CSpinner color="dark" />
                ) : (
                  <CTableBody>
                    {requests === undefined || requests.length === 0 ? (
                      <CTableHeaderCell>No data to display</CTableHeaderCell>
                    ) : (
                      requests.map((req, index) => {
                        return (
                          <CTableRow key={index}>
                            <CTableDataCell>{req.display_name}</CTableDataCell>
                            <CTableDataCell>{req.key_name}</CTableDataCell>
                            <CTableDataCell>{req.values.join(", ")}</CTableDataCell>
                          </CTableRow>
                        );
                      })
                    )}
                  </CTableBody>
                )}
              </CTable>
            )}
          </CCardBody>
        </CCard>
      </CCard>
      <br />
      <br />
    </div>
  );
};

export default GetNewRequest;
