import {
  CCol,
  CContainer,
  CRow,
  CNavbar,
  CNavbarBrand,
  CButton,
} from "@coreui/react";
import React, { useState, useEffect } from "react";
import GetDefaultMetadata from "./Components/GetDefaultMetadata";
import AddDefaultMetadata from "./Components/AddDefaultMetadata";

const DefaultMetadata = () => {
  return (
    <CContainer>
      <CRow>
        <CCol>
          <AddDefaultMetadata />
        </CCol>
        <CCol>
          <GetDefaultMetadata />
        </CCol>
      </CRow>
    </CContainer>
  );
};

export default DefaultMetadata;
