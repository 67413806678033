import { CCol, CContainer, CRow, CNavbar, CNavbarBrand } from "@coreui/react";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { auth } from "../../../firebase";
import GetNewRequest from "./components/GetRequestConfig";
import AddRequestConfig from "./components/AddRequestConfig";
import { useLocation } from "react-router-dom";
import DefaultClassConfigurations from "./components/DefaultClassConfigurations";
import DefaultResponseConfigurations from "./components/DefaultResponseConfigurations"
import CropInstructionConfigurations from './components/cropInstructionConfigurations'
const CropsListInfo = () => {
  const { state } = useLocation();
  const { crop_name, crop_variety } = state;
  const dispatch = useDispatch();
  const headerTitle = useSelector((state) => state.headerTitle);

  useEffect(() => {
    getCropDetails();
    return () => {
      dispatch({ type: "setHeaderTitle", headerTitle: "" });
    };
  }, []);

  async function getCropDetails() {

    let crop_id = window.location.href.split("/").pop();
    try {
      await new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          } else {
            reject(new Error("User is not authenticated."));
          }
        });
      });

      let authToken = await auth.currentUser.getIdToken();
      let result = await axios.get(
        window.config.commonEndpoint + "get/one/crop/" + crop_id,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      console.log(result.data);
      dispatch({
        type: "setHeaderTitle",
        headerTitle: result.data.data.crop.name,
      });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div>
      <CContainer>
        <p style={{ fontSize: "20px" }}>
          Crop: <span><strong>{crop_name}</strong></span>, Variety: <span><strong>{crop_variety}</strong></span>
        </p>
        <CRow>
          <CCol>
            <AddRequestConfig />
          </CCol>
          <CCol>
            <GetNewRequest />
          </CCol>
        </CRow>
        <CRow>
          <CropInstructionConfigurations/>
        </CRow>
        <CRow>
          <DefaultClassConfigurations crop_name={crop_name} />
        </CRow>
        <CRow>
          <DefaultResponseConfigurations crop_name={crop_name} />
        </CRow>
      </CContainer>
    </div>
  );
};

export default CropsListInfo;
