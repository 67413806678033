import { cilReload } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCardTitle,
  CContainer,
  CSpinner,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CFormInput
} from "@coreui/react";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { auth } from "../../../firebase";
import AppConfirmModal from "../../../components/AppConfirmModal";
import AppMessageModal from "../../../components/AppMessageModal";
import AddClass from "./components/AddClass";
import UpdateClass from "./components/UpdateClass";
import { MdDeleteForever } from 'react-icons/md';
import { deleteMasterClass } from "../../../utils/api";
import { CiEdit } from 'react-icons/ci';
const CropClasses = () => {
  //States
  const [loading, setLoading] = useState(false);
  const [cropClasses, setCropClasses] = useState(undefined);
  const [classModal, setClassModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredUserList, setFilteredUserList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [updateCropClass, setUpdateCropClass] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [cropClassId, setCropClassId] = useState(null);
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");
  const [confirmModal, setConfirmModal] = useState(false);
  const [messageModal, setMessageModal] = useState(false);
  const [language, setLanguage] = useState(null);
  const [loadingForDelete, setLoadingForDelete] = useState(false);
  //Functions
  useEffect(() => {
    getCropClasses();
  }, []);

  useEffect(() => {
    onhandleSearch();
  }, [searchTerm]);

  const onhandleSearch = () => {
    if (cropClasses) {
      const filteredData = cropClasses.filter((crop) => (
        crop.class_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        crop.class_id.toLowerCase().includes(searchTerm.toLowerCase()) ||
        crop.class_display_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        crop.class_code.toLowerCase().includes(searchTerm.toLowerCase()) ||
        crop.language.toLowerCase().includes(searchTerm.toLowerCase())
      ))
      setFilteredUserList(filteredData);
      setPage(0);
    }
  };
  async function getCropClasses() {
    setCropClasses(undefined);
    setLoading(true);
    try {
      await new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          } else {
            reject(new Error("User is not authenticated."));
          }
        });
      });

      let authToken = await auth.currentUser.getIdToken();
      let result = await axios.get(
        window.config.commonEndpoint + "crops/all/class/master",
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      setCropClasses(result.data.data.classes);
      setFilteredUserList(result.data.data.classes)
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }
  async function deleteCropClass() {
    setConfirmModal(false);
    setMessageModal(true);
    setLoadingForDelete(true)
    try {
      let result = await deleteMasterClass(cropClassId, language)
      if (result.status === 200) {
        setLoadingForDelete(false)
        setMessage(result?.data?.message);
        setTitle("Success");
      }
      if (result.status === 400) {
        setLoadingForDelete(false)
        setTitle("Error");
        setMessage(result?.data?.message);
      }
      getCropClasses();
    } catch (error) {
      setMessage("Something went wrong. Please try again later.");
      setTitle("Error")
    }
  }
  const handleButtonClick = (id) => {
    const selectedCrop = visibleCropList.find((crop) => crop.class_id === id);
    if (selectedCrop) {
      setUpdateCropClass(true);
      setSelectedRow(selectedCrop)
    }
  };
  function handleDeleteCropClass(id, language) {
    setCropClassId(id);
    setLanguage(language);
    setMessage("Are you sure you want to delete this Crop Class?");
    setTitle("Delete Crop Class");
    setConfirmModal(true);
  }
  function handleConfirm() {
    getCropClasses();
    setClassModal(false)
  }
  function handleConfirmForUpdate() {
    getCropClasses();
    setUpdateCropClass(false)
  }
  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const visibleCropList = filteredUserList && filteredUserList.slice(startIndex, endIndex);

  return (
    <>
      <CContainer>
        <CCard>
          <CCardHeader>
            <CCardTitle>
              <h3>
                <strong>Crop Classes</strong>
              </h3>
            </CCardTitle>
          </CCardHeader>
          <CCardBody style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
              <CButton
                style={{ marginRight: '10px' }}
                className="APP-BACKGROUND"
                onClick={() => setClassModal(true)}
              >
                Create new crop class
              </CButton>
              <CButton color="light" onClick={() => getCropClasses()}>
                <CIcon size="lg" icon={cilReload} />
              </CButton>
            </div>
            <CFormInput
              className="mx-2"
              type="text"
              style={{ width: '300px' }}
              placeholder="Search"
              value={searchTerm}
              onChange={(event) => setSearchTerm(event.target.value)}
            />
          </CCardBody>
          {/* <CCardHeader>
            <CCardTitle>
              <h3>
                <strong>Crop Classes List</strong>
              </h3>
            </CCardTitle>
          </CCardHeader> */}
          <CCardBody>
            {loading ? (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
                <CSpinner color="dark" />
              </div>
            ) : (
              <>
                <CTable align="middle">
                  <CTableHead style={{ background: "aliceblue" }}>
                    <CTableRow>
                      <CTableHeaderCell>S.No</CTableHeaderCell>
                      <CTableHeaderCell>Class Name</CTableHeaderCell>
                      <CTableHeaderCell>Class ID</CTableHeaderCell>
                      <CTableHeaderCell>Display Name</CTableHeaderCell>
                      <CTableHeaderCell>Class Code</CTableHeaderCell>
                      <CTableHeaderCell>Color</CTableHeaderCell>
                      <CTableHeaderCell>Language</CTableHeaderCell>
                      <CTableHeaderCell align="centre">Actions</CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>
                  {visibleCropList !== undefined ? (
                    <CTableBody>
                      {visibleCropList.map((item, index) => {
                        return (
                          <CTableRow key={index}>
                            <CTableHeaderCell>{startIndex + index + 1}</CTableHeaderCell>
                            <CTableDataCell>{item.class_name}</CTableDataCell>
                            <CTableDataCell>{item.class_id}</CTableDataCell>
                            <CTableDataCell>
                              {item.class_display_name}
                            </CTableDataCell>
                            <CTableDataCell>{item.class_code}</CTableDataCell>
                            <CTableDataCell>
                              <CContainer
                                style={{
                                  height: "20px",
                                  backgroundColor: item.color,
                                }}
                              ></CContainer>
                            </CTableDataCell>
                            <CTableDataCell>{item.language}</CTableDataCell>
                            <CTableDataCell>
                              <CButton style={{ marginLeft: '15px' }} className="APP-BACKGROUND">More Actions</CButton>
                              <CButton
                                className="APP-BACKGROUND text-white"
                                variant="outline"
                                style={{ marginLeft: '15px' }}
                                onClick={() => handleButtonClick(item.class_id)}
                              >
                                <CiEdit size={22} />
                              </CButton>
                              <CButton
                                className="APP-BACKGROUND-2 APP-BORDER text-white"
                                variant="outline"
                                style={{ marginLeft: '15px' }}
                                onClick={() => {
                                  handleDeleteCropClass(item.class_id, item.language);
                                }}
                              >
                                <MdDeleteForever size={22} />
                              </CButton>
                            </CTableDataCell>
                          </CTableRow>
                        );
                      })}
                    </CTableBody>
                  ) : (
                    <CTableHeaderCell>No Data to display</CTableHeaderCell>
                  )}
                </CTable>
                {filteredUserList && filteredUserList.length && (
                  <div style={{ marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <span style={{ marginRight: '10px' }}>Rows per page</span>
                    <select value={rowsPerPage} onChange={handleChangeRowsPerPage} style={{ marginRight: '5px' }}>
                      <option value={5}>5</option>
                      <option value={10}>10</option>
                      <option value={50}>50</option>
                    </select>
                    <span style={{ margin: '0 10px' }}>
                      {startIndex + 1}-{Math.min(endIndex, filteredUserList.length)} of {filteredUserList.length}
                    </span>
                    <button
                      disabled={page === 0}
                      onClick={() => handleChangePage(page - 1)}
                      style={{ marginRight: '5px', fontWeight: 'bold', fontSize: '20px', backgroundColor: 'transparent', color: 'black', border: 'none', cursor: 'pointer' }}
                    >
                      {"<"}
                    </button>
                    <button
                      disabled={endIndex >= filteredUserList.length}
                      onClick={() => handleChangePage(page + 1)}
                      style={{ marginLeft: '5px', padding: '4px', fontWeight: 'bold', fontSize: '20px', backgroundColor: 'transparent', color: 'black', border: 'none', cursor: 'pointer' }}
                    >
                      {">"}
                    </button>
                  </div>
                )}
              </>
            )}
          </CCardBody>
        </CCard>
      </CContainer>
      {/* Add Class Modal */}
      <AppMessageModal
        loading={loadingForDelete}
        isOpen={messageModal}
        handleClose={() => setMessageModal(false)}
        title={title}
        message={message}
      />
      <AppConfirmModal
        isOpen={confirmModal}
        onCancel={() => setConfirmModal(false)}
        onConfirm={deleteCropClass}
        title={title}
        message={message}
      />
      <AddClass
        isOpen={classModal}
        setClassModal={setClassModal}
        handleConfirm={() => handleConfirm()}
      />
      <UpdateClass
        isOpen={updateCropClass}
        handleCancel={() => setUpdateCropClass(false)}
        selectedCrop={selectedRow}
        handleConfirm={() => handleConfirmForUpdate()}
      />
    </>
  );
};

export default CropClasses;
