import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//UI Elements
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CCardTitle,
  CImage,
  CFormSwitch,
  CFormLabel,
  CFormInput,
  CCollapse,
  CSpinner,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilReload } from "@coreui/icons";
//HTTP Libraries
import axios from "axios";
//Firebase
import { auth } from "../../../../firebase";
//Components
import AppMessageModal from "../../../../components/AppMessageModal";
import AppConfirmModal from "../../../../components/AppConfirmModal";

const CompanyGetCustomInput = ({ isRefreshCustomInputData, setIsRefreshCustomInputData }) => {
  const navigate = useNavigate();
  //States
  const [loading, setLoading] = useState(true);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [customInputs, setCustomInputs] = useState("");

  //Functions
  useEffect(() => {
    getCustomField();
  }, []);

  useEffect(() => {
    if (isRefreshCustomInputData) {
      getCustomField();
      setIsRefreshCustomInputData(false)
    }
  }, [isRefreshCustomInputData]);

  async function getCustomField() {
    let company_id = window.location.href.split("/").pop();
    setLoading(true);
    try {
      await new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          } else {
            reject(new Error("User is not authenticated."));
          }
        });
      });

      let authToken = await auth.currentUser.getIdToken();
      let result = await axios.post(
        window.config.commonEndpoint + "company/webhook/get/custom_inputs",
        { company_id: company_id },
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      setCustomInputs(result.data.data.customInputs);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <CCard>
        <CCard>
          <CCardHeader>
            <CCardTitle>
              <CRow>
                <CCol>
                  <h3>
                    <strong>Custom Inputs</strong>
                  </h3>
                </CCol>
                <CCol xs={2}>
                  <CButton color="light" onClick={() => getCustomField()}>
                    <CIcon size="lg" icon={cilReload} />
                  </CButton>
                </CCol>
              </CRow>
            </CCardTitle>
          </CCardHeader>
          <CCardBody>
            {loading ? (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
                <CSpinner color="dark" />
              </div>
            ) : (
              <div style={{ maxHeight: "150px", overflowY: "auto" }}>
                <CTable align="middle">
                  <CTableHead>
                    <CTableRow>
                      <CTableHeaderCell>Display Name</CTableHeaderCell>
                      <CTableHeaderCell>Field Type</CTableHeaderCell>
                      <CTableHeaderCell>Key Name</CTableHeaderCell>
                      {/* <CTableHeaderCell>-</CTableHeaderCell> */}
                    </CTableRow>
                  </CTableHead>
                  {loading ? (
                    <CSpinner color="dark" />
                  ) : (
                    <CTableBody>
                      {customInputs === undefined || customInputs.length === 0 ? (
                        <CTableHeaderCell>No data to display</CTableHeaderCell>
                      ) : (
                        customInputs.map((ci, index) => {
                          return (
                            <CTableRow key={index}>
                              <CTableDataCell>{ci.display_name}</CTableDataCell>
                              <CTableDataCell>{ci.field_type}</CTableDataCell>
                              <CTableDataCell>{ci.key_name}</CTableDataCell>
                              {/* <CTableDataCell>
                              <CButton
                                color="danger"
                                onClick={() => {
                                  console.log("delete clicked");
                                }}
                              >
                                Delete
                              </CButton>
                            </CTableDataCell> */}
                            </CTableRow>
                          );
                        })
                      )}
                    </CTableBody>
                  )}
                </CTable>
              </div>
            )}
          </CCardBody>
        </CCard>
      </CCard>
      <br />
    </>
  );
};

export default CompanyGetCustomInput;
