import React, { useEffect, useState } from "react";
import {
    CButton,
    CCard,
    CCardBody,
    CCardHeader,
    CCardTitle,
    CCol,
    CRow,
    CSpinner,
    CTable,
    CTableBody,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
    CFormInput,
} from "@coreui/react";
import moment from "moment/moment";
import { cilReload } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { getFeedback } from "../../../../utils/api";

const AllFeedback = () => {
    const [loading, setLoading] = useState(false);
    const [userFeedbackList, setUserFeedbackList] = useState(undefined);
    const [searchTerm, setSearchTerm] = useState("");
    const [feedbackList, setFeedbackList] = useState([]);
    const [visibleUserFeedbackList, setVisibleUserFeedbackList] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [feedbackCount, setFeedbackCount] = useState(0);
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;

    useEffect(() => {
        const visibleUserFeedbackList = feedbackList && feedbackList;
        setVisibleUserFeedbackList(visibleUserFeedbackList);
    }, [feedbackList, page, rowsPerPage]);

    useEffect(() => {
        getFeedbackList();
    }, [page, rowsPerPage]);

    useEffect(() => {
        handleSearch();
    }, [searchTerm]);
   
    async function getFeedbackList() {
        setUserFeedbackList(undefined);
        setLoading(true);
        const response = await getFeedback(page + 1, rowsPerPage)
        const totalFeedbackCount = response.data.data.totalCount;
        const receivedFeedbackList = response.data.data.userFeedbackDetails;
        setFeedbackList(receivedFeedbackList);
        setUserFeedbackList(receivedFeedbackList);
        setFeedbackCount(totalFeedbackCount)
        setLoading(false);
    }

    const handleSearch = () => {
        if (userFeedbackList) {
            const feedbackList = userFeedbackList.filter((feedback) => {
                return (
                    feedback.user_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    (feedback.company_name &&
                        feedback.company_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
                    (feedback.crop && feedback.crop.includes(searchTerm)) ||
                    feedback.machine.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    feedback?.created_at.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    feedback?.feedback?.issue_content.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    feedback?.feedback?.device_id.toLowerCase().includes(searchTerm.toLowerCase())
                );
            });
            setFeedbackList(feedbackList);
            setPage(0);
        }
    };

    const handleChangePage = (newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleNextPage = () => {
        const newPage = page + 1;
        if (newPage * rowsPerPage > feedbackCount) {
            return;
        }
        setPage(newPage);
    };


    return (
        <>
            <CCard>
                <CCardHeader>
                    <CRow className="justify-items-between">
                        <CCol>
                            <CCardTitle>
                                <h3>
                                    <strong>Feedback List</strong>
                                </h3>
                            </CCardTitle>
                        </CCol>
                        <CCol>
                            <CButton color="light" onClick={() => getFeedbackList()}>
                                <CIcon size="lg" icon={cilReload} />
                            </CButton>
                        </CCol>
                        <CCol xs={3}>
                            <CCol>
                                <CFormInput
                                    className="mx-2"
                                    type="text"
                                    placeholder="Search"
                                    value={searchTerm}
                                    onChange={(event) => setSearchTerm(event.target.value)}
                                />
                            </CCol>
                        </CCol>
                    </CRow>
                </CCardHeader>
                <CCardBody>
                    <CTable align="middle">
                        <CTableHead style={{ background: "aliceblue" }}>
                            <CTableRow>
                                <CTableHeaderCell>S.No</CTableHeaderCell>
                                <CTableHeaderCell>Name</CTableHeaderCell>
                                <CTableHeaderCell>Phone/Email</CTableHeaderCell>
                                <CTableHeaderCell>Company</CTableHeaderCell>
                                <CTableHeaderCell>Crop</CTableHeaderCell>
                                <CTableHeaderCell>Machine</CTableHeaderCell>
                                <CTableHeaderCell>Created At</CTableHeaderCell>
                                <CTableHeaderCell>Device</CTableHeaderCell>
                                <CTableHeaderCell>Issue Content</CTableHeaderCell>
                            </CTableRow>
                        </CTableHead>
                        {loading ? (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
                                <CSpinner />
                            </div>
                        ) : (
                            <CTableBody>
                                {visibleUserFeedbackList === undefined ||
                                    visibleUserFeedbackList.length === 0 ? (
                                    <CTableHeaderCell>No data to display</CTableHeaderCell>
                                ) : (
                                    visibleUserFeedbackList.map((feedback, index) => {
                                        return (
                                            <CTableRow key={index}>
                                                <CTableHeaderCell>{startIndex + index + 1}</CTableHeaderCell>
                                                <CTableHeaderCell>
                                                    {feedback.user_name}
                                                </CTableHeaderCell>
                                                <CTableHeaderCell>
                                                    {feedback.phone_or_email}
                                                </CTableHeaderCell>
                                                <CTableHeaderCell>
                                                    {feedback.company_name}
                                                </CTableHeaderCell>
                                                <CTableHeaderCell>{feedback.crop}</CTableHeaderCell>
                                                <CTableHeaderCell>{feedback.machine}</CTableHeaderCell>
                                                <CTableHeaderCell>{moment(feedback.created_at).format('DD MMM, YYYY hh:mm A')}</CTableHeaderCell>
                                                <CTableHeaderCell>{feedback?.feedback?.device_id}</CTableHeaderCell>
                                                <CTableHeaderCell> <div style={{ maxHeight: '40px', WebkitOverflowScrolling: 'touch', overflowY: 'scroll', wordBreak: 'break-word' }}>
                                                    {feedback?.feedback?.issue_content}
                                                </div></CTableHeaderCell>
                                            </CTableRow>
                                        );
                                    })
                                )}
                            </CTableBody>
                        )}
                    </CTable>
                    {feedbackList && feedbackList.length && (
                        <div style={{ marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                            <span style={{ marginRight: '10px' }}>Rows per page</span>
                            <select value={rowsPerPage} onChange={handleChangeRowsPerPage} style={{ marginRight: '5px' }}>
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={50}>50</option>
                            </select>
                            <span style={{ margin: '0 10px' }}>
                                {startIndex + 1}-{endIndex} of {feedbackCount}
                            </span>
                            <button
                                disabled={page === 0}
                                onClick={() => handleChangePage(page - 1)}
                                style={{ marginRight: '5px', fontWeight: 'bold', fontSize: '20px', backgroundColor: 'transparent', color: 'black', border: 'none', cursor: 'pointer' }}
                            >
                                {"<"}
                            </button>
                            <button
                                disabled={endIndex >= feedbackCount}
                                onClick={handleNextPage}
                                style={{ marginLeft: '5px', padding: '4px', fontWeight: 'bold', fontSize: '20px', backgroundColor: 'transparent', color: 'black', border: 'none', cursor: 'pointer' }}
                            >
                                {">"}
                            </button>
                        </div>
                    )}
                </CCardBody>
            </CCard>
        </>
    );
};

export default AllFeedback;
