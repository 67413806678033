import { cilReload } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CButton, CCard, CCardBody, CCardHeader, CTableBody, CCardTitle, CContainer, CFormInput, CSpinner, CTable, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from "@coreui/react";
import React, { useState, useEffect } from "react";
import { auth } from "../../../firebase";
import axios from "axios";
import AddCompany from "./components/AddCompany";
import { useNavigate } from "react-router-dom";
import AppConfirmModal from "../../../components/AppConfirmModal";
import AppMessageModal from "../../../components/AppMessageModal";
import { getCompany } from "../../../utils/api";

const CompanyList = () => {
    const navigate = useNavigate();
    //States
    const [loading, setLoading] = useState(false);
    const [companyList, setCompanyList] = useState(undefined);
    const [filteredCompanyList, setFilteredCompanyList] = useState(undefined);
    const [addCompany, setAddCompany] = useState(false);
    const [message, setMessage] = useState('');
    const [title, setTitle] = useState('');
    const [confirmModal, setConfirmModal] = useState(false);
    const [activeCompanyID, setActiveCompanyID] = useState(undefined);
    const [searchTerm, setSearchTerm] = useState('');
    const [messageModal, setMessageModal] = useState(false);
    const [messagetitle, setMessageTitle] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    //Functions

    useEffect(() => {
        getCompanyList();
    }, []);

    useEffect(() => {
        handleSearch();
    }, [searchTerm]);

    const handleSearch = () => {
        if (companyList) {
            const filteredCompanyList = companyList.filter((user) => {
                console.log(user);
                const name = `${user.name || ""}`;
                return (
                    name.toLowerCase().includes(searchTerm.toLowerCase())
                );
            });
            setFilteredCompanyList(filteredCompanyList);
            setPage(0);
        }
    };

    async function getCompanyList() {
        setCompanyList(undefined);
        setLoading(true);
        try {
            let result = await getCompany()
            setCompanyList(result?.data?.data?.companyList)
            setFilteredCompanyList(result?.data?.data?.companyList)
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    function handleConfirm() {
        setAddCompany(false);
        getCompanyList();
    }

    function openCompanyInfo(company_id) {
        navigate('/home/company/info/' + company_id)
    }

    function handleDeleteConfirm(company_id) {
        setActiveCompanyID(company_id);
        setMessage("Are you sure you want to delete this company?");
        setTitle("Delete Company");
        setConfirmModal(true);
    }

    async function handleCompanyDelete() {
        setConfirmModal(false);
        setLoading(true);
        try {
            await new Promise((resolve, reject) => {
                auth.onAuthStateChanged((user) => {
                    if (user) {
                        resolve(user);
                    } else {
                        reject(new Error("User is not authenticated."));
                    }
                });
            });
            let authToken = await auth.currentUser.getIdToken();
            let result = await axios.post(window.config.commonEndpoint + 'company/delete', { id: activeCompanyID }, { headers: { Authorization: `Bearer ${authToken}` } });
            setLoading(false);
            setMessage(result.data.message);
            setMessageTitle('Success');
            setMessageModal(true);
            setConfirmModal(false);
        } catch (error) {
            setLoading(false);
            setMessage("Error while deleting company");
            setTitle("Error");
            setConfirmModal(true);
        }
    }
    const handleChangePage = (newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const visiblecompanyList = filteredCompanyList && filteredCompanyList.slice(startIndex, endIndex);

    return (
        <>
            <CContainer>
                <CCard>
                    <CCardHeader>
                        <CCardTitle><h3><strong>Company</strong></h3></CCardTitle>
                    </CCardHeader>
                    <CCardBody style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                            <CButton style={{ marginRight: '10px' }} className="APP-BACKGROUND" onClick={() => setAddCompany(true)}>Create new company</CButton>
                            <CButton color="light" onClick={() => getCompanyList()}><CIcon size="lg" icon={cilReload} /></CButton>
                        </div>
                        <CFormInput
                            className="mx-2"
                            type="text"
                            placeholder="Search"
                            value={searchTerm}
                            style={{ width: '300px' }}
                            onChange={(event) => setSearchTerm(event.target.value)}
                        />
                    </CCardBody>
                    <CCardBody>
                        {loading ?
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
                                <CSpinner color="dark" />
                            </div> :
                            <>
                                <CTable align="middle">
                                    <CTableHead style={{ background: "aliceblue" }}>
                                        <CTableRow>
                                            <CTableHeaderCell>S.No</CTableHeaderCell>
                                            <CTableHeaderCell>Company Name</CTableHeaderCell>
                                            <CTableHeaderCell>Short Name</CTableHeaderCell>
                                            <CTableHeaderCell>No. Of Users</CTableHeaderCell>
                                            <CTableHeaderCell>GST No.</CTableHeaderCell>
                                            <CTableHeaderCell>Actions</CTableHeaderCell>
                                        </CTableRow>
                                    </CTableHead>
                                    {visiblecompanyList !== undefined && visiblecompanyList?.length > 0 ? visiblecompanyList.map((company, index) => {
                                        return (
                                            <CTableBody><CTableRow key={index}>
                                                <CTableHeaderCell>{startIndex + index + 1}</CTableHeaderCell>
                                                <CTableDataCell>{company.name}</CTableDataCell>
                                                <CTableDataCell>{company.short_name}</CTableDataCell>
                                                <CTableDataCell>{company.number_of_users}</CTableDataCell>
                                                <CTableDataCell>{company.gst_number}</CTableDataCell>
                                                <CTableDataCell><CButton className="APP-BACKGROUND" onClick={() => openCompanyInfo(company.id)}>More Actions</CButton>
                                                    <CButton style={{ marginLeft: '15px' }} color="danger" onClick={() => handleDeleteConfirm(company.id)}>Delete</CButton></CTableDataCell>
                                            </CTableRow></CTableBody>)
                                    }) :
                                        <CTableHeaderCell>No data to display</CTableHeaderCell>}
                                </CTable>
                                {filteredCompanyList && filteredCompanyList.length && (
                                    <div style={{ marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                        <span style={{ marginRight: '10px' }}>Rows per page</span>
                                        <select value={rowsPerPage} onChange={handleChangeRowsPerPage} style={{ marginRight: '5px' }}>
                                            <option value={5}>5</option>
                                            <option value={10}>10</option>
                                            <option value={50}>50</option>
                                        </select>
                                        <span style={{ margin: '0 10px' }}>
                                            {startIndex + 1}-{Math.min(endIndex, filteredCompanyList.length)} of {filteredCompanyList.length}
                                        </span>
                                        <button
                                            disabled={page === 0}
                                            onClick={() => handleChangePage(page - 1)}
                                            style={{ marginRight: '5px', fontWeight: 'bold', fontSize: '20px', backgroundColor: 'transparent', color: 'black', border: 'none', cursor: 'pointer' }}
                                        >
                                            {"<"}
                                        </button>
                                        <button
                                            disabled={endIndex >= filteredCompanyList.length}
                                            onClick={() => handleChangePage(page + 1)}
                                            style={{ marginLeft: '5px', padding: '4px', fontWeight: 'bold', fontSize: '20px', backgroundColor: 'transparent', color: 'black', border: 'none', cursor: 'pointer' }}
                                        >
                                            {">"}
                                        </button>
                                    </div>
                                )}
                            </>
                        }
                    </CCardBody>
                </CCard>
            </CContainer>
            {/* Add Company Modal */}
            <AddCompany isOpen={addCompany} handleCancel={() => setAddCompany(false)} handleConfirm={() => handleConfirm()} />
            {/* Confirm Modal */}
            <AppConfirmModal isOpen={confirmModal} title={title} message={message} onCancel={() => setConfirmModal(false)} onConfirm={() => handleCompanyDelete()} />
            <AppMessageModal message={message} title={messagetitle} handleClose={() => setMessageModal(false)} isOpen={messageModal} />
        </>);
}

export default CompanyList;