import axios from "axios";
import { auth } from "../firebase";
const url = window.config.commonEndpoint;

const getRefreshedTokenId = async () => {
    return new Promise(async (resolve, reject) => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            unsubscribe();
            try {
                const refreshedToken = await user.getIdToken(true);
                resolve(refreshedToken);
            } catch (error) {
                console.error(error);
                reject(error);
            }
        });
    });
};

export const deleteCropUsingID = async (cropId) => {
    try {
        const token = await getRefreshedTokenId();
        const responce = await axios({
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            },
            method: 'POST',
            url: `${url}crops/delete`,
            data: { id: cropId }
        })
        return responce
    } catch (error) {
        return error
    }
}

export const deleteMachineUsingID = async (machine_code) => {
    try {
        const token = await getRefreshedTokenId();
        const responce = await axios({
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            },
            method: 'POST',
            url: `${url}machines/delete`,
            data: { machine_code: machine_code }
        })
        return responce
    } catch (error) {
        console.log(error);
    }
}
export const deleteSubCompany = async (company_id, parent_company_id) => {
    try {
        const token = await getRefreshedTokenId();
        const responce = await axios({
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            },
            method: 'POST',
            url: `${window.config.endpoint}sub_company/delete`,
            data: { sub_company_id: company_id, parent_company_id: parent_company_id }
        })
        return responce
    } catch (error) {
        console.log(error);
    }
}

export const getAllMachine = async (cropId) => {
    try {
        const token = await getRefreshedTokenId();
        const responce = await axios({
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            },
            method: 'GET',
            url: `${url}machines/all`,
            data: { id: cropId }
        })
        return responce
    } catch (error) {
        console.log(error);
    }
}
export const getDefaultResponseDetailsConfigurationsApi = async (payload) => {
    try {
        const token = await getRefreshedTokenId();
        const responce = await axios({
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            },
            method: 'POST',
            url: `${url}crops/get/default/crop/machine/response/config`,
            data: payload
        })
        return responce
    } catch (error) {
        return error
    }
}
export const getDefaultResponceConfigurationsApi = async (payload) => {
    try {
        const token = await getRefreshedTokenId();
        const responce = await axios({
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            },
            method: 'POST',
            url: `${url}crops/get/default/crop_id/response/config`,
            data: payload
        })
        return responce
    } catch (error) {
        return error
    }
}
export const getCompanyClassConfigurationsApi = async (payload) => {
    try {
        const token = await getRefreshedTokenId();
        const responce = await axios({
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            },
            method: 'POST',
            url: `${url}crops/get/company/crop/machine/class/config`,
            data: payload
        })
        return responce
    } catch (error) {
        return error
    }
}
export const getDefaultClassConfigurationsApi = async (payload) => {
    try {
        const token = await getRefreshedTokenId();
        const responce = await axios({
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            },
            method: 'POST',
            url: `${url}crops/get/default/crop/machine/class/config`,
            data: payload
        })
        return responce
    } catch (error) {
        return error
    }
}
export const getDefaultCropConfigurationsApi = async (payload) => {
    try {
        const token = await getRefreshedTokenId();
        const responce = await axios({
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            },
            method: 'POST',
            url: `${url}crops/get/default/crop_id/class/config`,
            data: payload
        })
        return responce
    } catch (error) {
        return error
    }
}
export const createVersion = async (payload) => {
    try {
        const token = await getRefreshedTokenId();
        const response = await axios({
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            },
            method: 'POST',
            url: `${url}machines/create/version`,
            data: payload
        });
        return response.data;
    } catch (error) {
        return error;
    }
};
export const getClassConfigurations = async (payload) => {
    try {
        const token = await getRefreshedTokenId();
        const responce = await axios({
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            },
            method: 'POST',
            url: `${url}crops/add/default/crop/machine/class/config`,
            data: payload
        })
        return responce
    } catch (error) {
        return error
    }
}
export const getEnamByDate = async (sellerId, requestDate) => {
    try {
        const token = await getRefreshedTokenId();
        const payload = {
            sellerId: sellerId,
            request_date: requestDate,
        };
        const responce = await axios({
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            },
            method: 'POST',
            url: `${url}enam/get_requests_by_date`,
            data: payload
        })
        return responce
    } catch (error) {
        return error
    }
}

export const createCropInstructionConfig = async (payload) => {
    try {
        const token = await getRefreshedTokenId();
        const responce = await axios({
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            },
            method: 'POST',
            url: `${url}crops/create/crop_instructions/config`,
            data: payload
        })
        return responce
    } catch (error) {
        return error
    }
}
export const createLiscenceKey = async (payload) => {
    try {
        const token = await getRefreshedTokenId();
        const responce = await axios({
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            },
            method: 'POST',
            url: `${url}input/create_licenseKey`,
            data: payload
        })
        return responce
    } catch (error) {
        return error
    }
}
export const updateLiscenceKey = async (payload) => {
    try {
        const token = await getRefreshedTokenId();
        const responce = await axios({
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            },
            method: 'POST',
            url: `${url}input/update_licenseKey`,
            data: payload
        })
        return responce
    } catch (error) {
        return error
    }
}

export const deleteRegisteredSystem = async (liscence_key) => {
    try {
        const token = await getRefreshedTokenId();
        const response = await axios({
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            },
            method: 'DELETE',
            url: `${url}input/delete_registered_system/${liscence_key}`
        });
        return response
    } catch (error) {
        return error
    }
}

export const getCropInstructionConfigurations = async (crop_id, machine_code) => {
    try {
        const token = await getRefreshedTokenId();
        const payload = {
            crop_ids: crop_id,
            machine_code: machine_code
        };
        const responce = await axios({
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            },
            method: 'POST',
            url: `${url}crops/get/crop_instructions/config`,
            data: payload
        })
        return responce
    } catch (error) {
        return error
    }
}
export const getResponceDetailConfigurations = async (payload) => {
    try {
        const token = await getRefreshedTokenId();
        const responce = await axios({
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            },
            method: 'POST',
            url: `${url}crops/add/default/crop/machine/response/config`,
            data: payload
        })
        return responce
    } catch (error) {
        return error
    }
}

export const getCropClasses = async (payload) => {
    try {
        const token = await getRefreshedTokenId();
        const responce = await axios({
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            },
            method: 'GET',
            url: `${url}crops/all/class/master`,
        })
        return responce
    } catch (error) {
        return error
    }
}
export const getFeedback = async (page, pageSize) => {
    try {
        const token = await getRefreshedTokenId();
        const responce = await axios({
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            },
            method: 'GET',
            url: `${url}users/all_users_feedback`,
            params: {
                page: page,
                pageSize: pageSize
            }
        })
        return responce
    } catch (error) {
        return error
    }
}
export const getMachines = async () => {
    try {
        const token = await getRefreshedTokenId();
        const responce = await axios({
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            },
            method: 'GET',
            url: `${url}machines/all`,
        })
        return responce
    } catch (error) {
        return error
    }
}
export const getCompanyMachines = async (company_id) => {
    try {
        const token = await getRefreshedTokenId();
        const response = await axios({
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            },
            method: 'POST',
            url: `${url}machines/get/company`,
            data: {
                company_id: company_id
            },
        });
        return response;
    } catch (error) {
        throw error.response || error;
    }
};
export const getCompanyCrops = async (company_id) => {
    try {
        const token = await getRefreshedTokenId();
        const response = await axios({
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            },
            method: 'POST',
            url: `${url}crops/get/company`,
            data: {
                company_id: company_id
            },
        });
        return response;
    } catch (error) {
        throw error.response || error;
    }
};
export const getCompanyCropClassConfig = async (company_id) => {
    try {
        const token = await getRefreshedTokenId();
        const response = await axios({
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            },
            method: 'POST',
            url: `${url}crops/get/company/class/config`,
            data: {
                company_id: company_id
            },
        });
        return response;
    } catch (error) {
        throw error.response || error;
    }
};
export const getCompanyResponceConfigurationsApi = async (company_id) => {
    try {
        const token = await getRefreshedTokenId();
        const response = await axios({
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            },
            method: 'POST',
            url: `${url}crops/get/company/response/config`,
            data: {
                company_id: company_id
            },
        });
        return response;
    } catch (error) {
        throw error.response || error;
    }
};
export const getCompany = async () => {
    try {
        const token = await getRefreshedTokenId();
        const responce = await axios({
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            },
            method: 'GET',
            url: `${url}company/get`,
        })
        return responce
    } catch (error) {
        return error
    }
}
export const CopyMasterClassConfig = async (payload) => {
    try {
        const token = await getRefreshedTokenId();
        const response = await axios({
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            },
            method: 'POST',
            url: `${url}crops/copy/default/crop/machine/class/config`,
            data: payload
        })
        return response
    } catch (error) {
        return error.response
    }
}
export const CopyMasterResponseConfig = async (payload) => {
    try {
        const token = await getRefreshedTokenId();
        const response = await axios({
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            },
            method: 'POST',
            url: `${url}crops/copy/default/crop/machine/response/config`,
            data: payload
        })
        return response
    } catch (error) {
        return error.response
    }
}
export const getComapanyResponseDetailsConfig = async (payload) => {
    try {
        const token = await getRefreshedTokenId();
        const response = await axios({
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            },
            method: 'POST',
            url: `${url}crops/get/company/crop/machine/response/config`,
            data: payload
        })
        return response
    } catch (error) {
        return error
    }
}
export const addCompanyResponseConfig = async (payload) => {
    try {
        const token = await getRefreshedTokenId();
        const response = await axios({
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            },
            method: 'POST',
            url: `${url}crops/add/company/crop/machine/response/config`,
            data: payload
        })
        return response
    } catch (error) {
        return error
    }
}
export const addCompanyClassConfigurations = async (payload) => {
    try {
        const token = await getRefreshedTokenId();
        const response = await axios({
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            },
            method: 'POST',
            url: `${url}crops/add/company/crop/machine/class/config`,
            data: payload
        })
        return response
    } catch (error) {
        return error
    }
}
export const acceptEnamReq = async (payload) => {
    try {
        const token = await getRefreshedTokenId();
        const response = await axios({
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            },
            method: 'POST',
            url: `${url}enam/accept_reject_request`,
            data: payload
        })
        return response
    } catch (error) {
        return error
    }
}

export const rejectEnamReq = async (payload) => {
    try {
        const token = await getRefreshedTokenId();
        const response = await axios({
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            },
            method: 'POST',
            url: `${url}enam/accept_reject_request`,
            data: payload
        })
        return response
    } catch (error) {
        return error
    }
}

// delete user notication token
export const deleteUserNotificationToken = async (firebase_user_id) => {
    try {
        const token = await getRefreshedTokenId();
        const responce = await axios({
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            },
            method: 'POST',
            url: `${url}input/clear_user_tokens`,
            data: {
                firebase_user_id: firebase_user_id
            }
        })
        return responce
    } catch (error) {
        return error
    }
}
export const deleteMasterClass = async (class_id, language) => {
    try {
        const token = await getRefreshedTokenId();
        const responce = await axios({
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            },
            method: 'DELETE',
            url: `${url}crops/delete/class/master`,
            data: {
                class_id: class_id,
                language: language
            }
        })
        return responce
    } catch (error) {
        return error.response
    }
}
