import React, { useEffect, useState } from "react";
import { auth } from "../../../../firebase";
import axios from "axios";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCardTitle,
  CCol,
  CRow,
  CSpinner,
  CTable,
  CTableBody,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CFormInput,
  CForm,
} from "@coreui/react";
import { cilReload } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { MdDeleteForever } from 'react-icons/md';
import { CiEdit } from 'react-icons/ci';
import AppConfirmModal from "../../../../components/AppConfirmModal";
import AppMessageModal from "../../../../components/AppMessageModal";
import { deleteSubCompany } from '../../../../utils/api'
import UpdateSubCompany from "./ParakhUpdateSubComanies";
import { IoEyeOutline } from "react-icons/io5";
import ShowDetailsDataModel from './ShowDetailDataModel'

const ParakhGetSubcompanies = ({ is_refresh_component, setIsRefreshComponent }) => {
  const [loading, setLoading] = useState(false);
  const [subCompanies, setSubCompanies] = useState(undefined);
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");
  const [confirmModal, setConfirmModal] = useState(false);
  const [viewDetailDataModal, setViewDetailDataModal] = useState(false);
  const [companyDetailsMessage, setCompanyDetailsMessage] = useState('')
  const [messageModal, setMessageModal] = useState(false);
  const [companyID, setCompanyID] = useState("");
  const [companyDetails, setCompanyDetails] = useState({});
  const [parentCompanyID, setParentCompanyID] = useState("");
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [UpdateSubCompanies, setUpdateSubCompanies] = useState(false);

  useEffect(() => {
    getSubCompanies();
  }, []);

  useEffect(() => {
    if (is_refresh_component) {
      getSubCompanies();
    }
  }, [is_refresh_component])

  function handleDeleteSubCompany(company_id, parent_company_id) {
    setCompanyID(company_id);
    setParentCompanyID(parent_company_id)
    setMessage("Are you sure you want to delete this Company?");
    setConfirmModal(true);
  }

  async function getSubCompanies() {
    setLoading(true);
    let company_id = window.location.href.split("/").pop();
    try {
      await new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          } else {
            reject(new Error("User is not authenticated."));
          }
        });
      });

      let authToken = await auth.currentUser.getIdToken();
      let result = await axios.get(
        window.config.endpoint + "sub_company/for_parent/" + company_id,
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      setSubCompanies(result.data.data);
      // console.log(typeof (result.data.data[1].company_location.latitude));
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }
  async function deleteCompany() {
    setConfirmModal(false);
    setDeleteLoading(true)
    try {
      let result = await deleteSubCompany(companyID, parentCompanyID)
      if (result.status === 200) {
        setMessage(result?.data?.message);
        setMessageModal(true);
        setDeleteLoading(false);
        setIsRefreshComponent(true)
      }
      getSubCompanies();
    } catch (error) {
      setMessage("Something went wrong. Please try again later.");
      setTitle("Error");
      setMessageModal(true);
      setDeleteLoading(false);
    }
  }
  function handleConfirm() {
    setUpdateSubCompanies(false);
    getSubCompanies();
  }

  const onHandleShowFullDetails = (company_data) => {
    setViewDetailDataModal(true)
    setTitle('Company Detail')
    const data = [{ 'Company Name': company_data?.company_name },
    { 'Company Type': company_data?.company_type },
    { 'Longitude': company_data?.company_location.longitude },
    { 'Latitude': company_data?.company_location.latitude },
    { 'Admin Name': company_data?.company_admin_name },
    { 'Contact Number': company_data?.company_admin_contact_number },
    { 'Company Address 1': company_data?.company_address_one },
    { 'Company Address 2': company_data?.company_address_two }]
    setCompanyDetailsMessage(data)
  }

  return (
    <>
      <ShowDetailsDataModel
        isOpen={viewDetailDataModal}
        handleClose={() => setViewDetailDataModal(false)}
        title={title}
        message={companyDetailsMessage}
      />

      <AppConfirmModal
        isOpen={confirmModal}
        onCancel={() => setConfirmModal(false)}
        onConfirm={deleteCompany}
        message={message}
        loading={deleteLoading}
      />
      {/* Message Modal */}
      <AppMessageModal
        isOpen={messageModal}
        handleClose={() => setMessageModal(false)}
        title={title}
        message={message}
      />
      <UpdateSubCompany
        isOpen={UpdateSubCompanies}
        handleCancel={() => setUpdateSubCompanies(false)}
        handleConfirm={() => handleConfirm()}
        companyID={companyID}
        companyDetails={companyDetails}
        setIsRefreshComponent={setIsRefreshComponent}
      />
      <div style={{ maxHeight: "532px", overflowY: "auto" }}>
        <CCard>
          <CCardHeader>
            <CRow className="justify-items-between">
              <CCol xs={7}>
                <CCardTitle>
                  <h3>
                    <strong>Sub Companies</strong>
                  </h3>
                </CCardTitle>
              </CCol>
              <CCol xs={2}>
                <CButton color="light" onClick={() => getSubCompanies()}>
                  <CIcon size="lg" icon={cilReload} />
                </CButton>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <div style={{ maxHeight: "532px", overflowY: "auto" }}>
              <CTable align="middle">
                <CTableHead>
                  <CTableRow>
                    <CTableHeaderCell className="fs-16 fw-bold">S.No</CTableHeaderCell>
                    <CTableHeaderCell className="fs-16 fw-bold">Name</CTableHeaderCell>
                    <CTableHeaderCell className="fs-16 fw-bold">Type</CTableHeaderCell>
                    <CTableHeaderCell className="fs-16 fw-bold">Latitude</CTableHeaderCell>
                    <CTableHeaderCell className="fs-16 fw-bold">Longitude</CTableHeaderCell>
                    <CTableHeaderCell className="fs-16 fw-bold">Action</CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                {loading ? (
                  <CSpinner color="dark" />
                ) : (
                  <CTableBody>
                    {subCompanies === undefined || subCompanies?.length === 0 ? (
                      <CTableHeaderCell>No data to display</CTableHeaderCell>
                    ) : (
                      subCompanies?.map((company, index) => (
                        <CTableRow key={index}>
                          <CTableHeaderCell>{index + 1}</CTableHeaderCell>
                          <CTableHeaderCell>
                            {company.company_name}
                          </CTableHeaderCell>
                          <CTableHeaderCell>
                            {company.company_type}
                          </CTableHeaderCell>
                          <CTableHeaderCell>
                            {company.company_location.latitude}
                          </CTableHeaderCell>
                          <CTableHeaderCell>
                            {company.company_location.longitude}
                          </CTableHeaderCell>
                          <CTableHeaderCell>
                            <div className="flex space-between gap-1">
                              <IoEyeOutline size={23} className="APP-COLOR cursor" onClick={() => onHandleShowFullDetails(company)} />
                              <CiEdit size={23} className="APP-COLOR cursor"
                                onClick={() => {
                                  setUpdateSubCompanies(true);
                                  setCompanyDetails(company)
                                  setCompanyID(company.sub_company_id);
                                }} />
                              <MdDeleteForever size={24} className="APP-COLOR cursor" onClick={() => {
                                handleDeleteSubCompany(company.sub_company_id, company.parent_company_id);
                              }} />
                            </div>
                          </CTableHeaderCell>
                        </CTableRow>
                      ))
                    )}
                  </CTableBody>
                )}
              </CTable>
            </div>
          </CCardBody>
        </CCard>
      </div>
    </>
  );
};

export default ParakhGetSubcompanies;
