import {
    CButton, CCard, CCardBody, CCardHeader, CCol, CRow, CCardTitle, CImage, CFormSwitch,
    CFormLabel, CFormInput, CCollapse, CSpinner, CTable, CTableBody, CTableDataCell, CTableHead,
    CTableHeaderCell, CTableRow, CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem, CContainer, CFormCheck,
} from "@coreui/react";
import React from 'react'
import Clipboard from 'clipboard';
import { getAllMachine, getCropClasses, getDefaultClassConfigurationsApi, getClassConfigurations, getDefaultCropConfigurationsApi } from "../../../../utils/api";
import { useEffect } from "react";
import { useState } from "react";
import AppMessageModal from "../../../../components/AppMessageModal";
import { MdDeleteForever } from 'react-icons/md';
import DropdownWithSelectedOptions from "./dropDown"


function DefaultClassConfigurations({ crop_name }) {
    const [allMachines, setAllMachines] = useState([]);
    const [selectedMachine, setSelectedMachine] = useState(null);
    const classType = ['DEFAULT_UPJAO', 'DEFAULT_ENAM']
    const [selectClassType, setSelectClassType] = useState(null)
    const [qualityScoreDependencies, setQualityScoreDependencies] = useState([]);
    const [loading, setLoading] = useState(false);
    const [cropClasses, setCropClasses] = useState(undefined);
    const [pipelineID, setPipelineID] = useState('')
    const [getResponceDefaultClassConfig, setResponceDefaultClassConfig] = useState(false)
    const [getInitialResponceDefaultClassConfig, setInitialResponceDefaultClassConfig] = useState(false)
    const [addConfig, setAddconfig] = useState(false)
    const [selectedClassOrderIds, setSelectedClassOrderIds] = useState([]);
    const [selectedDisplayOrderIds, setSelectedDisplayOrderIds] = useState([]);
    const [considerArea, setConsiderArea] = useState(false);
    const [considerLength, setConsiderLength] = useState(false);
    const [considerLengthDistribution, setConsiderLengthDistribution] = useState(false);
    const [considerWidth, setConsiderWidth] = useState(false);
    const [showSegmentedImage, setShowSegmentedImage] = useState(false);
    const [mapping, setMapping] = useState({});
    const [messageModal, setMessageModal] = useState(false);
    const [messagetitle, setMessageTitle] = useState('');
    const [message, setMessage] = useState('');
    const [defaultClassConfigurationList, setDefaultClassConfigurationList] = useState(null)
    const [classOrder, setClassOrder] = useState([])
    const [displayOrder, setDisplayOrder] = useState([])
    const [allClasses, setAllClasses] = useState([])
    const [classMapping, setClassMapping] = useState({})
    const [distributionAvgValuesResponce, setDistributionAvgValuesResponce] = useState([])
    const [distributionKeyResponce, setDistributionKeyResponce] = useState([])
    const distributionAvgValues = ["kernel_avg_area", "kernel_avg_height", "kernel_avg_length", "kernel_avg_width", "whiteness", "kernel_avg_length_no_foreign", "length / width ratio"]
    const distributionKeys = ["total_area", "length", "width", "height"]
    const [classRanges, setClassRanges] = useState({});
    const allValues = {
        classRanges,
        selectClassType,
        selectedMachine,
        pipelineID,
        distributionKeyResponce,
        distributionAvgValuesResponce,
        considerArea,
        considerLength,
        considerLengthDistribution,
        considerWidth,
        showSegmentedImage,
        selectedClassOrderIds,
        selectedDisplayOrderIds,
        qualityScoreDependencies,
        mapping
    };

    const beautify = {
        translations: {
            display_order: {},
            class_code: {},
            class_color: {},
        },
    };
    selectedDisplayOrderIds && selectedDisplayOrderIds.forEach((classId) => {
        const selectedClass = cropClasses.find((item) => item.class_id === classId);
        if (selectedClass) {
            beautify.translations.class_code[selectedClass.class_id] = selectedClass.class_code;
            beautify.translations.class_color[selectedClass.class_id] = selectedClass.color;
            beautify.translations.display_order[selectedClass.class_id] = selectedClass.class_display_name;
        }
    });
    useEffect(() => {
        getMachines();
        getDefaultClass()
        getDefaultCropConfigurations()
    }, []);

    const getDefaultClass = async () => {
        try {
            const result = await getCropClasses()
            setCropClasses(result.data.data.classes);
        } catch (error) {

        }
    }

    const setAllValues = (values) => {
        setClassRanges(values.classRanges || {})
        setSelectClassType(values.configClassType || null);
        setSelectedMachine(values.selectedMachine || null);
        setPipelineID(values.pipelineID || '');
        setDistributionKeyResponce(values.distributionKeyResponce || []);
        setDistributionAvgValuesResponce(values.distributionAvgValuesResponce || []);
        setConsiderArea(values.considerArea || false);
        setConsiderLength(values.considerLength || false);
        setConsiderLengthDistribution(values.considerLengthDistribution || false);
        setConsiderWidth(values.considerWidth || false);
        setShowSegmentedImage(values.showSegmentedImage || false);
        setSelectedClassOrderIds(values.selectedClassOrderIds || []);
        setSelectedDisplayOrderIds(values.selectedDisplayOrderIds || []);
        setQualityScoreDependencies(values.qualityScoreDependencies || []);
        setMapping(values.mapping || {});
    };

    const validateClasses = (values) => {
        const invalidClasses = [];
        if (values.selectedClassOrderIds) {
            values.selectedClassOrderIds.forEach((classId) => {
                const selectedClass = cropClasses.find((item) => item.class_id === classId);
                if (!selectedClass) {
                    invalidClasses.push(classId);
                }
            });
        }

        if (values.selectedDisplayOrderIds) {
            values.selectedDisplayOrderIds.forEach((classId) => {
                const selectedClass = cropClasses.find((item) => item.class_id === classId);
                if (!selectedClass) {
                    invalidClasses.push(classId);
                }
            });
        }

        return invalidClasses;
    };

    const copyValuesToClipboard = () => {
        navigator.clipboard.writeText(JSON.stringify(allValues)).then(() => {
            console.log('Values copied to clipboard');
        }).catch((error) => {
            console.error('Error copying values to clipboard:', error);
        });
    };

    const pasteValuesFromClipboard = () => {
        navigator.clipboard.readText().then((text) => {
            try {
                const values = JSON.parse(text);
                const invalidClasses = validateClasses(values);
                if (invalidClasses.length > 0) {
                    alert(`Please add the following classes first: ${invalidClasses.join(', ')}`);
                } else {
                    setAllValues(values);
                }
            } catch (error) {
                console.error('Failed to parse clipboard content:', error);
            }
        }).catch((error) => {
            console.error('Failed to read clipboard content:', error);
        });
    };
    async function getMachines() {
        try {
            const result = await getAllMachine()
            setAllMachines(result?.data?.data?.machines);
        } catch (error) {
            console.log(error);
        }
    }
    const getDefaultCropConfigurations = async () => {
        try {
            setLoading(true)
            const payload = {
                crop_id: window.location.href.split("/").pop()
            };
            const response = await getDefaultCropConfigurationsApi(payload);
            if (response?.data?.data) {
                setAllClasses(response?.data?.data?.defaultCropClass)
            } else {
            }
            setLoading(false)
        } catch (error) {
            console.log(error);
        }
    };

    const handleAddConfig = async () => {
        if (selectClassType === '' || selectedMachine.machine_code === '' || pipelineID === '') {
            setMessage('Please select Required Fields');
            setMessageTitle('Error');
            setMessageModal(true);
            return;
        }
        try {
            const requestBody = {
                class_order: selectedClassOrderIds,
                display_order: selectedDisplayOrderIds,
                class_mappings: mapping,
                beautify: beautify,
                consider_area: considerArea,
                consider_length: considerLength,
                consider_length_distribution: considerLengthDistribution,
                consider_width: considerWidth,
                distribution_avg_values: distributionAvgValuesResponce,
                distribution_key: distributionKeyResponce,
                quality_score_dependencies: qualityScoreDependencies,
                show_segmented_image: showSegmentedImage,
                crop_id: window.location.href.split("/").pop(),
                machine_code: selectedMachine.machine_code,
                type: selectClassType,
                pipeline_id: pipelineID,
                ...(selectClassType === 'DEFAULT_ENAM' && { class_ranges: classRanges }),
            };
            const response = await getClassConfigurations(requestBody);
            if (response?.response?.status === 400) {
                setMessage(`The default crop class config for the ${crop_name}-${selectClassType} and (${selectedMachine.machine_code}) already exists.`);
                setMessageTitle('Error');
                setMessageModal(true);
            }
            if (response?.status === 200) {
                setMessageTitle('Success');
                setMessage(response.data.message);
                setMessageModal(true);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleSelectOptionForDistributionAvgValues = (option) => {
        setDistributionAvgValuesResponce([...distributionAvgValuesResponce, option]);
    };

    const handleRemoveOptionForDistributionAvgValues = (optionToRemove) => {
        const updatedOptions = distributionAvgValuesResponce.filter((option) => option !== optionToRemove);
        setDistributionAvgValuesResponce(updatedOptions);
    };

    const handleSelectOptionForDistributionKeys = (option) => {
        setDistributionKeyResponce([...distributionKeyResponce, option]);
    };

    const handleRemoveOptionForDistributionKeys = (optionToRemove) => {
        const updatedOptions = distributionKeyResponce.filter((option) => option !== optionToRemove);
        setDistributionKeyResponce(updatedOptions);
    };

    const handleClassOrderHeaderClick = () => {
        setSelectedClassOrderIds([]);
    };

    const onSelectQualityScoreDependencies = (type) => {
        setQualityScoreDependencies((prev) => {
            if (prev.includes(type)) {
                return prev.filter((item) => item !== type);
            } else {
                return [...prev, type];
            }
        });
    };
    const handleDisplayOrderHeaderClick = () => {
        setSelectedDisplayOrderIds([]);
    };
    const handleDisplayOrderSelect = (classId, displayId) => {
        if (classId && displayId) {
            const newMapping = { ...mapping };
            newMapping[classId] = displayId;
            setMapping(newMapping);
        }
    };
    const getDisplayOrderValue = (classId) => {
        if (mapping?.hasOwnProperty(classId)) {
            return mapping[classId];
        }
        return "Select Display Order";
    };
    const handleViewButtonClick = async (machine_name, machine_code, type) => {
        setLoading(true)
        const payload = {
            crop_id: window.location.href.split("/").pop(),
            machine_code: machine_code,
            type: type
        };
        const response = await getDefaultClassConfigurationsApi(payload);
        if (response?.data?.data) {
            setSelectClassType(type)
            const selectedMachine = allMachines && allMachines.find(machine => machine.machine_code === machine_code);
            setSelectedMachine(selectedMachine);
            setPipelineID(response?.data?.data?.pipeline_id)
            setClassRanges(response?.data?.data?._doc.class_ranges || {})
            setDistributionKeyResponce(response?.data?.data?._doc.distribution_key)
            setDistributionAvgValuesResponce(response?.data?.data?._doc.distribution_avg_values)
            setConsiderArea(response?.data?.data?._doc.consider_area)
            setConsiderLength(response?.data?.data?._doc.consider_length)
            setConsiderLengthDistribution(response?.data?.data?._doc.consider_length_distribution)
            setConsiderWidth(response?.data?.data?._doc.consider_width)
            setShowSegmentedImage(response?.data?.data?._doc.show_segmented_image)
            setSelectedClassOrderIds(response?.data?.data?._doc.class_order)
            setSelectedDisplayOrderIds(response?.data?.data?._doc.display_order)
            setQualityScoreDependencies(response?.data?.data?._doc.quality_score_dependencies ? response?.data?.data?._doc.quality_score_dependencies : '')
            const newMapping = {};
            response?.data?.data?._doc.class_order.forEach((classId, index) => {
                const displayId = response?.data?.data?._doc.display_order[index];
                newMapping[classId] = displayId;
            });
            setMapping(response?.data?.data?._doc.class_mappings);
            setInitialResponceDefaultClassConfig(true);
            setLoading(false)
            setAddconfig(false)
        }
    };

    const handleDeleteButtonClick = (index) => {
        // Logic for handling the "Delete" button click for the item at the specified index
    };

    const handleDropdownChange = (classId, value) => {
        setClassRanges((prevRanges) => {
            const newRanges = { ...prevRanges };
            if (value === 'Count') {
                newRanges[classId] = { countRange: [null, null, null] };
                delete newRanges[classId]?.weightRange;
            } else if (value === 'Weight') {
                newRanges[classId] = { weightRange: [null, null, null] };
                delete newRanges[classId]?.countRange;
            } else if (value === 'Percentage') {
                delete newRanges[classId];
            }

            return newRanges;
        });
    };



    const handleRangeChange = (classId, rangeIndex, value) => {
        setClassRanges((prevRanges) => {
            const currentRangeType = prevRanges[classId]?.countRange ? 'countRange' : 'weightRange';
            const updatedRange = [...(prevRanges[classId]?.[currentRangeType] || [null, null, null])];
            updatedRange[rangeIndex] = value === '' ? null : Number(value);

            const allNull = updatedRange.every(v => v === null);

            if (allNull) {
                const { [classId]: _, ...newRanges } = prevRanges;
                return newRanges;
            }

            return {
                ...prevRanges,
                [classId]: { [currentRangeType]: updatedRange }
            };
        });
    };


    const handleAddButtonClick = () => {
        setSelectClassType('')
        setSelectedMachine('')
        setPipelineID('')
        setClassRanges({})
        setDistributionKeyResponce([])
        setDistributionAvgValuesResponce([])
        setConsiderArea(false)
        setConsiderLength(false)
        setConsiderLengthDistribution(false)
        setConsiderWidth(false)
        setShowSegmentedImage(false)
        setSelectedClassOrderIds([])
        setSelectedDisplayOrderIds([])
        setMapping({});
        setInitialResponceDefaultClassConfig(true);
        setAddconfig(true)
        setQualityScoreDependencies([])
    }
    const selectedClassOrderText = selectedClassOrderIds.join(", ");
    const selectedDisplayOrderText = selectedDisplayOrderIds.join(", ");
    return (
        <div>
            <AppMessageModal message={message} title={messagetitle} handleClose={() => setMessageModal(false)} isOpen={messageModal} />
            <CCard className="mt-4 mb-5">
                <CCardHeader>
                    <CRow className="justify-items-between ">
                        <CCol>
                            <CCardTitle>
                                <h3>
                                    <strong>Default Class Configurations</strong>
                                </h3>
                            </CCardTitle>
                        </CCol>
                    </CRow>
                </CCardHeader>
                <CCardBody>
                    <CTable style={{ marginTop: '30px', marginBottom: '50px' }} align="middle">
                        <CTableHead style={{ background: "aliceblue" }}>
                            <CTableRow>
                                <CTableHeaderCell>S.No</CTableHeaderCell>
                                <CTableHeaderCell>ResponeID</CTableHeaderCell>
                                <CTableHeaderCell>Machine</CTableHeaderCell>
                                <CTableHeaderCell>Flow- Default Upjao</CTableHeaderCell>
                                <CTableHeaderCell>Action</CTableHeaderCell>
                            </CTableRow>
                        </CTableHead>
                        {allClasses !== undefined ? (
                            <CTableBody>
                                {allClasses && allClasses.map((item, index) => {
                                    return (
                                        <CTableRow key={index}>
                                            <CTableHeaderCell>{index + 1}</CTableHeaderCell>
                                            <CTableDataCell>{item.reference_id}</CTableDataCell>
                                            <CTableDataCell>{item.machine_code}</CTableDataCell>
                                            <CTableDataCell>{item.type}</CTableDataCell>
                                            <CTableDataCell className="button-cell">
                                                <CButton onClick={() => handleViewButtonClick(item, item.machine_code, item.type)}>VIEW</CButton>
                                                <CButton
                                                    color="danger"
                                                    style={{ marginLeft: '15px' }}
                                                    onClick={() => {
                                                        handleDeleteButtonClick(index)
                                                    }}
                                                >
                                                    <MdDeleteForever size={22} />
                                                </CButton>
                                            </CTableDataCell>
                                        </CTableRow>
                                    );
                                })}
                            </CTableBody>
                        ) : (
                            <CTableHeaderCell>No Data to display</CTableHeaderCell>
                        )}
                    </CTable>
                    <CRow>
                        <CCol xs="2">
                            <CDropdown>
                                <CDropdownToggle color="secondary">
                                    {selectClassType ? `${selectClassType}` : "Select Class Type"}
                                </CDropdownToggle>
                                <span style={{ fontSize: "20px" }} className="text-danger">*</span>
                                <CDropdownMenu>
                                    {classType &&
                                        classType.map((type, index) => (
                                            <CDropdownItem
                                                key={index}
                                                onClick={() => setSelectClassType(type)}
                                            >
                                                {type}
                                            </CDropdownItem>
                                        ))}
                                </CDropdownMenu>
                            </CDropdown>
                        </CCol>
                        <CCol xs="2">
                            <CDropdown>
                                <CDropdownToggle color="secondary">
                                    {selectedMachine
                                        ? `${selectedMachine.machine_name} - ${selectedMachine.machine_code}`
                                        : "Select Machine"}
                                </CDropdownToggle>
                                <span style={{ fontSize: "20px" }} className="text-danger">*</span>
                                <CDropdownMenu>
                                    {allMachines &&
                                        allMachines.map((machine, index) => (
                                            <CDropdownItem
                                                key={index}
                                                onClick={() => setSelectedMachine(machine)}
                                            >
                                                {machine.machine_name} - {machine.machine_code}
                                            </CDropdownItem>
                                        ))}
                                </CDropdownMenu>
                            </CDropdown>
                        </CCol>
                        <CCol xs="8" className="align-items-center justify-content-end button-cell">
                            <CButton color="success" onClick={() => handleAddButtonClick()}>+ ADD</CButton>
                            <CCol xs="2">
                                <CDropdown>
                                    <CDropdownToggle color="primary">
                                        COPY
                                    </CDropdownToggle>
                                    <CDropdownMenu>
                                        {allClasses &&
                                            allClasses.map((item, index) => (
                                                <CDropdownItem
                                                    key={index}
                                                    onClick={() => handleViewButtonClick(item, item.machine_code, item.type)}
                                                >
                                                    {item.machine_code} - ({item.reference_id})
                                                </CDropdownItem>
                                            ))}
                                    </CDropdownMenu>
                                </CDropdown>
                            </CCol>
                            {getInitialResponceDefaultClassConfig && (
                                <>
                                    <CButton color="primary" onClick={copyValuesToClipboard}>
                                        Copy Values
                                    </CButton>
                                    <CButton color="primary" onClick={pasteValuesFromClipboard}>
                                        Paste Values
                                    </CButton>
                                </>)}
                        </CCol>
                    </CRow>
                    {
                        loading ?
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
                                <CSpinner color="dark" />
                            </div>
                            :
                            <>
                                {getResponceDefaultClassConfig &&
                                    <CRow>
                                        <CRow className="mt-5">
                                            <CRow>
                                                <CCol>
                                                    Pipeline ID:  <strong>{defaultClassConfigurationList?.pipeline_id}</strong>
                                                </CCol>
                                                <CCol>
                                                    Consider Area: <strong>{defaultClassConfigurationList?._doc?.consider_area ? 'True' : 'False'}</strong>
                                                </CCol>
                                                <CCol>
                                                    Consider Length: <strong>{defaultClassConfigurationList?._doc?.consider_length ? 'True' : 'False'}</strong>
                                                </CCol>
                                            </CRow>
                                            <CRow>
                                                <CCol>
                                                    Consider Length Distribution: <strong>{defaultClassConfigurationList?._doc.consider_length_distribution ? 'True' : 'False'}</strong>
                                                </CCol>
                                                <CCol>
                                                    Consider Width: <strong>{defaultClassConfigurationList?._doc.consider_width ? 'True' : 'False'}</strong>
                                                </CCol>
                                                <CCol></CCol>
                                            </CRow>
                                        </CRow>
                                        <CRow className="mt-4">
                                            <CCol>
                                                <CTable align="middle">
                                                    <CTableHead style={{ background: "aliceblue" }}>
                                                        <CTableRow>
                                                            <CTableHeaderCell>Class Order</CTableHeaderCell>
                                                        </CTableRow>
                                                    </CTableHead>
                                                    {classOrder ? (
                                                        <CTableBody>
                                                            {classOrder.map((item, index) => {
                                                                return (
                                                                    <CTableRow key={index}>
                                                                        <CTableHeaderCell>{item}</CTableHeaderCell>
                                                                    </CTableRow>
                                                                );
                                                            })}
                                                        </CTableBody>
                                                    ) : (
                                                        <CTableHeaderCell>No Data to display</CTableHeaderCell>
                                                    )}
                                                </CTable>
                                            </CCol>
                                            <CCol>
                                                <CTable align="middle">
                                                    <CTableHead style={{ background: "aliceblue" }}>
                                                        <CTableRow>
                                                            <CTableHeaderCell>Display Order</CTableHeaderCell>
                                                        </CTableRow>
                                                    </CTableHead>
                                                    {displayOrder ? (
                                                        <CTableBody>
                                                            {displayOrder.map((item, index) => {
                                                                return (
                                                                    <CTableRow key={index}>
                                                                        <CTableHeaderCell>{item}</CTableHeaderCell>
                                                                    </CTableRow>
                                                                );
                                                            })}
                                                        </CTableBody>
                                                    ) : (
                                                        <CTableHeaderCell>No Data to display</CTableHeaderCell>
                                                    )}
                                                </CTable>
                                            </CCol>
                                            <CCol>
                                                <CTable align="middle">
                                                    <CTableHead style={{ background: "aliceblue" }}>
                                                        <CTableRow>
                                                            <CTableHeaderCell>Class Mapping</CTableHeaderCell>
                                                        </CTableRow>
                                                    </CTableHead>
                                                    {classMapping ? (
                                                        <CTableBody>
                                                            {Object.entries(classMapping).map(([key, value], index) => {
                                                                return (
                                                                    <CTableRow key={index}>
                                                                        <CTableHeaderCell>{key}: {value}</CTableHeaderCell>
                                                                    </CTableRow>
                                                                );
                                                            })}
                                                        </CTableBody>
                                                    ) : (
                                                        <CTableHeaderCell>No Data to display</CTableHeaderCell>
                                                    )}
                                                </CTable>
                                            </CCol>
                                        </CRow>
                                        <CRow className="mt-4">
                                            <CCol>
                                                <CTable align="middle">
                                                    <CTableHead>
                                                        <CTableRow style={{ background: "aliceblue" }}>
                                                            <CTableHeaderCell>Distribution Keys</CTableHeaderCell>
                                                        </CTableRow>
                                                    </CTableHead>
                                                    {distributionKeyResponce ? (
                                                        <CTableBody>
                                                            {distributionKeyResponce.map((item, index) => {
                                                                return (
                                                                    <CTableRow key={index}>
                                                                        <CTableHeaderCell>{item}</CTableHeaderCell>
                                                                    </CTableRow>
                                                                );
                                                            })}
                                                        </CTableBody>
                                                    ) : (
                                                        <CTableHeaderCell>No Data to display</CTableHeaderCell>
                                                    )}
                                                </CTable>
                                            </CCol>

                                            <CCol>
                                                <CTable align="middle">
                                                    <CTableHead style={{ background: "aliceblue" }}>
                                                        <CTableRow>
                                                            <CTableHeaderCell>Distribution Avg Value</CTableHeaderCell>
                                                        </CTableRow>
                                                    </CTableHead>
                                                    {distributionAvgValuesResponce ? (
                                                        <CTableBody>
                                                            {distributionAvgValuesResponce.map((item, index) => {
                                                                return (
                                                                    <CTableRow key={index}>
                                                                        <CTableHeaderCell>{item}</CTableHeaderCell>
                                                                    </CTableRow>
                                                                );
                                                            })}
                                                        </CTableBody>
                                                    ) : (
                                                        <CTableHeaderCell>No Data to display</CTableHeaderCell>
                                                    )}
                                                </CTable>
                                            </CCol>
                                        </CRow>
                                    </CRow>
                                }
                                {getInitialResponceDefaultClassConfig &&
                                    <CRow>
                                        <CRow className="mt-4">
                                            <CCol xs="auto" className="d-flex align-items-center">
                                                <strong style={{ marginRight: "10px" }}>Pipeline ID</strong>
                                                <span style={{ fontSize: "20px" }} className="text-danger">*</span>
                                            </CCol>
                                            <CCol xs="auto">
                                                <CFormInput
                                                    type="text"
                                                    value={pipelineID}
                                                    onChange={(e) => setPipelineID(e.target.value)}
                                                    placeholder="Enter Pipeline ID"
                                                />
                                            </CCol>
                                        </CRow>
                                        <CRow>
                                            <div style={{ marginTop: '30px' }}>
                                                <strong style={{ fontSize: '20px' }}>Distribution Keys: </strong>
                                                <div style={{ marginTop: '10px' }}>
                                                    <div>
                                                        <DropdownWithSelectedOptions options={distributionKeys.map((option) => option)} onSelect={handleSelectOptionForDistributionKeys} title={"Select an Option"} onRemove={handleRemoveOptionForDistributionKeys} selectedOptions={distributionKeyResponce} />
                                                    </div>
                                                </div>
                                            </div>
                                        </CRow>
                                        <CRow>
                                            <div style={{ marginTop: '30px' }}>
                                                <strong style={{ fontSize: '20px' }}>Distribution Average Values: </strong>
                                                <div style={{ marginTop: '10px' }}>
                                                    <div>
                                                        <DropdownWithSelectedOptions options={distributionAvgValues.map((option) => option)} onSelect={handleSelectOptionForDistributionAvgValues} title={"Select an Option"} onRemove={handleRemoveOptionForDistributionAvgValues} selectedOptions={distributionAvgValuesResponce} />
                                                    </div>
                                                </div>
                                            </div>
                                        </CRow>
                                        <CRow className="mt-5">
                                            <CRow>
                                                <CCol>
                                                    <CFormCheck label="Consider Area" checked={considerArea} onChange={() => setConsiderArea(!considerArea)} />
                                                </CCol>
                                                <CCol>
                                                    <CFormCheck label="Consider Length" checked={considerLength} onChange={() => setConsiderLength(!considerLength)} />
                                                </CCol>
                                                <CCol>
                                                    <CFormCheck label="Consider Length Distribution" checked={considerLengthDistribution} onChange={() => setConsiderLengthDistribution(!considerLengthDistribution)} />
                                                </CCol>
                                            </CRow>
                                            <CRow>
                                                <CCol>
                                                    <CFormCheck label="Consider Width" checked={considerWidth} onChange={() => setConsiderWidth(!considerWidth)} />
                                                </CCol>
                                                <CCol>
                                                    <CFormCheck label="Show Segmented Image" checked={showSegmentedImage} onChange={() => setShowSegmentedImage(!showSegmentedImage)} />
                                                </CCol>
                                                <CCol></CCol>
                                            </CRow>
                                        </CRow>
                                        <hr className="mt-4"></hr>
                                        <div>
                                            <>
                                                <div className="label-value-container">
                                                    <div className="label-box">
                                                        <strong>Class Order:</strong>
                                                    </div>
                                                    <div>{selectedClassOrderText}</div>
                                                </div>
                                                <div className="label-value-container">
                                                    <div className="label-box">
                                                        <strong>Display Order:</strong>
                                                    </div>
                                                    <div>{selectedDisplayOrderText}</div>
                                                </div>
                                                <CTable style={{ marginTop: '10px' }} align="middle">
                                                    <CTableHead style={{ background: "aliceblue" }}>
                                                        <CTableRow>
                                                            <CTableHeaderCell>S.No</CTableHeaderCell>
                                                            <CTableHeaderCell>Class Name</CTableHeaderCell>
                                                            <CTableHeaderCell>Class ID</CTableHeaderCell>
                                                            <CTableHeaderCell>Display Name</CTableHeaderCell>
                                                            <CTableHeaderCell>Class Code</CTableHeaderCell>
                                                            <CTableHeaderCell>Color</CTableHeaderCell>
                                                            <CTableHeaderCell>Language</CTableHeaderCell>
                                                            <CTableHeaderCell>
                                                                <CFormCheck
                                                                    checked={
                                                                        selectedClassOrderIds.length
                                                                    }
                                                                    onChange={handleClassOrderHeaderClick}
                                                                />
                                                                <span className="checkbox-label">Class Order</span>
                                                            </CTableHeaderCell>
                                                            <CTableHeaderCell>
                                                                <CFormCheck
                                                                    checked={
                                                                        selectedDisplayOrderIds.length
                                                                    }
                                                                    onChange={handleDisplayOrderHeaderClick}
                                                                />
                                                                <span className="checkbox-label">Display Order</span>
                                                            </CTableHeaderCell>
                                                        </CTableRow>
                                                    </CTableHead>
                                                    {cropClasses !== undefined ? (
                                                        <CTableBody>
                                                            {cropClasses.map((item, index) => {
                                                                const classId = item.class_id;
                                                                const isSelectedClassOrder = selectedClassOrderIds?.includes(classId);
                                                                const isSelectedDisplayOrder = selectedDisplayOrderIds?.includes(classId);

                                                                return (
                                                                    <CTableRow key={index}>
                                                                        <CTableHeaderCell>{index + 1}</CTableHeaderCell>
                                                                        <CTableDataCell>{item.class_name}</CTableDataCell>
                                                                        <CTableDataCell>{item.class_id}</CTableDataCell>
                                                                        <CTableDataCell>{item.class_display_name}</CTableDataCell>
                                                                        <CTableDataCell>{item.class_code}</CTableDataCell>
                                                                        <CTableDataCell>
                                                                            <CContainer
                                                                                style={{
                                                                                    height: "20px",
                                                                                    backgroundColor: item.color,
                                                                                }}
                                                                            ></CContainer>
                                                                        </CTableDataCell>
                                                                        <CTableDataCell>{item.language}</CTableDataCell>
                                                                        <CTableDataCell>
                                                                            <CFormCheck
                                                                                checked={isSelectedClassOrder}
                                                                                onChange={() => {
                                                                                    if (isSelectedClassOrder) {
                                                                                        setSelectedClassOrderIds(selectedClassOrderIds.filter((id) => id !== classId));
                                                                                    } else {
                                                                                        setSelectedClassOrderIds([...selectedClassOrderIds, classId]);
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </CTableDataCell>
                                                                        <CTableDataCell>
                                                                            <CFormCheck
                                                                                checked={isSelectedDisplayOrder}
                                                                                onChange={() => {
                                                                                    if (isSelectedDisplayOrder) {
                                                                                        setSelectedDisplayOrderIds(selectedDisplayOrderIds.filter((id) => id !== classId));
                                                                                    } else {
                                                                                        setSelectedDisplayOrderIds([...selectedDisplayOrderIds, classId]);
                                                                                    }
                                                                                    setQualityScoreDependencies([])
                                                                                }}
                                                                            />
                                                                        </CTableDataCell>
                                                                    </CTableRow>
                                                                );
                                                            })}
                                                        </CTableBody>
                                                    ) : (
                                                        <CTableHeaderCell>No Data to display</CTableHeaderCell>
                                                    )}
                                                </CTable>
                                                {selectedClassOrderIds.length > 0 && selectedDisplayOrderIds.length > 0 && (
                                                    <>
                                                        <CCol>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <strong style={{ marginRight: '10px' }}>Quality Score Dependencies:</strong>
                                                                <CDropdown>
                                                                    <CDropdownToggle color="primary">
                                                                        {qualityScoreDependencies && qualityScoreDependencies.length > 0 ? qualityScoreDependencies.join(', ') : "Select Quality Score Dependencies"}
                                                                    </CDropdownToggle>
                                                                    <CDropdownMenu>
                                                                        {selectedDisplayOrderIds &&
                                                                            selectedDisplayOrderIds.map((type, index) => (
                                                                                <CDropdownItem
                                                                                    key={index}
                                                                                    onClick={() => onSelectQualityScoreDependencies(type)}
                                                                                >
                                                                                    {qualityScoreDependencies?.includes(type) ? "✔ " : ""}{type}
                                                                                </CDropdownItem>
                                                                            ))}
                                                                    </CDropdownMenu>
                                                                </CDropdown>
                                                            </div>
                                                        </CCol>
                                                        <CTable style={{ marginTop: '50px' }} align="middle">
                                                            <CTableHead style={{ background: "aliceblue" }}>
                                                                <CTableRow>
                                                                    <CTableHeaderCell>S.No</CTableHeaderCell>
                                                                    <CTableHeaderCell>Class Order</CTableHeaderCell>
                                                                    <CTableHeaderCell>Display Order</CTableHeaderCell>
                                                                </CTableRow>
                                                            </CTableHead>
                                                            <CTableBody>
                                                                {selectedClassOrderIds.map((classId, index) => (
                                                                    <CTableRow key={index}>
                                                                        <CTableHeaderCell>{index + 1}</CTableHeaderCell>
                                                                        <CTableDataCell>{classId}</CTableDataCell>
                                                                        <CTableDataCell>
                                                                            <CDropdown>
                                                                                <CDropdownToggle color="primary">
                                                                                    {getDisplayOrderValue(classId)}
                                                                                </CDropdownToggle>
                                                                                <CDropdownMenu>
                                                                                    {selectedDisplayOrderIds.map((displayId, displayIndex) => (
                                                                                        <CDropdownItem
                                                                                            key={displayIndex}
                                                                                            onClick={() => handleDisplayOrderSelect(classId, displayId)}
                                                                                        >
                                                                                            {displayId}
                                                                                        </CDropdownItem>
                                                                                    ))}
                                                                                </CDropdownMenu>
                                                                            </CDropdown>
                                                                        </CTableDataCell>
                                                                    </CTableRow>
                                                                ))}
                                                            </CTableBody>
                                                        </CTable>
                                                    </>
                                                )}
                                                {selectClassType === 'DEFAULT_ENAM' && selectedDisplayOrderIds.length > 0 && (
                                                    <div style={{ marginTop: '50px' }}>
                                                        <h5>Enam Class Wise Range Config</h5>
                                                        <div style={{ marginTop: '40px', marginBottom: '40px' }}>
                                                            <div style={{ display: 'flex', gap: '30px' }}>
                                                                <h6 style={{ marginTop: '40px' }}>Moisture Range</h6>
                                                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', gap: '5px' }}>
                                                                    <label htmlFor="moistureDropdown" style={{ marginRight: '10px' }}>% By</label>
                                                                    <CDropdown>
                                                                        <CDropdownToggle id="moistureDropdown" color="secondary">
                                                                            {classRanges && classRanges['moisture']?.countRange
                                                                                ? 'Count'
                                                                                : classRanges && classRanges['moisture']?.weightRange
                                                                                    ? 'Weight'
                                                                                    : 'Select % By'}
                                                                        </CDropdownToggle>
                                                                        <CDropdownMenu>
                                                                            <CDropdownItem onClick={() => handleDropdownChange('moisture', 'Percentage')}>
                                                                                Select % By
                                                                            </CDropdownItem>
                                                                            <CDropdownItem onClick={() => handleDropdownChange('moisture', 'Count')}>
                                                                                Count
                                                                            </CDropdownItem>
                                                                            <CDropdownItem onClick={() => handleDropdownChange('moisture', 'Weight')}>
                                                                                Weight
                                                                            </CDropdownItem>
                                                                        </CDropdownMenu>
                                                                    </CDropdown>
                                                                </div>
                                                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', gap: '5px' }}>
                                                                    <label htmlFor="moistureRange1" style={{ marginRight: '10px' }}>Range 1</label>
                                                                    <CFormInput
                                                                        id="moistureRange1"
                                                                        type="number"
                                                                        style={{ width: '100px' }}
                                                                        placeholder="Range 1"
                                                                        value={
                                                                            classRanges?.['moisture']?.countRange?.[0] ??
                                                                            classRanges?.['moisture']?.weightRange?.[0] ??
                                                                            ''
                                                                        }
                                                                        onChange={(e) => handleRangeChange('moisture', 0, e.target.value)}
                                                                    />
                                                                </div>
                                                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', gap: '5px' }}>
                                                                    <label htmlFor="moistureRange2" style={{ marginRight: '10px' }}>Range 2</label>
                                                                    <CFormInput
                                                                        id="moistureRange2"
                                                                        type="number"
                                                                        style={{ width: '100px' }}
                                                                        placeholder="Range 2"
                                                                        value={
                                                                            classRanges?.['moisture']?.countRange?.[1] ??
                                                                            classRanges?.['moisture']?.weightRange?.[1] ??
                                                                            ''
                                                                        }
                                                                        onChange={(e) => handleRangeChange('moisture', 1, e.target.value)}
                                                                    />
                                                                </div>
                                                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', gap: '5px' }}>
                                                                    <label htmlFor="moistureRange3" style={{ marginRight: '10px' }}>Range 3</label>
                                                                    <CFormInput
                                                                        id="moistureRange3"
                                                                        type="number"
                                                                        style={{ width: '100px' }}
                                                                        placeholder="Range 3"
                                                                        value={
                                                                            classRanges?.['moisture']?.countRange?.[2] ??
                                                                            classRanges?.['moisture']?.weightRange?.[2] ??
                                                                            ''
                                                                        }
                                                                        onChange={(e) => handleRangeChange('moisture', 2, e.target.value)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <CTable align="middle">
                                                            <CTableHead style={{ background: 'aliceblue' }}>
                                                                <CTableRow>
                                                                    <CTableHeaderCell>Display Order</CTableHeaderCell>
                                                                    <CTableHeaderCell>% By</CTableHeaderCell>
                                                                    <CTableHeaderCell>Range 1</CTableHeaderCell>
                                                                    <CTableHeaderCell>Range 2</CTableHeaderCell>
                                                                    <CTableHeaderCell>Range 3</CTableHeaderCell>
                                                                </CTableRow>
                                                            </CTableHead>
                                                            <CTableBody>
                                                                {selectedDisplayOrderIds.map((classId, index) => (
                                                                    <CTableRow key={index}>
                                                                        <CTableDataCell>{classId}</CTableDataCell>
                                                                        <CTableDataCell>
                                                                            <CDropdown>
                                                                                <CDropdownToggle color="secondary">
                                                                                    {classRanges && classRanges[classId]?.countRange
                                                                                        ? 'Count'
                                                                                        : classRanges[classId]?.weightRange
                                                                                            ? 'Weight'
                                                                                            : 'Select % By'}
                                                                                </CDropdownToggle>
                                                                                <CDropdownMenu>
                                                                                    <CDropdownItem onClick={() => handleDropdownChange(classId, 'Percentage')}>
                                                                                        Select % By
                                                                                    </CDropdownItem>
                                                                                    <CDropdownItem onClick={() => handleDropdownChange(classId, 'Count')}>
                                                                                        Count
                                                                                    </CDropdownItem>
                                                                                    <CDropdownItem onClick={() => handleDropdownChange(classId, 'Weight')}>
                                                                                        Weight
                                                                                    </CDropdownItem>
                                                                                </CDropdownMenu>
                                                                            </CDropdown>
                                                                        </CTableDataCell>
                                                                        <CTableDataCell>
                                                                            <CFormInput
                                                                                type="number"
                                                                                placeholder="Range 1"
                                                                                style={{ width: '100px' }}
                                                                                value={
                                                                                    classRanges[classId]?.countRange?.[0] ??
                                                                                    classRanges[classId]?.weightRange?.[0] ??
                                                                                    ''
                                                                                }
                                                                                onChange={(e) => handleRangeChange(classId, 0, e.target.value)}
                                                                            />
                                                                        </CTableDataCell>
                                                                        <CTableDataCell>
                                                                            <CFormInput
                                                                                type="number"
                                                                                placeholder="Range 2"
                                                                                style={{ width: '100px' }}
                                                                                value={
                                                                                    classRanges[classId]?.countRange?.[1] ??
                                                                                    classRanges[classId]?.weightRange?.[1] ??
                                                                                    ''
                                                                                }
                                                                                onChange={(e) => handleRangeChange(classId, 1, e.target.value)}
                                                                            />
                                                                        </CTableDataCell>
                                                                        <CTableDataCell>
                                                                            <CFormInput
                                                                                type="number"
                                                                                placeholder="Range 3"
                                                                                style={{ width: '100px' }}
                                                                                value={
                                                                                    classRanges[classId]?.countRange?.[2] ??
                                                                                    classRanges[classId]?.weightRange?.[2] ??
                                                                                    ''
                                                                                } 
                                                                                onChange={(e) => handleRangeChange(classId, 2, e.target.value)}
                                                                            />
                                                                        </CTableDataCell>
                                                                    </CTableRow>
                                                                ))}
                                                            </CTableBody>
                                                        </CTable>
                                                    </div>
                                                )}
                                                {selectedClassOrderIds.length > 0 && selectedDisplayOrderIds.length > 0 && addConfig ?
                                                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                                                        <button onClick={handleAddConfig} className="btn btn-primary">Submit</button>
                                                    </div>
                                                    :
                                                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                                                        <button onClick={handleAddConfig} className="btn btn-primary">Update</button>
                                                    </div>}
                                            </>
                                        </div>
                                    </CRow>
                                }
                            </>
                    }
                </CCardBody>
            </CCard>
        </div>
    )
}

export default DefaultClassConfigurations