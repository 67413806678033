import { cilReload } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CCol, CRow, CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem,
  CCardHeader, CContainer, CCard, CCardBody, CButton, CTable, CTableHead, CFormLabel,
  CTableRow, CTableHeaderCell, CTableBody, CTableDataCell, CSpinner, CCollapse, CCardTitle, CFormInput,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";

import axios from "axios";
import React, { useState, useEffect } from "react";
import { auth } from "../../../firebase";
import AddCrop from "./components/AddCrop";
import { useNavigate } from "react-router-dom";
import AppConfirmModal from "../../../components/AppConfirmModal";
import { getAllMachine, deleteCropUsingID, getCropInstructionConfigurations, createCropInstructionConfig } from "../../../utils/api";
import AppMessageModal from "../../../components/AppMessageModal";

const CropsList = () => {
  //States for the component
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [loadingForThreshold, setLoadingForThreshold] = useState(false);
  const [addCropModal, setAddCropModal] = useState(false);
  const [cropList, setCropList] = useState(undefined);
  const [filteredCropList, setFilteredCropList] = useState([])
  const [filteredCrop, setFilteredCrop] = useState([])
  const [cropId, setCropId] = useState('')
  const [message, setMessage] = useState('');
  const [title, setTitle] = useState('');
  const [confirmModal, setConfirmModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [messageModal, setMessageModal] = useState(false);
  const [messagetitle, setMessageTitle] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [allMachines, setAllMachines] = useState([]);
  const [selectedVarietyForCrop, setSelectedVarietyForCrop] = useState(null);
  const [selectedMachine, setSelectedMachine] = useState(null);
  const [minThreshold, setMinThreshold] = useState(0);
  const [midThreshold, setMidThreshold] = useState(0);
  const [maxThreshold, setMaxThreshold] = useState(0);
  const [paperType, setPaperType] = useState('');
  const [cropInstruction, setCropInstructions] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [viewDetails, setViewDetails] = useState(false);
  const [selectedCrop, setSelectedCrop] = useState(null);
  const [varietiesForSelectedCrop, setVarietiesForSelectedCrop] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedVarieties, setSelectedVarieties] = useState([]);
  const [updateCropInstruction, setUpdateCropInstruction] = useState(false);
  const [selectedCrops, setSelectedCrops] = useState([]);

  const handleSelectAllRows = () => {
    if (isAllRowsSelected()) {
      setSelectedCrops([]);
    } else {
      setSelectedCrops(cropInstruction);
    }
  };
  const handleCheckboxChange = (selectedCrop) => {
    if (selectedCrops.some((crop) => isSameCrop(crop, selectedCrop))) {
      setSelectedCrops(selectedCrops.filter((crop) => !isSameCrop(crop, selectedCrop)));
    } else {
      setSelectedCrops([...selectedCrops, selectedCrop]);
    }
  };

  const isSameCrop = (crop1, crop2) => {
    return crop1.crop === crop2.crop && crop1.variety === crop2.variety;
  };

  const isAllRowsSelected = () => {
    return selectedCrops?.length === cropInstruction?.length;
  };

  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    setSelectAll(isChecked);
    if (isChecked) {
      setSelectedVarieties(selectedVarietyForCrop);
    } else {
      setSelectedVarieties([]);
    }
  };

  const isAllSelected = () => {
    return selectAll;
  };

  const handleVarietySelection = (variety) => {
    if (selectAll) {
      setSelectedVarieties((prevSelectedVarieties) => {
        const isVarietySelected = prevSelectedVarieties.some(
          (item) => item.id === variety.id
        );
        if (isVarietySelected) {
          return prevSelectedVarieties.filter((v) => v.id !== variety.id);
        } else {
          return [...prevSelectedVarieties, variety];
        }
      });
      setSelectAll(false);
    } else {
      setSelectedVarieties(prevSelectedVarieties => {
        const isVarietySelected = prevSelectedVarieties.includes(variety);
        if (isVarietySelected) {
          return prevSelectedVarieties.filter(v => v !== variety);
        } else {
          return [...prevSelectedVarieties, variety];
        }
      });
    }
  };
  const isVarietySelected = (variety) => {
    return selectedVarieties.some(item => item.id === variety.id);
  };

  useEffect(() => {
    getMachines();
    getCropsList();
  }, []);

  async function getMachines() {
    try {
      const result = await getAllMachine()
      setAllMachines(result?.data?.data?.machines);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    onhandleSearch();
  }, [searchTerm]);

  const handleSubmit = async () => {
    const filteredCropList = cropList?.filter((crop) =>
      selectedCrops.some((selected) => selected.crop === crop.crop && selected.variety === crop.variety)
    );
    const arrayOfIds = filteredCropList.map(item => item.id);
    const payload = {
      crop_ids: arrayOfIds,
      machine_code: selectedMachine.machine_code,
      min_threshold: minThreshold,
      mid_threshold: midThreshold,
      max_threshold: maxThreshold,
      paper_type: paperType
    };
    try {
      const response = await createCropInstructionConfig(payload);
      if (response?.status === 200) {
        setMessageTitle('Success');
        setMessage(response.data.message);
        setMessageModal(true);
      }
      setUpdateCropInstruction(false)
      setSelectedCrops([]);
      setMinThreshold();
      setMidThreshold();
      setMaxThreshold();
      setPaperType('');
      ViewCropInstructionConfiguration()
    } catch (error) {
      // Handle the error
    }
  };
  const handleAddModel = async (e) => {
    e.preventDefault();
    setUpdateCropInstruction(true)
  };

  const ViewCropInstructionConfiguration = async () => {
    try {
      setLoadingForThreshold(true);
      setViewDetails(true)
      setSelectedCrops([]);
      const arrayOfIds = selectedVarieties.map(item => item.id);
      setVarietiesForSelectedCrop(arrayOfIds)
      const crop_id = arrayOfIds
      const machine_code = selectedMachine.machine_code
      const response = await getCropInstructionConfigurations(crop_id, machine_code);
      if (response?.data?.data.length > 0) {
        setCropInstructions(response?.data?.data)
      }
      setLoadingForThreshold(false);
    } catch (error) {
      // Handle the error
    }
  };

  const onhandleSearch = () => {
    if (cropList) {
      const filteredData = cropList.filter((crop) => (
        crop.crop.toLowerCase().includes(searchTerm.toLowerCase()) ||
        crop.variety.toLowerCase().includes(searchTerm.toLowerCase())
      ))
      setFilteredCropList(filteredData);
      setPage(0);
    }
  };

  function setCropModal(value) {
    setAddCropModal(value);
  }
  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const visibleCropList = filteredCropList && filteredCropList.slice(startIndex, endIndex);

  async function getCropsList() {
    setCropList(undefined);
    setLoading(true);
    try {
      await new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          } else {
            reject(new Error("User is not authenticated."));
          }
        });
      });
      await new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          } else {
            reject(new Error("User is not authenticated."));
          }
        });
      });
      let authToken = await auth.currentUser.getIdToken();
      // let authToken = localStorage.getItem("authToken");
      console.log(authToken);
      let result = await axios.get(window.config.commonEndpoint + "crops/all", {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      setCropList(result.data.data.crops);
      setFilteredCropList(result?.data?.data?.crops)
      let uniqueCropNames = [...new Set(result.data.data.crops.map(crop => crop.crop))];
      setFilteredCrop(uniqueCropNames);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  function openCropsListInfo(crop) {
    navigate("/home/crops/listinfo/" + crop.id, { state: { crop_name: crop.crop, crop_variety: crop.variety } });
  }

  const onDeleteConfirmation = (id) => {
    setCropId(id)
    setConfirmModal(true)
    setMessage("Are you sure you want to delete this crop?");
    setTitle("Delete Crop");
  };

  const onHandleCropDelete = async () => {
    // setLoading(true);
    try {
      const responce = await deleteCropUsingID(cropId)
      if (responce.status === 200) {
        setConfirmModal(false);
        setMessageTitle('Success');
        setMessage(responce.data.message);
        setMessageModal(true);
      }
      if (responce?.response?.status === 400) {
        setConfirmModal(false);
        setMessage(responce?.response?.data?.message);
        setMessageModal(true);
      }
    } catch (error) {
      setLoading(false);
      setMessage("Error while deleting crop");
      setTitle("Error");
      setConfirmModal(true);
    }
  }
  const handleSelectCrop = (crop) => {
    setSelectedVarietyForCrop([])
    setSelectedVarieties([])
    setViewDetails(false)
    setSelectedCrops([]);
    setSelectAll(null)
    setMinThreshold();
    setMidThreshold();
    setMaxThreshold();
    setPaperType('');
    setSelectedCrop(crop)
    const varieties = cropList.filter((variety) => variety.crop === crop)
    setSelectedVarietyForCrop(varieties)
  }
  const handleCancel = () => {
    setUpdateCropInstruction(false)
  }
  const handleRemoveOption = (option) => {
    if (selectAll) {
      setSelectAll(false);
    }
    setSelectedVarieties((prevSelectedVarieties) =>
      prevSelectedVarieties.filter((selectedOption) => selectedOption.id !== option.id)
    );
  };
  
  return (
    <>
      <div>
        <AppMessageModal message={message} title={messagetitle} handleClose={() => setMessageModal(false)} isOpen={messageModal} />
        <AppConfirmModal isOpen={confirmModal} title={title} message={message} onCancel={() => setConfirmModal(false)} onConfirm={() => onHandleCropDelete()} />
        <CContainer style={{ marginBottom: "40px" }}>
          <CCard>
            <CCardHeader>
              <CCardTitle>
                <h3>
                  <strong>Crops</strong>
                </h3>
              </CCardTitle>
            </CCardHeader>
            <CCardBody style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                <CButton
                  style={{ marginRight: "10px" }}
                  className="APP-BACKGROUND"
                  onClick={() => setCropModal(!addCropModal)}
                >
                  Create new crop
                </CButton>
                <CButton color="light" onClick={() => getCropsList()}>
                  <CIcon size="lg" icon={cilReload} />
                </CButton>
              </div>
              <CFormInput
                className="mx-2"
                type="text"
                placeholder="Search"
                style={{ width: '300px' }}
                value={searchTerm}
                onChange={(event) => setSearchTerm(event.target.value)}
              />
              <br />
              <CCollapse visible={addCropModal}>
                <AddCrop />
              </CCollapse>
              {addCropModal ? <br /> : <></>}
            </CCardBody>
            {/* <CCardHeader>
              <CCardTitle>
                <h4>
                  <strong>Crops List</strong>
                </h4>
              </CCardTitle>
            </CCardHeader> */}
            <CCardBody>
              {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
                  <CSpinner color="dark" />
                </div>
              ) : (
                <>
                  <CTable align="middle">
                    <CTableHead style={{ background: "aliceblue" }}>
                      <CTableRow>
                        <CTableHeaderCell>S.No</CTableHeaderCell>
                        <CTableHeaderCell>Crop Name</CTableHeaderCell>
                        <CTableHeaderCell>Variety</CTableHeaderCell>
                        <CTableHeaderCell>Actions</CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    {filteredCropList.length ? (
                      <CTableBody>
                        {visibleCropList.length ? (
                          visibleCropList.map((crop, index) => (
                            <CTableRow key={index}>
                              <CTableHeaderCell>{startIndex + index + 1}</CTableHeaderCell>
                              <CTableDataCell>{crop.crop}</CTableDataCell>
                              <CTableDataCell>{crop.variety}</CTableDataCell>
                              <CTableDataCell>
                                <CButton
                                  className="APP-BACKGROUND"
                                  onClick={() => openCropsListInfo(crop)}
                                >
                                  More Actions
                                </CButton>
                                <CButton
                                  style={{ marginLeft: '15px' }}
                                  className="APP-BACKGROUND-2 APP-BORDER"
                                  onClick={() => onDeleteConfirmation(crop.id)}
                                >
                                  Delete
                                </CButton>
                              </CTableDataCell>
                            </CTableRow>
                          ))
                        ) : (
                          <CTableRow>
                            <CTableHeaderCell colSpan="4">No Data to Display</CTableHeaderCell>
                          </CTableRow>
                        )}
                      </CTableBody>
                    ) : (
                      <CTableHeaderCell>No Data to Display</CTableHeaderCell>
                    )}
                  </CTable>
                  {filteredCropList && filteredCropList.length && (
                    <div style={{ marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                      <span style={{ marginRight: '10px' }}>Rows per page</span>
                      <select value={rowsPerPage} onChange={handleChangeRowsPerPage} style={{ marginRight: '5px' }}>
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={50}>50</option>
                      </select>
                      <span style={{ margin: '0 10px' }}>
                        {startIndex + 1}-{Math.min(endIndex, filteredCropList.length)} of {filteredCropList.length}
                      </span>
                      <button
                        disabled={page === 0}
                        onClick={() => handleChangePage(page - 1)}
                        style={{ marginRight: '5px', fontWeight: 'bold', fontSize: '20px', backgroundColor: 'transparent', color: 'black', border: 'none', cursor: 'pointer' }}
                      >
                        {"<"}
                      </button>
                      <button
                        disabled={endIndex >= filteredCropList.length}
                        onClick={() => handleChangePage(page + 1)}
                        style={{ marginLeft: '5px', padding: '4px', fontWeight: 'bold', fontSize: '20px', backgroundColor: 'transparent', color: 'black', border: 'none', cursor: 'pointer' }}
                      >
                        {">"}
                      </button>
                    </div>
                  )}
                </>
              )}
            </CCardBody>
          </CCard>
        </CContainer>
      </div>
      <div>
        <CContainer style={{ marginBottom: "40px" }}>
          <CCard>
            <CCardHeader>
              <CCardTitle>
                <h3>
                  <strong> Crop Threshold Configurations For All Crops</strong>
                </h3>
              </CCardTitle>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <div className="selected-options-container">
                  {selectedVarieties?.map((option, index) => (
                    <div key={index} className="selected-option-box">
                      {option.variety}
                      <span className="remove-option" onClick={() => handleRemoveOption(option)}>
                        &times;
                      </span>
                    </div>
                  ))}
                </div>
              </CRow>
              <CRow>
                <CCol>
                  <CDropdown>
                    <CDropdownToggle color="secondary">
                      {selectedCrop
                        ? `${selectedCrop}`
                        : "Select Crop"}
                    </CDropdownToggle>
                    <CDropdownMenu>
                      {filteredCrop &&
                        filteredCrop.map((crop, index) => (
                          <CDropdownItem
                            key={index}
                            onClick={() => handleSelectCrop(crop)}
                          >
                            {crop}
                          </CDropdownItem>
                        ))}
                    </CDropdownMenu>
                  </CDropdown>
                </CCol>
                <CCol>
                  {selectedCrop && (
                    <CDropdown>
                      <CDropdownToggle color="secondary">
                        Select Variety
                      </CDropdownToggle>
                      <CDropdownMenu style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                        <CDropdownItem>
                          <input
                            type="checkbox"
                            onChange={(e) => handleSelectAll(e)}
                            checked={isAllSelected() || selectedVarieties?.length === selectedVarietyForCrop?.length}
                          />
                          <span style={{ marginLeft: '10px' }}>Select All</span>
                        </CDropdownItem>
                        {selectedVarietyForCrop?.length &&
                          selectedVarietyForCrop.map((variety, index) => (
                            <CDropdownItem key={index}>
                              <input
                                type="checkbox"
                                onChange={() => handleVarietySelection(variety)}
                                checked={isVarietySelected(variety)}
                              />
                              <span style={{ marginLeft: '10px' }}>{variety.variety}</span>
                            </CDropdownItem>
                          ))}
                      </CDropdownMenu>
                    </CDropdown>
                  )}
                </CCol>
                <CCol>
                  <CDropdown>
                    <CDropdownToggle color="secondary">
                      {selectedMachine
                        ? `${selectedMachine.machine_name} - ${selectedMachine.machine_code}`
                        : "Select Machine"}
                    </CDropdownToggle>
                    <CDropdownMenu>
                      {allMachines &&
                        allMachines.map((machine, index) => (
                          <CDropdownItem
                            key={index}
                            onClick={() => setSelectedMachine(machine)}
                          >
                            {machine.machine_name} - {machine.machine_code}
                          </CDropdownItem>
                        ))}
                    </CDropdownMenu>
                  </CDropdown>
                </CCol>
                <CCol>
                  <CButton color="primary" style={{ marginLeft: '20px' }} onClick={ViewCropInstructionConfiguration}>View</CButton>
                </CCol>
                {viewDetails &&
                  <CCol>
                    <CButton color="primary" disabled={selectedCrops?.length === 0} style={{ marginLeft: '20px' }} onClick={handleAddModel}>ADD</CButton>
                  </CCol>}
              </CRow>
              {
                loadingForThreshold ?
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100px' }}>
                    <CSpinner color="dark" />
                  </div>
                  :
                  <>
                    {viewDetails &&
                      <>
                        <CTable align="middle" style={{ marginTop: "50px" }}>
                          <CTableHead style={{ background: "aliceblue" }}>
                            <CTableRow>
                              <CTableHeaderCell>
                                <input
                                  type="checkbox"
                                  onChange={() => handleSelectAllRows()}
                                  checked={isAllRowsSelected()}
                                />
                              </CTableHeaderCell>
                              <CTableHeaderCell>S.No</CTableHeaderCell>
                              <CTableHeaderCell>Crop Name</CTableHeaderCell>
                              <CTableHeaderCell>Variety</CTableHeaderCell>
                              <CTableHeaderCell>Min Threshold</CTableHeaderCell>
                              <CTableHeaderCell>Mid Threshold</CTableHeaderCell>
                              <CTableHeaderCell>Max Threshold</CTableHeaderCell>
                            </CTableRow>
                          </CTableHead>
                          <CTableBody>
                            {cropInstruction?.length ? (
                              cropInstruction.map((crop, index) => (
                                <CTableRow key={index}>
                                  <CTableDataCell>
                                    <input
                                      type="checkbox"
                                      onChange={() => handleCheckboxChange(crop)}
                                      checked={selectedCrops?.some((selectedCrop) => isSameCrop(selectedCrop, crop))}
                                    />
                                  </CTableDataCell>
                                  <CTableHeaderCell>{startIndex + index + 1}</CTableHeaderCell>
                                  <CTableDataCell>{crop.crop}</CTableDataCell>
                                  <CTableDataCell>{crop.variety}</CTableDataCell>
                                  <CTableDataCell>{crop.min_threshold ? crop.min_threshold : '-'}</CTableDataCell>
                                  <CTableDataCell>{crop.mid_threshold ? crop.mid_threshold : '-'}</CTableDataCell>
                                  <CTableDataCell>{crop.max_threshold ? crop.max_threshold : '-'}</CTableDataCell>
                                </CTableRow>
                              ))
                            ) : (
                              <CTableRow>
                                <CTableHeaderCell colSpan="4">No Data to Display</CTableHeaderCell>
                              </CTableRow>
                            )}
                          </CTableBody>
                        </CTable>
                      </>
                    }
                  </>
              }
              {updateCropInstruction &&
                <CModal
                  alignment="center"
                  backdrop="static"
                  size="lg"
                  visible={updateCropInstruction}
                  onClose={() => handleCancel()}
                >
                  <CModalHeader onClose={() => handleCancel()}>
                    <CModalTitle>
                      <h3>
                        <strong>ADD Crop-Instruction</strong>
                      </h3>
                    </CModalTitle>
                  </CModalHeader>
                  <CModalBody>
                    <CRow>
                      <CCol>
                        <CFormLabel className="mr-2">Min Threshold</CFormLabel><span className="text-danger">*</span>
                        <CFormInput type="number" value={minThreshold} onChange={(e) => setMinThreshold(e.target.value)} disabled={editMode} />
                      </CCol>
                      <CCol>
                        <CFormLabel className="mr-2">Mid Threshold</CFormLabel><span className="text-danger">*</span>
                        <CFormInput type="number" value={midThreshold} onChange={(e) => setMidThreshold(e.target.value)} disabled={editMode} />
                      </CCol>
                      <CCol>
                        <CFormLabel className="mr-2">Max Threshold</CFormLabel><span className="text-danger">*</span>
                        <CFormInput type="number" value={maxThreshold} onChange={(e) => setMaxThreshold(e.target.value)} disabled={editMode} />
                      </CCol>
                      <CCol>
                        <CFormLabel className="mr-2">Paper Type</CFormLabel><span className="text-danger">*</span>
                        <select className="form-control" value={paperType} onChange={(e) => setPaperType(e.target.value)} disabled={editMode}>
                          <option value="">Select</option>
                          <option value="A4">A4</option>
                          <option value="A5">A5</option>
                        </select>
                      </CCol>
                    </CRow>
                  </CModalBody>
                  <CModalFooter>
                    <CButton color="success" onClick={() => handleSubmit()}>
                      Confirm
                    </CButton>{" "}
                    <CButton color="danger" onClick={() => handleCancel()}>
                      Cancel
                    </CButton>
                  </CModalFooter>
                </CModal>}
            </CCardBody>
          </CCard>
        </CContainer>
      </div>
    </>
  );
};

export default CropsList;
