import {useState} from "react"
import { CModal, CModalHeader, CModalTitle, CModalBody, CButton, CModalFooter, CRow, CCol, CFormLabel, CFormInput } from "@coreui/react";
import { AppConfirmModal } from "../../../../components";

const RejectReasonModal = ({ handleRejectCancel, isRejectReasonModalOpen, submitRejectReason }) => {
  
    const [rejectReason, setRejectReason] = useState("")
    const [confirmModal, setConfirmModal] = useState(false)

    const handleRequestReason = (e) => {
        setRejectReason(e.target.value);
    }

    const onConfirmReject = () => {
        setConfirmModal(false);
        submitRejectReason(rejectReason);
        setRejectReason("")
    }

    return (
        <>
        <CModal alignment="center" size="lg" backdrop="static" visible={isRejectReasonModalOpen} onClose={() => handleRejectCancel()}>
            <CModalHeader onClose={() => handleRejectCancel()}>
                <CModalTitle><h3><strong>Reject</strong></h3></CModalTitle>
            </CModalHeader>
            <CModalBody>
          <CRow>
            <CCol>
              <CFormLabel>Reason to reject the request</CFormLabel>
              <CFormInput
                type="text"
                value={rejectReason}
                onChange={handleRequestReason}
              />
            </CCol>
          
          </CRow>
          <br />
        </CModalBody>
            <CModalFooter>
            <CButton color="success" onClick={() => setConfirmModal(true)} disabled={!rejectReason.length}>
            Reject
          </CButton>{" "}
          <CButton color="danger" onClick={() => handleRejectCancel()}>
            Cancel
          </CButton>
            </CModalFooter>
        </CModal>
         <AppConfirmModal
         isOpen={confirmModal}
         title={"Reject"}
         message={"Are you sure you want to reject?"}
         onCancel={() => setConfirmModal(false)}
         onConfirm={() => onConfirmReject()}
       />
       </>
    );
}

export default RejectReasonModal;