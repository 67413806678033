import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//UI Elements
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CCardTitle,
  CImage,
  CFormSwitch,
  CFormLabel,
  CFormInput,
  CCollapse,
  CSpinner,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilReload } from "@coreui/icons";
//HTTP Libraries
import axios from "axios";
//Firebase
import { auth } from "../../../../firebase";
//Components
import AppMessageModal from "../../../../components/AppMessageModal";
import AppConfirmModal from "../../../../components/AppConfirmModal";

const AddDefaultMetadata = () => {
  //States
  const [loading, setLoading] = useState(false);
  const [metadataValue, setMetadataValue] = useState("");
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");
  const [messageModal, setMessageModal] = useState(false);

  //Functions

  async function addCustomField() {
    let company_id = window.location.href.split("/").pop();
    const keyName = metadataValue.toLowerCase().replace(/ /g, "_");
    if (metadataValue === "") {
      setTitle("Error");
      setMessage("Metadata Value cannot be empty");
      setMessageModal(true);
      return;
    }
    setLoading(true);
    try {
      await new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          } else {
            reject(new Error("User is not authenticated."));
          }
        });
      });

      let authToken = await auth.currentUser.getIdToken();
      let result = await axios.post(
        window.config.commonEndpoint + "scans/add_default_metadata",
        {
          metadata_value: metadataValue,
          metadata_type: 2,
          metadata_key: keyName,
        },
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      setLoading(false);
      setTitle("Success");
      setMessage("Added Metadata successfully");
      setMessageModal(true);
      // console.log( {
      //   metadata_value: metadataValue,
      //   metadata_type: 2,
      //   metadata_key: keyName,
      // })
      console.log(result);
      setMetadataValue("");
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }
  return (
    <>
      <CCard>
        <CCardHeader>
          <CRow className="justify-items-between">
            <CCol xs={7}>
              <CCardTitle>
                <h3>
                  <strong>Add Default Metadata</strong>
                </h3>
              </CCardTitle>
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardBody>
          <br />
          <CRow>
            <CCol>
              <CFormLabel>
                <strong>Metadata Value</strong>
              </CFormLabel>
              <CFormInput
                type="text"
                value={metadataValue}
                onChange={(e) => setMetadataValue(e.target.value)}
                placeholder="Enter Metadata Value"
              />
            </CCol>
          </CRow>
          <br />
          <CButton
            color="success"
            className="text-white"
            onClick={() => addCustomField()}
            disabled={loading}
          >
            {loading ? <CSpinner color="light" /> : "Add Metadata"}
          </CButton>
        </CCardBody>
      </CCard>
      <AppMessageModal
        isOpen={messageModal}
        title={title}
        message={message}
        handleClose={() => setMessageModal(false)}
      />
      <br />
    </>
  );
};

export default AddDefaultMetadata;
