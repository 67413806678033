import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//UI Elements
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CCardTitle,
  CSpinner,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
  CContainer,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilReload } from "@coreui/icons";
//HTTP Libraries
import axios from "axios";
//Firebase
import { auth } from "../../../../firebase";
import AppConfirmModal from "../../../../components/AppConfirmModal";
import AppMessageModal from "../../../../components/AppMessageModal";

const CompanyAttachMachines = () => {
  //States
  const [loading, setLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [allMachines, setAllMachines] = useState([]);
  const [companyMachines, setCompanyMachines] = useState([]);
  const [filteredMachines, setFilteredMachines] = useState([]);
  const [cropId, setCropId] = useState("");
  const [selectedMachine, setSelectedMachine] = useState(null);
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");
  const [messageModal, setMessageModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deletedMachine, setDeletedMachine] = useState("");
  //Functions

  useEffect(() => {
    getAllMachines();
  }, []);

  useEffect(() => {
    let filteredMachines = allMachines.filter(
      (machine) =>
        !companyMachines.some(
          (companyMachines) =>
            companyMachines.machine_code === machine.machine_code
        )
    );
    setFilteredMachines(filteredMachines);
  }, [companyMachines, allMachines]);

  async function getAllMachines() {
    allCompanyMachines();

    let company_id = window.location.href.split("/").pop();
    try {
      await new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          } else {
            reject(new Error("User is not authenticated."));
          }
        });
      });

      let authToken = await auth.currentUser.getIdToken();
      let result = await axios.get(window.config.commonEndpoint + "machines/all", {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      let totalMachines = result.data.data.machines;
      //   console.log(totalMachines)
      let filteredMachines = totalMachines.filter(
        (machine) =>
          !companyMachines.some(
            (companyMachine) =>
              companyMachine.machine_code === machine.machine_code
          )
      );
      setAllMachines(totalMachines);
      setFilteredMachines(filteredMachines);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  async function allCompanyMachines() {
    let company_id = window.location.href.split("/").pop();
    setLoading(true);
    try {
      await new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          } else {
            reject(new Error("User is not authenticated."));
          }
        });
      });

      let authToken = await auth.currentUser.getIdToken();
      let result = await axios.post(
        window.config.commonEndpoint + "machines/get/company",
        {
          company_id: company_id,
        },
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      setLoading(false);
      //   console.log(result.data.data.machines_data)
      setCompanyMachines(result.data.data.machines_data);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  async function attachMachine() {
    let company_id = window.location.href.split("/").pop();
    setLoading(true);
    try {
      await new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          } else {
            reject(new Error("User is not authenticated."));
          }
        });
      });

      let authToken = await auth.currentUser.getIdToken();
      let result = await axios.post(
        window.config.commonEndpoint + "machines/attach/company",
        {
          company_id: company_id,
          machine_code: selectedMachine.machine_code,
        },
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      if (result.status === 200) {
        setTitle("Success");
        setMessage("Machine Attached Successfully");
        setMessageModal(true);
        setSelectedMachine(null);
        getAllMachines();
      }
    } catch (error) {
      setMessage(error.response.data.message);
      setTitle('Error');
      setMessageModal(true);
    } finally {
      setLoading(false);
    }
  }

  async function detachMachine() {
    let company_id = window.location.href.split("/").pop();
    setDeleteLoading(true);
    setConfirmModal(false);
    try {
      await new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          } else {
            reject(new Error("User is not authenticated."));
          }
        });
      });

      let authToken = await auth.currentUser.getIdToken();
      let result = await axios.post(
        window.config.commonEndpoint + "machines/detach/company",
        {
          company_id: company_id,
          machine_code: deletedMachine.machine_code,
        },
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      getAllMachines();
      if (result.status === 200) {
        setMessage(
          `${deletedMachine.machine_name} ${deletedMachine.machine_code} detached successfully.`
        );
        setTitle("Success");
        setMessageModal(true);
        setDeleteLoading(false);
      }
      console.log(result.data);
    } catch (error) {
      console.log(error);
      setMessage("Something went wrong. Please try again later.");
      setTitle("Error");
      setMessageModal(true);
      setDeleteLoading(false);
    }
  }

  function handleDetachMachine(machine) {
    setDeletedMachine(machine);
    setMessage(
      `Are you sure you want to detach ${machine.machine_name} ${machine.machine_code}`
    );
    setTitle("Detach Machine");
    setConfirmModal(true);
  }

  return (
    <div>
      <CCard>
        <CCard>
          <CCardHeader>
            <CCardTitle>
              <CRow>
                <CCol>
                  <h3>
                    <strong>Machines</strong>
                  </h3>
                </CCol>
                <CCol xs={2}>
                  <CButton color="light" onClick={() => getAllMachines()}>
                    <CIcon size="lg" icon={cilReload} />
                  </CButton>
                </CCol>
              </CRow>
            </CCardTitle>
          </CCardHeader>
          <CCardBody>
            {loading ? (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
                <CSpinner color="dark" />
              </div>
            ) : (
              <div style={{ maxHeight: "200px", overflowY: "auto" }}>
                <CTable align="middle">
                  <CTableHead>
                    <CTableRow>
                      <CTableHeaderCell>Machine Name</CTableHeaderCell>
                      <CTableHeaderCell>Machine Code</CTableHeaderCell>
                      <CTableHeaderCell>Action</CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>
                  {loading ? (
                    <CSpinner color="dark" />
                  ) : (
                    <CTableBody  >
                      {companyMachines === undefined ||
                        companyMachines.length === 0 ? (
                        <CTableHeaderCell>No data to display</CTableHeaderCell>
                      ) : (
                        companyMachines.map((machine, index) => {
                          return (
                            <CTableRow key={index}>
                              <CTableDataCell>
                                {machine.machine_name}
                              </CTableDataCell>
                              <CTableDataCell>
                                {machine.machine_code}
                              </CTableDataCell>
                              <CTableDataCell>
                                <CButton
                                  color="danger"
                                  onClick={() => handleDetachMachine(machine)}
                                >
                                  Detach
                                </CButton>
                              </CTableDataCell>
                            </CTableRow>
                          );
                        })
                      )}
                    </CTableBody>
                  )}
                </CTable>
              </div>
            )}
            <CContainer>
              <CRow>
                <CCol>
                  <CDropdown>
                    <CDropdownToggle color="secondary">
                      {selectedMachine
                        ? `${selectedMachine.machine_name} - ${selectedMachine.machine_code}`
                        : "Select Machine"}
                    </CDropdownToggle>
                    <CDropdownMenu>
                      {filteredMachines &&
                        filteredMachines.map((machine, index) => (
                          <CDropdownItem
                            key={index}
                            onClick={() => setSelectedMachine(machine)}
                          >
                            {machine.machine_name} - {machine.machine_code}
                          </CDropdownItem>
                        ))}
                    </CDropdownMenu>
                  </CDropdown>
                </CCol>
                <CCol>
                  <CButton
                    className={selectedMachine ? "APP-BACKGROUND" : "APP-FADE-BACKGROUND APP-FADE-BORDER"}
                    onClick={() => attachMachine()}
                    disabled={loading || !selectedMachine}
                  >
                    {loading ? (
                      <CSpinner color="light" />
                    ) : (
                      "Attach a new machine"
                    )}
                  </CButton>
                </CCol>
              </CRow>
            </CContainer>
          </CCardBody>
        </CCard>
      </CCard>
      <AppConfirmModal
        isOpen={confirmModal}
        onCancel={() => setConfirmModal(false)}
        onConfirm={() => detachMachine()}
        title={title}
        message={message}
      />
      <AppMessageModal
        isOpen={messageModal}
        handleClose={() => setMessageModal(false)}
        title={title}
        message={message}
      />
      <br />
      <br />
    </div>
  );
};
export default CompanyAttachMachines;
