import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//UI Elements
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CCardTitle,
  CImage,
  CFormSwitch,
  CFormLabel,
  CFormInput,
  CCollapse,
  CSpinner,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilReload } from "@coreui/icons";
//HTTP Libraries
import axios from "axios";
//Firebase
import { auth } from "../../../../firebase";
//Components
import AppMessageModal from "../../../../components/AppMessageModal";
import AppConfirmModal from "../../../../components/AppConfirmModal";

const CompanyUpdate = () => {
  const navigate = useNavigate();
  //States
  const [loading, setLoading] = useState(true);
  const [companyInfo, setCompanyInfo] = useState(undefined);
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");
  const [messageModal, setMessageModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [noOfUsers, setNoOfUsers] = useState("");
  const [gstNumber, setGstNumber] = useState("");
  const [shortName, setShortName] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [address, setAddress] = useState("");
  const [inTrialPeriod, SetInTrialPeriod] = useState(false);
  const [companyLogo, setCompanyLogo] = useState("");
  const [editFields, setEditFields] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [companyType, setCompanyType] = useState("");
  const [pdfType, setPdfType] = useState("");
  const [responseType, setResponseType] = useState("");

  //Functions
  useEffect(() => {
    getCompanyDetails();
  }, []);

  function handleConfirm() {
    setMessage("Are you sure you want to update the company details?");
    setTitle("Update Company");
    setConfirmModal(true);
  }

  function handleDeleteConfirm() {
    setMessage("Are you sure you want to delete this company?");
    setTitle("Delete Company");
    setConfirmModal(true);
  }

  async function getCompanyDetails() {
    setLoading(true);
    let company_id = window.location.href.split("/").pop();
    try {
      await new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          } else {
            reject(new Error("User is not authenticated."));
          }
        });
      });

      let authToken = await auth.currentUser.getIdToken();
      let result = await axios.get(
        window.config.commonEndpoint + "company/get/" + company_id,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      console.log(result.data.data.companyInfo);
      setCompanyInfo(result.data.data.companyInfo);
      setCompanyName(result.data.data.companyInfo.name);
      setNoOfUsers(result.data.data.companyInfo.number_of_users);
      setGstNumber(result.data.data.companyInfo.gst_number);
      setShortName(result.data.data.companyInfo.short_name);
      setCompanyLogo(result.data.data.companyInfo.company_logo);
      setCompanyType(result.data.data.companyInfo.company_type);
      setPdfType(result.data.data.companyInfo.pdf_type);
      setResponseType(result.data.data.companyInfo.response_type)
      setContactNo(result.data.data.companyInfo.contact_no);
      SetInTrialPeriod(result.data.data.companyInfo.in_trial_period);
      setAddress(result.data.data.companyInfo.address);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }
  const handleOptionChange = (selectedPdfType) => {
    setPdfType(selectedPdfType);
  };

  const handleResponseOptionChange = (selectedResponseType) => {
    setResponseType(selectedResponseType);
  };

  async function updateCompanyInfo() {
    setConfirmModal(false);
    setUpdateLoading(true);
    try {
      await new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          } else {
            reject(new Error("User is not authenticated."));
          }
        });
      });

      let authToken = await auth.currentUser.getIdToken();
      let result = await axios.post(
        window.config.commonEndpoint + "company/update",
        {
          id: companyInfo.id,
          name: companyName,
          number_of_users: noOfUsers,
          company_logo: companyLogo,
          gst_number: gstNumber,
          short_name: shortName,
          company_type: companyType,
          pdf_type: pdfType,
          contact_no: "+91" + contactNo,
          address: address,
          in_trial_period: inTrialPeriod,
          response_type: responseType
        },
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      if (result.status === 200) {
        setTitle("Success");
        setMessage("Company Information Updated Successfully");
        setMessageModal(true);
        setUpdateLoading(false);
      }
      console.log(result);
    } catch (error) {
      console.log(error);
      setUpdateLoading(false);
    }
  }

  async function deleteCompany() {
    setConfirmModal(false);
    setLoading(true);
    try {
      await new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          } else {
            reject(new Error("User is not authenticated."));
          }
        });
      });

      let authToken = await auth.currentUser.getIdToken();
      let result = await axios.post(
        window.config.commonEndpoint + "company/delete",
        { id: companyInfo.id },
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      setLoading(false);
      setMessage(result.data.message);
      setTitle(result.data.title);
      setConfirmModal(true);
      navigate("/home/company/create");
    } catch (error) {
      setLoading(false);
      setMessage("Error while deleting company");
      setTitle("Error");
      setConfirmModal(true);
    }
  }

  const handleAddressChange = (e) => {
    const input = e.target.value.substring(0, 100);
    setAddress(input);
  };

  const handleContactNoChange = (e) => {
    const input = e.target.value.replace(/[^\d+]/g, '').substring(0, 14);
    setContactNo(input);
  };

  return (
    <>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
          <CSpinner color="dark" />
        </div>
      ) : (
        <CCard>
          <CCardHeader>
            <CRow className="justify-items-between">
              <CCol xs={7}>
                <CCardTitle>
                  <h3>
                    <strong>Company Information</strong>
                  </h3>
                </CCardTitle>
              </CCol>
              <CCol xs={2}>
                <CButton color="light" onClick={() => getCompanyDetails()}>
                  <CIcon size="lg" icon={cilReload} />
                </CButton>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <CImage
              thumbnail
              rounded
              width={100}
              height={100}
              src={companyLogo}
            />
            <br />
            <CFormSwitch
              label="Update Company Information"
              checked={editFields}
              onChange={(e) => setEditFields(e.target.checked)}
            />
            <CRow>
              <CCol>
                <CFormLabel>
                  <strong>Company Name</strong>
                </CFormLabel>
                <CFormInput
                  type="text"
                  value={
                    companyName === null || companyName === undefined
                      ? ""
                      : companyName
                  }
                  onChange={(e) => setCompanyName(e.target.value)}
                  placeholder="Enter Company Name Here"
                  disabled={!editFields}
                />
              </CCol>
              <CCol>
                <CFormLabel>
                  <strong>Short Code</strong>
                </CFormLabel>
                <CFormInput
                  type="text"
                  value={
                    shortName === null || shortName === undefined
                      ? ""
                      : shortName
                  }
                  onChange={(e) => setShortName(e.target.value)}
                  placeholder="Enter Short Code Here"
                  disabled={!editFields}
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol>
                <CFormLabel>
                  <strong>GST Number</strong>
                </CFormLabel>
                <CFormInput
                  type="text"
                  value={
                    gstNumber === null || gstNumber === undefined
                      ? ""
                      : gstNumber
                  }
                  onChange={(e) => setGstNumber(e.target.value)}
                  placeholder="Enter GST Number Here"
                  disabled={!editFields}
                />
              </CCol>
              <CCol>
                <CFormLabel>
                  <strong>Number of Users</strong>
                </CFormLabel>
                <CFormInput
                  type="number"
                  value={
                    noOfUsers === null || noOfUsers === undefined
                      ? ""
                      : noOfUsers
                  }
                  onChange={(e) => setNoOfUsers(e.target.value)}
                  placeholder="Enter Number of Users Here"
                  disabled={!editFields}
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol>
                <CFormLabel>
                  <strong>Company Type</strong>
                </CFormLabel>
                <br />
                <CDropdown>
                  <CDropdownToggle color="secondary" disabled={!editFields}>
                    {companyType ? `${companyType}` : "Select Company Type"}
                  </CDropdownToggle>
                  <CDropdownMenu>
                    <CDropdownItem onClick={() => setCompanyType("UPJAO")}>
                      UPJAO
                    </CDropdownItem>
                    <CDropdownItem
                      onClick={() => setCompanyType("PARAKH_APMC")}
                    >
                      PARAKH_APMC
                    </CDropdownItem>
                    <CDropdownItem
                      onClick={() => setCompanyType("PARAKH_PACS")}
                    >
                      PARAKH_PACS
                    </CDropdownItem>
                  </CDropdownMenu>
                </CDropdown>
              </CCol>
              <CCol>
                <CFormLabel>
                  <strong>Company Logo URL</strong>
                </CFormLabel>
                <CFormInput
                  type="text"
                  value={
                    (companyLogo === null) | (companyLogo === undefined)
                      ? ""
                      : companyLogo
                  }
                  onChange={(e) => setCompanyLogo(e.target.value)}
                  placeholder="Enter Company Logo URL Here"
                  disabled={!editFields}
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol>
                <CFormLabel>
                  <strong>PDF Type</strong>
                </CFormLabel>
                <br />
                <CDropdown>
                  <CDropdownToggle color="secondary" disabled={!editFields} >
                    {pdfType}
                  </CDropdownToggle>
                  <CDropdownMenu>
                    <CDropdownItem onClick={() => handleOptionChange('LONG')}>LONG</CDropdownItem>
                    <CDropdownItem onClick={() => handleOptionChange('SHORT')}>SHORT</CDropdownItem>
                  </CDropdownMenu>
                </CDropdown>
              </CCol>
              <CCol>
                <CFormLabel>
                  <strong>Contact No</strong>
                </CFormLabel>
                <br />
                <CFormInput
                  type="tel"
                  pattern="[0-9]*"
                  value={
                    contactNo === null || contactNo === undefined
                      ? ""
                      : contactNo
                  }
                  maxLength={14}
                  onChange={handleContactNoChange}
                  placeholder="Enter Contact No Here"
                  disabled={!editFields}
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol>
                <CFormLabel>
                  <strong>In Trial Period</strong>
                </CFormLabel>
                <br />
                <CFormSwitch
                  label="In Trial Period?"
                  checked={inTrialPeriod}
                  onChange={(e) => {
                    SetInTrialPeriod(e.target.checked);
                  }}
                  disabled={!editFields}
                />
              </CCol>
              <CCol>
                <CFormLabel>
                  <strong>Address</strong>
                </CFormLabel>
                <br />
                <CFormInput
                  type="text"
                  value={
                    address === null || address === undefined
                      ? ""
                      : address
                  }
                  onChange={handleAddressChange}
                  placeholder="Enter Address Here"
                  disabled={!editFields}
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol>
                <CFormLabel>
                  <strong>Response Type</strong>
                </CFormLabel>
                <br />
                <CDropdown>
                  <CDropdownToggle color="secondary" disabled={!editFields} >
                    {responseType}
                  </CDropdownToggle>
                  <CDropdownMenu>
                    <CDropdownItem onClick={() => handleResponseOptionChange('DEFAULT_UPJAO')}>DEFAULT_UPJAO</CDropdownItem>
                    <CDropdownItem onClick={() => handleResponseOptionChange('DEFAULT_ENAM')}>DEFAULT_ENAM</CDropdownItem>
                  </CDropdownMenu>
                </CDropdown>
              </CCol>
            </CRow>
            <br />
            <CCollapse visible={editFields}>
              <CButton className="APP-BACKGROUND APP-BORDER-2" onClick={() => handleConfirm()}>
                {updateLoading ? (
                  <CSpinner color="light" />
                ) : (
                  "Update Company Details"
                )}
              </CButton>
              {"   "}
              <CButton className="APP-BACKGROUND-2 APP-BORDER" onClick={() => handleDeleteConfirm()}>
                Delete Company
              </CButton>
            </CCollapse>
          </CCardBody>
        </CCard>
      )}
      {/* Message Modal */}
      <AppMessageModal
        isOpen={messageModal}
        message={message}
        title={title}
        handleClose={() => setMessageModal(false)}
      />
      {/* Confirm Modal */}
      <AppConfirmModal
        isOpen={confirmModal}
        title={title}
        message={message}
        onCancel={() => setConfirmModal(false)}
        onConfirm={
          title === "Update Company"
            ? () => updateCompanyInfo()
            : () => deleteCompany()
        }
      />
    </>
  );
};

export default CompanyUpdate;
