import {
  CCol,
  CContainer,
  CRow,
  CNavbar,
  CNavbarBrand,
  CButton,
} from "@coreui/react";
import React, { useState, useEffect } from "react";
import AllUsers from "./Components/AllUsers";

const UserLayout = () => {
  return (
    <CContainer>
      <CRow>
        <CCol>
          <AllUsers />
        </CCol>
      </CRow>
    </CContainer>
  );
};

export default UserLayout;
