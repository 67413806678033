import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//UI Elements
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CCardTitle,
  CImage,
  CFormSwitch,
  CFormLabel,
  CFormInput,
  CCollapse,
  CSpinner,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilReload } from "@coreui/icons";
//HTTP Libraries
import axios from "axios";
//Firebase
import { auth } from "../../../../firebase";
//Components
import AppMessageModal from "../../../../components/AppMessageModal";

const ParakhAddSubCompanies = ({ setIsRefreshComponent }) => {
  const navigate = useNavigate();
  //States
  const [subCompanies, setSubCompanies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [companyType, setCompanyType] = useState("");
  const [messageModal, setMessageModal] = useState(false);
  const [message, setMessage] = useState('');
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [admin_name, setAdminName] = useState("");
  const [admin_contact_number, setAdminContactNumber] = useState("");
  const [sub_company_address_one, setSubCompanyAddressOne] = useState("");
  const [sub_company_address_two, setSubCompanyAddressTwo] = useState("");
  const [isValidPhone, setIsValidPhone] = useState(false)

  //Functions
  useEffect(() => {
    getCompanyDetails();
    getSubCompanies();
  }, []);

  async function getCompanyDetails() {
    setLoading(true);
    let company_id = window.location.href.split("/").pop();
    try {
      await new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          } else {
            reject(new Error("User is not authenticated."));
          }
        });
      });

      let authToken = await auth.currentUser.getIdToken();
      let result = await axios.get(
        window.config.commonEndpoint + "company/get/" + company_id,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      setCompanyType(result.data.data.companyInfo.company_type);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }
  async function getSubCompanies() {
    let company_id = window.location.href.split("/").pop();
    try {
      await new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          } else {
            reject(new Error("User is not authenticated."));
          }
        });
      });

      let authToken = await auth.currentUser.getIdToken();
      let result = await axios.get(
        window.config.endpoint + "sub_company/for_parent/" + company_id,
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      result?.data?.data && setSubCompanies(result?.data?.data);
    } catch (error) {
      console.error(error);
    }
  }
  async function addSubCompany() {
    if (admin_name.length >= 50) {
      setMessage("Please enter admin name must less than 50 characters.");
      setMessageModal(true);
      return;
    }
    if (sub_company_address_one.length >= 200 || sub_company_address_two.length >= 200) {
      setMessage("Please enter company address must less than 200 characters.");
      setMessageModal(true);
      return;
    }
    let company_id = window.location.href.split("/").pop();
    let cType = companyType.split("_")[1];
    if (subCompanies && subCompanies?.some(company => company.company_name === companyName)) {
      alert("Company name already exists!");
      return;
    }
    setLoading(true);
    try {
      await new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          } else {
            reject(new Error("User is not authenticated."));
          }
        });
      });

      let authToken = await auth.currentUser.getIdToken();
      let result = await axios.post(
        window.config.endpoint + "sub_company",
        {
          parent_company_id: company_id,
          company_name: companyName,
          company_type: cType,
          company_location: {
            latitude: parseFloat(latitude),
            longitude: parseFloat(longitude),
          },
          company_admin_name: admin_name,
          company_admin_contact_number: admin_contact_number,
          company_address_one: sub_company_address_one,
          company_address_two: sub_company_address_two
        },
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      if (result.status === 200) {
        setMessage(result.data.message);
        setMessageModal(true)
        setIsRefreshComponent(true)
      }
      setCompanyName("")
      setLongitude("")
      setLatitude("")
      setAdminName('')
      setAdminContactNumber('')
      setSubCompanyAddressOne('')
      setSubCompanyAddressTwo('')
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error.response.data.status === false) {
        setMessage(error.response.data.message);
        setMessageModal(true)
      }
      setLoading(false);
    }
  }

  const onCheckPhoneValidation = (e) => {
    let isValid = false
    const regex = /^[6-9]\d{9}$/;
    isValid = regex.test(e.target.value)
    setIsValidPhone(isValid)
    setAdminContactNumber(e.target.value)
  }

  return (
    <div>
      <AppMessageModal message={message} handleClose={() => setMessageModal(false)} isOpen={messageModal} />
      <CCard>
        <CCardHeader>
          <CRow className="justify-items-between">
            <CCol>
              <CCardTitle>
                <h3>
                  <strong>Add Sub Company</strong>
                </h3>
              </CCardTitle>
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardBody>
          <form onSubmit={(e) => {
            e.preventDefault();
            addSubCompany();
          }}>
            <br />
            <CRow>
              <CCol>
                <CFormLabel>
                  <strong>Company Name</strong>
                </CFormLabel>
                <CFormInput
                  type="text"
                  required
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  placeholder="Company Name"
                />
              </CCol>
            </CRow>
            <br />
            <CRow>
              <CCol>
                <CFormLabel>
                  <strong>Latitude</strong>
                </CFormLabel>
                <CFormInput
                  type="number"
                  value={latitude}
                  required
                  onChange={(e) => setLatitude(e.target.value)}
                  placeholder="Latitude"
                />
              </CCol>
              <CCol>
                <CFormLabel>
                  <strong>Longitude</strong>
                </CFormLabel>
                <CFormInput
                  type="number"
                  value={longitude}
                  required
                  onChange={(e) => setLongitude(e.target.value)}
                  placeholder="Longitude"
                />
              </CCol>
            </CRow>
            <br />
            <CRow>
              <CCol>
                <CFormLabel>
                  <strong>Admin Name</strong>
                </CFormLabel>
                <CFormInput
                  type="text"
                  value={admin_name}
                  required
                  onChange={(e) => setAdminName(e.target.value)}
                  placeholder="Admin Name"
                />
              </CCol>
              <CCol>
                <CFormLabel>
                  <strong>Contact Number</strong>
                </CFormLabel>
                <CFormInput
                  type="number"
                  value={admin_contact_number}
                  required
                  onChange={(e) => onCheckPhoneValidation(e)}
                  placeholder="Contact Number"
                />
                {!isValidPhone && admin_contact_number && <p className="red">Please enter valid phone number.</p>}
              </CCol>
            </CRow>
            <br />
            <CRow>
              <CCol>
                <CFormLabel>
                  <strong>Company Address 1</strong>
                </CFormLabel>
                <CFormInput
                  type="text"
                  value={sub_company_address_one}
                  required
                  onChange={(e) => setSubCompanyAddressOne(e.target.value)}
                  placeholder="Address"
                />
              </CCol>
            </CRow>
            <br />
            <CRow>
              <CCol>
                <CFormLabel>
                  <strong>Company Address 2</strong>
                </CFormLabel>
                <CFormInput
                  type="text"
                  value={sub_company_address_two}
                  required
                  onChange={(e) => setSubCompanyAddressTwo(e.target.value)}
                  placeholder="Address"
                />
              </CCol>
            </CRow>
            <br />
            <CButton
              className={(loading || !(companyName && isValidPhone)) ? "APP-FADE-BACKGROUND APP-FADE-BORDER" : "APP-BACKGROUND"}
              type="submit"
              disabled={loading || !(companyName && isValidPhone)}
            >
              {loading ? <CSpinner color="light" /> : "Add Sub Company"}
            </CButton>
          </form>
        </CCardBody>
      </CCard>
    </div>
  );
};

export default ParakhAddSubCompanies;
