import { CCol, CFormInput, CFormLabel, CFormSelect, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle, CRow, CButton } from "@coreui/react";
import React, { useState, useEffect } from "react";
import { HexColorPicker } from "react-colorful";
import AppMessageModal from "../../../../components/AppMessageModal";
import AppConfirmModal from "../../../../components/AppConfirmModal";
import axios from "axios";
import { auth } from "../../../../firebase";


const UpdateClass = ({ isOpen, handleCancel, handleConfirm, selectedCrop }) => {
    //States for the component
    const [className, setClassName] = useState('');
    const [classCode, setClassCode] = useState('');
    const [classColor, setClassColor] = useState('');
    const [classDisplayName, setClassDisplayName] = useState('');
    const [language, setLanguage] = useState('');
    const [classID, setClassID] = useState('');
    const [message, setMessage] = useState('');
    const [title, setTitle] = useState('');
    const [messageModal, setMessageModal] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);
    //Functions for the component 
    useEffect(() => {
        if (isOpen && selectedCrop) {
            setClassName(selectedCrop.class_name || '');
            setClassCode(selectedCrop.class_code || '');
            setClassColor(selectedCrop.color || '');
            setClassDisplayName(selectedCrop.class_display_name || '');
            setLanguage(selectedCrop.language || '');
            setClassID(selectedCrop.class_id || '');
        }
    }, [isOpen, selectedCrop]);

    const handleClassName = (e) => {
        const trimmedValue = e.target.value.trim();
        setClassName(trimmedValue);
    }

    const handleClassCode = (e) => {
        const trimmedValue = e.target.value.trim();
        setClassCode(trimmedValue);
    }

    const handleClassDisplayName = (e) => {
        const trimmedValue = e.target.value.trim();
        setClassDisplayName(trimmedValue);
    }

    const handleLanguage = (e) => {
        setLanguage(e.target.value);
    }

    const handleClassID = (e) => {
        const trimmedValue = e.target.value.trim();
        setClassID(trimmedValue);
    }

    function handleMessageClose() {
        setMessageModal(false);
        isOpen = true;
    }

    async function createClass() {
        try {
            await new Promise((resolve, reject) => {
                auth.onAuthStateChanged((user) => {
                    if (user) {
                        resolve(user);
                    } else {
                        reject(new Error("User is not authenticated."));
                    }
                });
            });

            let authToken = await auth.currentUser.getIdToken();
            let result = await axios.post(window.config.commonEndpoint + 'crops/add/class/master', { class_name: className, class_code: classCode, color: classColor, class_display_name: classDisplayName, language: language, class_id: classID }, {
                headers: { Authorization: `Bearer ${authToken}` }
            });
            if (result.status == 200) {
                setConfirmModal(false);
                setMessage(result?.data?.message);
                setTitle('Success');
                setMessageModal(true);
                handleConfirm();
            }
        } catch (error) {
            setMessage('Error Updating class');
            setTitle('Error');
            setMessageModal(true);
        }
    }

    function confirmNewClass() {
        if (className === '' || classCode === '' || classColor === '' || classDisplayName === '' || language === '' || classID === '') {
            setMessage('Please fill all the fields');
            setTitle('Error');
            setMessageModal(true);
            return;
        } else {
            setMessage('Are you sure you want to update this class?');
            setTitle('Confirm');
            setConfirmModal(true);
            return;
        }
    }

    return (
        <>
            <CModal backdrop="static" alignment="center" size="lg" visible={isOpen} onClose={() => handleCancel()}>
                <CModalHeader onClose={() => handleCancel()}>
                    <CModalTitle><h3><strong>Update crop class</strong></h3></CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <CRow>
                        <CCol>
                            <CFormLabel><h5>Class Name</h5></CFormLabel>
                            <CFormInput value={className} onChange={handleClassName} placeholder="No Defect, Broken etc." />
                        </CCol>
                        <CCol>
                            <CFormLabel><h5>Class Code</h5></CFormLabel>
                            <CFormInput value={classCode} onChange={handleClassCode} placeholder="ND, BK etc." />
                        </CCol>
                    </CRow>
                    <br />
                    <CRow>
                        <CCol>
                            <CFormLabel><h5>Class Color</h5></CFormLabel>
                            <HexColorPicker color={classColor} onChange={setClassColor} />
                            <br />
                            <h5>Selected Color: {classColor}</h5>
                        </CCol>
                        <CCol>
                            <CFormLabel><h5>Class Display Name</h5></CFormLabel>
                            <CFormInput value={classDisplayName} onChange={handleClassDisplayName} placeholder="No Defect Kernel, Broken Kernel etc." />
                        </CCol>
                    </CRow>
                    <br />
                    <CRow>
                        <CCol>
                            <CFormLabel><h5>Language</h5></CFormLabel>
                            <CFormSelect value={language} disabled onChange={handleLanguage}>
                                <option value="">Select Language</option>
                                <option value="EN">EN</option>
                                <option value="HI">HI</option>
                                <option value="GU">GU</option>
                            </CFormSelect>
                        </CCol>
                        <CCol>
                            <CFormLabel><h5>Class ID</h5></CFormLabel>
                            <CFormInput value={classID} disabled onChange={handleClassID} placeholder="no_defect, broken etc." />
                        </CCol>
                    </CRow>
                </CModalBody>
                <CModalFooter>
                    <CButton color="success" onClick={() => confirmNewClass()}>Confirm</CButton>
                    <CButton color="danger" onClick={() => handleCancel()}>Cancel</CButton>
                </CModalFooter>
            </CModal>
            {/* Message Modal */}
            <AppMessageModal isOpen={messageModal} handleClose={() => setMessageModal(false)} title={title} message={message} />
            {/* Confirm Modal */}
            <AppConfirmModal isOpen={confirmModal} onCancel={() => setConfirmModal(false)} onConfirm={() => createClass()} title={title} message={message} />
        </>
    );
}

export default UpdateClass;