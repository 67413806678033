import { CNavGroup, CNavItem, CNavbarBrand } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import {
  cilHome,
  cilPlant,
  cilBuilding,
  cilDevices,
  cilUser,
  cilBarChart,
  cilSettings,
} from "@coreui/icons";

const _nav = [
  {
    component: CNavItem,
    name: "Home",
    to: "/home",
    icon: <CIcon icon={cilHome} customClassName="nav-icon" />,
  },
  {
    component: CNavGroup,
    name: "Crops",
    to: "/crops",
    icon: <CIcon icon={cilPlant} customClassName="nav-icon"/>,
    items: [
      {
        component: CNavItem,
        name: "Crops List",
        to: "/home/crops/create",
      },
      {
        component: CNavItem,
        name: "Crop Classes",
        to: "/home/crops/class",
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Company",
    to: "/company",
    icon: <CIcon icon={cilBuilding} customClassName="nav-icon"/>,
    items: [
      {
        component: CNavItem,
        name: "Manage Company",
        to: "/home/company/create",
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Machines",
    to: "/machines",
    icon: <CIcon icon={cilDevices} customClassName="nav-icon"/>,
    items: [
      {
        component: CNavItem,
        name: "Machines List",
        to: "/home/machines/create",
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Users",
    to: "/user",
    icon: <CIcon icon={cilUser} customClassName="nav-icon"/>,
    items: [
      {
        component: CNavItem,
        name: "Manage Users",
        to: "/home/users",
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Settings",
    to: "/settings",
    icon: <CIcon icon={cilSettings} customClassName="nav-icon"/>,
    items: [
      {
        component: CNavItem,
        name: <div>Manage Default <br/> Metadata</div>,
        to: "/home/settings/metadata",
      },
      {
        component: CNavItem,
        name: "Manage Roles",
        to: "/home/settings/roles",
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Enam",
    to: "/enam",
    icon: <CIcon icon={cilUser} customClassName="nav-icon"/>,
    items: [
      {
        component: CNavItem,
        name: "Enam Details",
        to: "/home/enam",
      },
    ],
  },
  {
    component: CNavGroup,
    name: "User Feedback",
    to: "/user/feedback",
    icon: <CIcon icon={cilUser} customClassName="nav-icon"/>,
    items: [
      {
        component: CNavItem,
        name: "Feedback Details",
        to: "/home/user/feedback",
      },
    ],
  },
];

export default _nav;
