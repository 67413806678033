import React, { useState } from 'react';
import { CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem } from '@coreui/react';

function DropdownWithSelectedOptions({ options, onSelect, onRemove, title, selectedOptions }) {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const handleSelectOption = (option) => {
        onSelect(option);
        toggleDropdown();
    };

    const handleRemoveOption = (optionToRemove) => {
        onRemove(optionToRemove);
    };

    // Filter the options to exclude selectedOptions
    const filteredOptions = options.filter(option => !selectedOptions?.includes(option));

    return (
        <div>
            {selectedOptions?.length > 0 && (
                <div className="selected-options-container">
                    {selectedOptions?.map((option, index) => (
                        <div key={index} className="selected-option-box">
                            {option}
                            <span className="remove-option" onClick={() => handleRemoveOption(option)}>
                                &times;
                            </span>
                        </div>
                    ))}
                </div>
            )}
            <CDropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                <CDropdownToggle color='danger'>
                    {title}
                </CDropdownToggle>
                <CDropdownMenu>
                    {filteredOptions?.length > 0 &&
                        filteredOptions.map((option, index) => (
                            <CDropdownItem key={index} onClick={() => handleSelectOption(option)}>
                                {option}
                            </CDropdownItem>
                        ))}
                </CDropdownMenu>
            </CDropdown>
        </div>
    );
}

export default DropdownWithSelectedOptions;
