import React from 'react'
import { AppContent, AppSidebar, AppHeader } from '../../components/index';
import { useSelector } from 'react-redux';

const DashboardLayout = () => {
    const unfoldable = useSelector((state) => state.sidebarUnfoldable)
    const sidebarShow = useSelector((state) => state.sidebarShow)
    return (
        <div className={unfoldable ? sidebarShow ? 'm-l-64px' : '' : sidebarShow ? 'm-l-256px' : ''}>
            <AppSidebar />
            <div >
                <AppHeader />
                <AppContent />
            </div>
        </div>
        // <div>
        //     <AppSidebar />
        //     <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        //         <AppHeader />
        //         {/* TODO:Jugaad to make the sidebar fixed, please change later */}
        //         <div className="body flex-grow-1 px-3" style={{ marginLeft: '15vw' }}>
        //             <AppContent />
        //         </div>
        //     </div>
        // </div>
    )
}

export default DashboardLayout