import {
    CCol,
    CContainer,
    CRow,
    CNavbar,
    CNavbarBrand,
    CButton,
  } from "@coreui/react";
  import React, { useState, useEffect } from "react";
  import AllFeedback from "./Components/AllFeedback";
  
  const feedbackLayout = () => {
    return (
      <CContainer>
        <CRow>
          <CCol>
            <AllFeedback />
          </CCol>
        </CRow>
      </CContainer>
    );
  };
  
  export default feedbackLayout;