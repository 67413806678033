import React, { useState, useEffect } from "react";
import { auth } from "../../../../firebase";
import axios from "axios";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CSpinner,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CCol,
  CRow,
  CCardTitle,
  CImage,
  CFormSwitch,
  CFormLabel,
  CFormInput,
  CFormCheck,
} from "@coreui/react";
import { cilReload } from "@coreui/icons";
import CIcon from "@coreui/icons-react";

const PaymentAccessHistory = ({isRefreshAccessPayment, setIsRefreshAccessPayment}) => {
  const [paymentHistory, setPaymentHistory] = useState(undefined);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getPaymentHistory();
  }, []);

  useEffect(() => {
    if (isRefreshAccessPayment) {
      getPaymentHistory();
      setIsRefreshAccessPayment(false)
    }
  }, [isRefreshAccessPayment]);

  async function getPaymentHistory() {
    setLoading(true);
    let company_id = window.location.href.split("/").pop();
    try {
      await new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          } else {
            reject(new Error("User is not authenticated."));
          }
        });
      });
      console.log({ company_id: company_id });
      let authToken = await auth.currentUser.getIdToken();
      let result = await axios.post(
        window.config.commonEndpoint + "company/payment/accessHistory",
        { company_id: company_id },
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      setPaymentHistory(result.data.data.accessHistory);
      console.log(result.data.data.accessHistory);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  return (
    <div style={{ maxHeight: "430px", overflowY: "auto" }}>
      <CCard>
        <CCardHeader>
          <CCardTitle>
            <CRow>
              <CCol>
                <h3>
                  <strong>Payments Access History</strong>
                </h3>
              </CCol>
              <CCol>
                <CButton color="light" onClick={() => getPaymentHistory()}>
                  <CIcon size="lg" icon={cilReload} />
                </CButton>
              </CCol>
            </CRow>
          </CCardTitle>
        </CCardHeader>
        <CCardBody>
          {loading ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
              <CSpinner color="dark" />
            </div>
          ) : (
            <div style={{ maxHeight: "200px", overflowY: "auto" }}>
              <CTable align="middle">
                <CTableHead>
                  <CTableRow>
                    <CTableHeaderCell>S.No</CTableHeaderCell>
                    <CTableHeaderCell>Payment Start Date</CTableHeaderCell>
                    <CTableHeaderCell>Payment End Date</CTableHeaderCell>
                    <CTableHeaderCell>Scans</CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                {loading ? (
                  <CSpinner color="dark" />
                ) : (
                  <CTableBody>
                    {paymentHistory === undefined ||
                      paymentHistory.length === 0 ? (
                      <CTableHeaderCell>No data to display</CTableHeaderCell>
                    ) : (
                      paymentHistory.map((payment, index) => {
                        return (
                          <CTableRow key={index}>
                            <CTableHeaderCell>{index + 1}</CTableHeaderCell>
                            <CTableDataCell>
                              {payment.start_date.substring(0, 10)}
                            </CTableDataCell>
                            <CTableDataCell>
                              {payment.end_date.substring(0, 10)}
                            </CTableDataCell>
                            <CTableDataCell>
                              {payment.unlimited_scans
                                ? "Unlimited"
                                : payment.number_of_scans}
                            </CTableDataCell>
                            {/* <CTableDataCell>
                            <CButton color="danger" onClick={() => {console.log("delete clicked")}}>
                              Delete
                            </CButton>
                          </CTableDataCell> */}
                          </CTableRow>
                        );
                      })
                    )}
                  </CTableBody>
                )}
              </CTable>
            </div>
          )}
        </CCardBody>
      </CCard>
    </div>
  );
};

export default PaymentAccessHistory;
