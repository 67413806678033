import {
  CCol,
  CFormInput,
  CFormLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CButton,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
} from "@coreui/react";
import React, { useState } from "react";
import AppMessageModal from "../../../../components/AppMessageModal";
import AppConfirmModal from "../../../../components/AppConfirmModal";
import axios from "axios";
import { auth } from "../../../../firebase";

const AddCompany = ({ isOpen, handleCancel, handleConfirm }) => {
  //States
  const [companyName, setCompanyName] = useState("");
  const [noOfUsers, setNoOfUsers] = useState(0);
  const [companyType, setCompanyType] = useState("");
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");
  const [messageModal, setMessageModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [pdfType, setPdfType] = useState("LONG");
  //Functions

  const handleCompanyName = (e) => {
    setCompanyName(e.target.value);
  };

  const handleNoOfUsers = (e) => {
    setNoOfUsers(e.target.value);
  };

  function handleCompanyConfirm() {
    if (companyName === "") {
      setTitle("Error");
      setMessage("Company Name cannot be empty");
      setMessageModal(true);
      return;
    }
    if (noOfUsers === 0) {
      setTitle("Error");
      setMessage("No of Users cannot be 0");
      setMessageModal(true);
      return;
    }
    setTitle("Confirm");
    setMessage("Are you sure you want to add this company?");
    setConfirmModal(true);
  }

  async function createCompany() {
    try {
      await new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          } else {
            reject(new Error("User is not authenticated."));
          }
        });
      });

      let authToken = await auth.currentUser.getIdToken();
      let result = await axios.post(
        window.config.commonEndpoint + "company/create",
        {
          name: companyName,
          number_of_users: noOfUsers,
          company_type: companyType,
          pdf_type: pdfType
        },
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      if (result.status == 200) {
        setTitle("Success");
        setMessage("Company created successfully");
        setMessageModal(true);
        handleConfirm();
      }
    } catch (error) {
      setTitle("Error");
      setMessage("Error while creating company");
      setMessageModal(true);
    }
  }
  const handleOptionChange = (selectedPdfType) => {
    setPdfType(selectedPdfType);
  };

  return (
    <>
      <CModal
        alignment="center"
        backdrop="static"
        size="lg"
        visible={isOpen}
        onClose={() => handleCancel()}
      >
        <CModalHeader onClose={() => handleCancel()}>
          <CModalTitle>
            <h3>
              <strong>Add a new company</strong>
            </h3>
          </CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CRow>
            <CCol>
              <CFormLabel>Company Name</CFormLabel>
              <CFormInput
                type="text"
                value={companyName}
                onChange={handleCompanyName}
                placeholder="Upjao Agrotech LLC"
              />
            </CCol>
            <CCol>
              <CFormLabel>No of Users</CFormLabel>
              <CFormInput
                type="number"
                value={noOfUsers}
                onChange={handleNoOfUsers}
                placeholder="10"
              />
            </CCol>
          </CRow>
          <br />
          <CRow>
            <CCol>
              <CFormLabel>Company Type</CFormLabel>
              <br />
              <CDropdown>
                <CDropdownToggle color="secondary">
                  {companyType ? `${companyType}` : "Select Company Type"}
                </CDropdownToggle>
                <CDropdownMenu>
                  <CDropdownItem onClick={() => setCompanyType("UPJAO")}>
                    UPJAO
                  </CDropdownItem>
                  <CDropdownItem onClick={() => setCompanyType("PARAKH_APMC")}>
                    PARAKH_APMC
                  </CDropdownItem>
                  <CDropdownItem onClick={() => setCompanyType("PARAKH_PACS")}>
                    PARAKH_PACS
                  </CDropdownItem>
                </CDropdownMenu>
              </CDropdown>
            </CCol>
            <CCol>
              <CFormLabel>
                <strong>PDF Type</strong>
              </CFormLabel>
              <br />
              <CDropdown>
                <CDropdownToggle color="secondary">
                  {pdfType}
                </CDropdownToggle>
                <CDropdownMenu>
                  <CDropdownItem onClick={() => handleOptionChange('LONG')}>LONG</CDropdownItem>
                  <CDropdownItem onClick={() => handleOptionChange('SHORT')}>SHORT</CDropdownItem>
                </CDropdownMenu>
              </CDropdown>
            </CCol>
          </CRow>
        </CModalBody>
        <CModalFooter>
          <CButton color="success" onClick={() => handleCompanyConfirm()}>
            Confirm
          </CButton>{" "}
          <CButton color="danger" onClick={() => handleCancel()}>
            Cancel
          </CButton>
        </CModalFooter>
      </CModal>
      {/* Message Modal */}
      <AppMessageModal
        isOpen={messageModal}
        title={title}
        message={message}
        handleClose={() => setMessageModal(false)}
      />
      {/* Confirm Modal */}
      <AppConfirmModal
        isOpen={confirmModal}
        title={title}
        message={message}
        onCancel={() => setConfirmModal(false)}
        onConfirm={() => createCompany()}
      />
    </>
  );
};

export default AddCompany;
