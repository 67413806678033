import { CModal, CModalHeader, CModalTitle, CModalBody, CButton, CModalFooter } from "@coreui/react";

const ShowDetailDataModel = ({ title, message, handleClose, isOpen }) => {
    return (
        <CModal alignment="center" visible={isOpen} onClose={() => handleClose()}>
            <CModalHeader onClose={() => handleClose()}>
                <CModalTitle><h3><strong>{title}</strong></h3></CModalTitle>
            </CModalHeader>
            <CModalBody>
                {message && message?.length && message?.map((item, index) => (
                    <div key={index} style={{ wordWrap: 'break-word' }}>
                        <strong>{Object.keys(item)[0]}</strong>: {Object.values(item)[0]}
                    </div>
                ))}
            </CModalBody>
            <CModalFooter>
                <CButton color="warning" onClick={() => handleClose()}>Close</CButton>
            </CModalFooter>
        </CModal>
    );
}

export default ShowDetailDataModel