import React, { useEffect, useState } from "react";
import { auth } from "../../../../firebase";
import axios from "axios";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCardTitle,
  CCol,
  CRow,
  CSpinner,
  CTable,
  CTableBody,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CFormInput,
  CForm,
  CContainer,
  CFormLabel,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
} from "@coreui/react";
import { cilReload } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import AppConfirmModal from "../../../../components/AppConfirmModal";
import AppMessageModal from "../../../../components/AppMessageModal";
import "../Components/GetUserRole";

const GetUserRole = () => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(undefined);
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");
  const [confirmModal, setConfirmModal] = useState(false);
  const [messageModal, setMessageModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [filteredUserList, setFilteredUserList] = useState([]);
  const [userList, setUserList] = useState(undefined);

  useEffect(() => {
    getUserList();
  }, []);

  useEffect(() => {
    handleSearch();
  }, [searchTerm])

  useEffect(() => {
    getUserRole();
  }, [selectedUser]);

  async function getUserList() {
    setUserList(undefined);
    setLoading(true);
    try {
      await new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          } else {
            reject(new Error("User is not authenticated."));
          }
        });
      });

      let authToken = await auth.currentUser.getIdToken();
      let result = await axios.get(
        window.config.commonEndpoint + "input/all_users",
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      setFilteredUserList(result.data.data.users);
      setUserList(result.data.data.users);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  async function getUserRole() {
    setLoading(true);
    try {
      if (!selectedUser) return;
      await new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          } else {
            reject(new Error("User is not authenticated."));
          }
        });
      });

      let authToken = await auth.currentUser.getIdToken();
      let result = await axios.post(
        window.config.commonEndpoint + "input/get_user_roles",
        { firebase_user_id: selectedUser.firebase_user_id },
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      setUser(result.data.data);
      console.log(result.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  const handleSearch = () => {
    if (userList) {
      const filteredUserList = userList.filter((user) => {
        const name = `${user.first_name || ""} ${user.last_name || ""}`;
        return (
          name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          (user.email &&
            user.email.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (user.phone_number && user.phone_number.includes(searchTerm)) ||
          user.user_type.toLowerCase().includes(searchTerm.toLowerCase())
        );
      });
      setFilteredUserList(filteredUserList);
    }
  };

  const handleUserSelection = (user) => {
    setSelectedUser(user);
    setModalVisible(false);
  };

  return (
    <>
      <CCard>
        <CCardHeader>
          <CCardTitle>Select User</CCardTitle>
          <CButton color="primary" className="APP-BACKGROUND" onClick={() => setModalVisible(true)}>
            Open User List
          </CButton>
          {selectedUser ? (
            <div>
              <strong>Selected User:</strong> {selectedUser.first_name}{" "}
              {selectedUser.last_name} --- {selectedUser.phone_number}{" "}
              {selectedUser.email}
            </div>
          ) : (
            <div>No user selected</div>
          )}
        </CCardHeader>
        <CCardBody>
          <CTable align="middle">
            <CTableHead>
              <CTableRow>
                <CTableHeaderCell>Role Code</CTableHeaderCell>
                <CTableHeaderCell>App Type</CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            {loading ? (
              <CSpinner color="dark" />
            ) : (
              <CTableBody>
                {user === undefined || user.length === 0 ? (
                  <CTableHeaderCell>No data to display</CTableHeaderCell>
                ) : (
                  user.map((u, index) => {
                    return (
                      <CTableRow key={index}>
                        <CTableHeaderCell>{u.role_code}</CTableHeaderCell>
                        <CTableHeaderCell>{u.app_type}</CTableHeaderCell>
                      </CTableRow>
                    );
                  })
                )}
              </CTableBody>
            )}
          </CTable>
        </CCardBody>
      </CCard>
      <br />

      {/* User List Modal */}
      {modalVisible && (
        <div className="user-list-modal">
          <div className="user-list-modal-header">
            <h6>Select User</h6>
            <CFormInput
              className="search-input"
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={(event) => setSearchTerm(event.target.value)}
            />
            <CButton color="secondary" onClick={() => setModalVisible(false)}>
              Close
            </CButton>
          </div>
          <div className="user-list-modal-content">
            {filteredUserList.map((user, index) => (
              <div
                key={index}
                className="user-item"
                onClick={() => handleUserSelection(user)}
              >
                <div>{`${user.first_name} ${user.last_name} `}</div>
                <div>{user.phone_number}</div>
                <div>{user.email}</div>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Confirm Modal */}
      <AppConfirmModal
        isOpen={confirmModal}
        onCancel={() => setConfirmModal(false)}
        // onConfirm={title === "Delete User" ? deleteUser : null}
        title={title}
        message={message}
      />

      {/* Message Modal */}
      <AppMessageModal
        isOpen={messageModal}
        handleClose={() => setMessageModal(false)}
        title={title}
        message={message}
      />
    </>
  );
};

export default GetUserRole;
