import {
    CButton, CCard, CCardBody, CCardHeader, CCol, CRow, CCardTitle,
    CFormLabel, CFormInput, CSpinner, CTable, CTableBody, CTableDataCell, CTableHead,
    CTableHeaderCell, CTableRow, CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem, CContainer, CFormCheck,
} from "@coreui/react";
import React from 'react'
import { getCropClasses, getCompanyCropClassConfig, CopyMasterClassConfig, getCompanyClassConfigurationsApi, addCompanyClassConfigurations, getCompanyMachines, getCompanyCrops } from "../../../../utils/api";
import { useEffect } from "react";
import { useState } from "react";
import AppMessageModal from "../../../../components/AppMessageModal";
import { MdDeleteForever } from 'react-icons/md';
import DropdownWithSelectedOptions from "../../Crops/components/dropDown"

function CompanyDefaultClassConfigurations() {
    const [allMachines, setAllMachines] = useState([]);
    const [allCrops, setAllCrops] = useState([]);
    const [selectedMachine, setSelectedMachine] = useState(null);
    const [selectedCrop, setSelectedCrop] = useState(null);
    const classType = ['DEFAULT_UPJAO']
    const [selectClassType, setSelectClassType] = useState(null)
    const [configClassType, setConfigClassType] = useState(null)
    const [masterSelectedMachine, setMasterSelectedMachine] = useState(null);
    const [masterSelectedCrop, setMasterSelectedCrop] = useState(null);
    const [qualityScoreDependencies, setQualityScoreDependencies] = useState([]);
    const [loading, setLoading] = useState(false);
    const [cropClasses, setCropClasses] = useState(undefined);
    const [pipelineID, setPipelineID] = useState('')
    const [getResponceDefaultClassConfig, setResponceDefaultClassConfig] = useState(false)
    const [getInitialResponceDefaultClassConfig, setInitialResponceDefaultClassConfig] = useState(false)
    const [addConfig, setAddconfig] = useState(false)
    const [selectedClassOrderIds, setSelectedClassOrderIds] = useState([]);
    const [selectedDisplayOrderIds, setSelectedDisplayOrderIds] = useState([]);
    const [considerArea, setConsiderArea] = useState(false);
    const [considerLength, setConsiderLength] = useState(false);
    const [considerLengthDistribution, setConsiderLengthDistribution] = useState(false);
    const [considerWidth, setConsiderWidth] = useState(false);
    const [showSegmentedImage, setShowSegmentedImage] = useState(false);
    const [mapping, setMapping] = useState({});
    const [messageModal, setMessageModal] = useState(false);
    const [messagetitle, setMessageTitle] = useState('');
    const [message, setMessage] = useState('');
    const [defaultClassConfigurationList, setDefaultClassConfigurationList] = useState(null)
    const [classOrder, setClassOrder] = useState([])
    const [displayOrder, setDisplayOrder] = useState([])
    const [allClasses, setAllClasses] = useState([])
    const [classMapping, setClassMapping] = useState({})
    const [distributionAvgValuesResponce, setDistributionAvgValuesResponce] = useState([])
    const [distributionKeyResponce, setDistributionKeyResponce] = useState([])
    const distributionAvgValues = ["kernel_avg_area", "kernel_avg_height", "kernel_avg_length", "kernel_avg_width", "whiteness", "kernel_avg_length_no_foreign", "length / width ratio"]
    const distributionKeys = ["total_area", "length", "width", "height"]
    const allValues = {
        configClassType,
        selectedCrop,
        selectedMachine,
        pipelineID,
        distributionKeyResponce,
        distributionAvgValuesResponce,
        considerArea,
        considerLength,
        considerLengthDistribution,
        considerWidth,
        showSegmentedImage,
        selectedClassOrderIds,
        selectedDisplayOrderIds,
        qualityScoreDependencies,
        mapping
    };

    const beautify = {
        translations: {
            display_order: {},
            class_code: {},
            class_color: {},
        },
    };
    selectedDisplayOrderIds && selectedDisplayOrderIds.forEach((classId) => {
        const selectedClass = cropClasses.find((item) => item.class_id === classId);
        if (selectedClass) {
            beautify.translations.class_code[selectedClass.class_id] = selectedClass.class_code;
            beautify.translations.class_color[selectedClass.class_id] = selectedClass.color;
            beautify.translations.display_order[selectedClass.class_id] = selectedClass.class_display_name;
        }
    });
    useEffect(() => {
        getMachines();
        getCrops()
        getDefaultClass()
        getDefaultCropConfigurations()
    }, []);

    const handleSelectOptionForDistributionAvgValues = (option) => {
        setDistributionAvgValuesResponce([...distributionAvgValuesResponce, option]);
    };

    const handleRemoveOptionForDistributionAvgValues = (optionToRemove) => {
        const updatedOptions = distributionAvgValuesResponce.filter((option) => option !== optionToRemove);
        setDistributionAvgValuesResponce(updatedOptions);
    };

    const handleSelectOptionForDistributionKeys = (option) => {
        setDistributionKeyResponce([...distributionKeyResponce, option]);
    };

    const handleRemoveOptionForDistributionKeys = (optionToRemove) => {
        const updatedOptions = distributionKeyResponce.filter((option) => option !== optionToRemove);
        setDistributionKeyResponce(updatedOptions);
    };

    const getDefaultClass = async () => {
        try {
            const result = await getCropClasses()
            setCropClasses(result.data.data.classes);
        } catch (error) {

        }
    }

    async function getMachines() {
        try {
            let company_id = window.location.href.split("/").pop();
            const result = await getCompanyMachines(company_id)
            setAllMachines(result?.data?.data?.machines_data);
        } catch (error) {
            console.log(error);
        }
    }
    async function getCrops() {
        try {
            let company_id = window.location.href.split("/").pop();
            const result = await getCompanyCrops(company_id)
            setAllCrops(result?.data?.data?.crops);
        } catch (error) {
            console.log(error);
        }
    }
    const getDefaultCropConfigurations = async () => {
        try {
            setLoading(true)
            const company_id = window.location.href.split("/").pop();
            const response = await getCompanyCropClassConfig(company_id);
            if (response?.data?.data) {
                setAllClasses(response?.data?.data?.cropClasses)
            }
            setLoading(false)
        } catch (error) {
            console.log(error);
        }
    };
    const setAllValues = (values) => {
        setConfigClassType(values.selectClassType || null);
        setSelectedCrop(values.selectedCrop || null);
        setSelectedMachine(values.selectedMachine || null);
        setPipelineID(values.pipelineID || '');
        setDistributionKeyResponce(values.distributionKeyResponce || []);
        setDistributionAvgValuesResponce(values.distributionAvgValuesResponce || []);
        setConsiderArea(values.considerArea || false);
        setConsiderLength(values.considerLength || false);
        setConsiderLengthDistribution(values.considerLengthDistribution || false);
        setConsiderWidth(values.considerWidth || false);
        setShowSegmentedImage(values.showSegmentedImage || false);
        setSelectedClassOrderIds(values.selectedClassOrderIds || []);
        setSelectedDisplayOrderIds(values.selectedDisplayOrderIds || []);
        setQualityScoreDependencies(values.qualityScoreDependencies || []);
        setMapping(values.mapping || {});
    };

    const validateClasses = (values) => {
        const invalidClasses = [];
        if (values.selectedClassOrderIds) {
            values.selectedClassOrderIds.forEach((classId) => {
                const selectedClass = cropClasses.find((item) => item.class_id === classId);
                if (!selectedClass) {
                    invalidClasses.push(classId);
                }
            });
        }

        if (values.selectedDisplayOrderIds) {
            values.selectedDisplayOrderIds.forEach((classId) => {
                const selectedClass = cropClasses.find((item) => item.class_id === classId);
                if (!selectedClass) {
                    invalidClasses.push(classId);
                }
            });
        }

        return invalidClasses;
    };
    const copyValuesToClipboard = () => {
        navigator.clipboard.writeText(JSON.stringify(allValues)).then(() => {
            console.log('Values copied to clipboard');
        }).catch((error) => {
            console.error('Error copying values to clipboard:', error);
        });
    };

    const pasteValuesFromClipboard = () => {
        navigator.clipboard.readText().then((text) => {
            try {
                const values = JSON.parse(text);
                console.log(values,"valuesvaluesvalues");
                
                if (values.selectClassType === 'DEFAULT_ENAM') {
                    alert('You cannot set enam type at Company Level.');
                    return;
                }
                const invalidClasses = validateClasses(values);
                if (invalidClasses.length > 0) {
                    alert(`Please add the following classes first: ${invalidClasses.join(', ')}`);
                } else {
                    setAllValues(values);
                }
            } catch (error) {
                console.error('Failed to parse clipboard content:', error);
            }
        }).catch((error) => {
            console.error('Failed to read clipboard content:', error);
        });
    };

    const handleAddConfig = async () => {
        if (selectedMachine === '' || selectedCrop === '' || pipelineID === '' || configClassType === '') {
            setMessage('Please select Required Fields');
            setMessageTitle('Error');
            setMessageModal(true);
            return;
        }
        try {
            const requestBody = {
                company_id: window.location.href.split("/").pop(),
                class_order: selectedClassOrderIds,
                display_order: selectedDisplayOrderIds,
                class_mappings: mapping,
                beautify: beautify,
                consider_area: considerArea,
                consider_length: considerLength,
                consider_length_distribution: considerLengthDistribution,
                consider_width: considerWidth,
                distribution_avg_values: distributionAvgValuesResponce,
                distribution_key: distributionKeyResponce,
                quality_score_dependencies: qualityScoreDependencies,
                show_segmented_image: showSegmentedImage,
                crop_id: selectedCrop.id,
                machine_code: selectedMachine.machine_code,
                pipeline_id: pipelineID,
                type: configClassType,
            };
            const response = await addCompanyClassConfigurations(requestBody);
            if (response?.status === 200) {
                setMessageTitle('Success');
                setMessage(response.data.message);
                setMessageModal(true);
                getDefaultCropConfigurations()
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };


    const handleClassOrderHeaderClick = () => {
        setSelectedClassOrderIds([]);
    };

    const onSelectQualityScoreDependencies = (type) => {
        setQualityScoreDependencies((prev) => {
            if (prev.includes(type)) {
                return prev.filter((item) => item !== type);
            } else {
                return [...prev, type];
            }
        });
    };

    const handleDisplayOrderHeaderClick = () => {
        setSelectedDisplayOrderIds([]);
    };
    const handleDisplayOrderSelect = (classId, displayId) => {
        if (classId && displayId) {
            const newMapping = { ...mapping };
            newMapping[classId] = displayId;
            setMapping(newMapping);
        }
    };
    const getDisplayOrderValue = (classId) => {
        if (mapping.hasOwnProperty(classId)) {
            return mapping[classId];
        }
        return "Select Display Order";
    };

    const handleCopyMasterClass = async () => {
        const payload = {
            crop_id: masterSelectedCrop.id,
            machine_code: masterSelectedMachine.machine_code,
            type: selectClassType,
            company_id: window.location.href.split("/").pop()
        };
        const response = await CopyMasterClassConfig(payload);
        if (response?.status === 200) {
            setMessageTitle('success');
            setMessage(response?.data?.message);
            setMessageModal(true);
            getDefaultCropConfigurations()
        }
        if (response?.status === 400) {
            setMessageTitle('Error');
            setMessage(response?.data?.message);
            setMessageModal(true);
        }
    };
    const handleViewButtonClick = async (crop_id, machine_code) => {
        setLoading(true)
        const payload = {
            crop_id: crop_id,
            machine_code: machine_code,
            company_id: window.location.href.split("/").pop()
        };
        const response = await getCompanyClassConfigurationsApi(payload);
        console.log(response?.data?.data);
        if (response?.data?.data) {
            const selectedCrop = allCrops && allCrops.find(crop => crop.id === crop_id);
            const selectedMachine = allMachines && allMachines.find(machine => machine.machine_code === machine_code);
            setSelectedCrop(selectedCrop);
            setSelectedMachine(selectedMachine);
            setConfigClassType(response?.data?.data?.type)
            setPipelineID(response?.data?.data?.pipeline_id)
            setDistributionKeyResponce(response?.data?.data?.distribution_key)
            setDistributionAvgValuesResponce(response?.data?.data?.distribution_avg_values)
            setConsiderArea(response?.data?.data?.consider_area)
            setConsiderLength(response?.data?.data?.consider_length)
            setConsiderLengthDistribution(response?.data?.data?.consider_length_distribution)
            setConsiderWidth(response?.data?.data?.consider_width)
            setShowSegmentedImage(response?.data?.data?.show_segmented_image)
            setSelectedClassOrderIds(response?.data?.data?.class_order)
            setSelectedDisplayOrderIds(response?.data?.data?.display_order)
            setQualityScoreDependencies(response?.data?.data?.quality_score_dependencies ? response?.data?.data?.quality_score_dependencies : '')
            const newMapping = {};
            response?.data?.data?.class_order.forEach((classId, index) => {
                const displayId = response?.data?.data?.display_order[index];
                newMapping[classId] = displayId;
            });
            setMapping(response?.data?.data?.class_mappings);
            setInitialResponceDefaultClassConfig(true);
            setLoading(false)
            setAddconfig(false)
        }
    };
    const handleDeleteButtonClick = (index) => {
        // Logic for handling the "Delete" button click for the item at the specified index
    };
    const handleAddButtonClick = () => {
        setConfigClassType('')
        setSelectedCrop('')
        setSelectedMachine('')
        setPipelineID('')
        setDistributionKeyResponce([])
        setDistributionAvgValuesResponce([])
        setConsiderArea(false)
        setConsiderLength(false)
        setConsiderLengthDistribution(false)
        setConsiderWidth(false)
        setShowSegmentedImage(false)
        setSelectedClassOrderIds([])
        setSelectedDisplayOrderIds([])
        setMapping({});
        setInitialResponceDefaultClassConfig(true);
        setAddconfig(true)
        setQualityScoreDependencies([])
    }
    const selectedClassOrderText = selectedClassOrderIds.join(", ");
    const selectedDisplayOrderText = selectedDisplayOrderIds.join(", ");
    return (
        <>
            <div>
                <CCard className="mt-4 mb-5">
                    <CCardHeader>
                        <CRow className="justify-items-between ">
                            <CCol>
                                <CCardTitle>
                                    <h3>
                                        <strong>Copy Master Default Class Configurations</strong>
                                    </h3>
                                </CCardTitle>
                            </CCol>
                        </CRow>
                    </CCardHeader>
                    <CCardBody>
                        <CRow>
                            <CCol xs="2">
                                <CDropdown>
                                    <CDropdownToggle color="secondary">
                                        {selectClassType ? `${selectClassType}` : "Select Class Type"}
                                    </CDropdownToggle>
                                    <span style={{ fontSize: "20px" }} className="text-danger">*</span>
                                    <CDropdownMenu>
                                        {classType &&
                                            classType.map((type, index) => (
                                                <CDropdownItem
                                                    key={index}
                                                    onClick={() => setSelectClassType(type)}
                                                >
                                                    {type}
                                                </CDropdownItem>
                                            ))}
                                    </CDropdownMenu>
                                </CDropdown>
                            </CCol>
                            <CCol xs="2">
                                <CDropdown>
                                    <CDropdownToggle color="secondary">
                                        {masterSelectedCrop
                                            ? `${masterSelectedCrop.crop} - ${masterSelectedCrop.variety}`
                                            : "Select Crop"}
                                    </CDropdownToggle>
                                    <span style={{ fontSize: "20px" }} className="text-danger">*</span>
                                    <CDropdownMenu>
                                        {allCrops &&
                                            allCrops.map((crop, index) => (
                                                <CDropdownItem
                                                    key={index}
                                                    onClick={() => setMasterSelectedCrop(crop)}
                                                >
                                                    {crop.crop} - {crop.variety}
                                                </CDropdownItem>
                                            ))}
                                    </CDropdownMenu>
                                </CDropdown>
                            </CCol>
                            <CCol xs="2">
                                <CDropdown>
                                    <CDropdownToggle color="secondary">
                                        {masterSelectedMachine
                                            ? `${masterSelectedMachine.machine_name} - ${masterSelectedMachine.machine_code}`
                                            : "Select Machine"}
                                    </CDropdownToggle>
                                    <span style={{ fontSize: "20px" }} className="text-danger">*</span>
                                    <CDropdownMenu>
                                        {allMachines &&
                                            allMachines.map((machine, index) => (
                                                <CDropdownItem
                                                    key={index}
                                                    onClick={() => setMasterSelectedMachine(machine)}
                                                >
                                                    {machine.machine_name} - {machine.machine_code}
                                                </CDropdownItem>
                                            ))}
                                    </CDropdownMenu>
                                </CDropdown>
                            </CCol>
                            <CCol xs="2" className="align-items-center justify-content-end button-cell">
                                <CButton color="success" onClick={() => handleCopyMasterClass()}>COPY</CButton>
                            </CCol>
                        </CRow>
                    </CCardBody>
                </CCard>
            </div>
            <div>
                <AppMessageModal message={message} title={messagetitle} handleClose={() => setMessageModal(false)} isOpen={messageModal} />
                <CCard className="mt-4 mb-5">
                    <CCardHeader>
                        <CRow className="justify-items-between ">
                            <CCol>
                                <CCardTitle>
                                    <h3>
                                        <strong>Default Class Configurations</strong>
                                    </h3>
                                </CCardTitle>
                            </CCol>
                        </CRow>
                    </CCardHeader>
                    <CCardBody>
                        <CTable style={{ marginTop: '30px', marginBottom: '50px' }} align="middle">
                            <CTableHead style={{ background: "aliceblue" }}>
                                <CTableRow>
                                    <CTableHeaderCell>S.No</CTableHeaderCell>
                                    <CTableHeaderCell>ResponeID</CTableHeaderCell>
                                    <CTableHeaderCell>Flow</CTableHeaderCell>
                                    <CTableHeaderCell>Crop</CTableHeaderCell>
                                    <CTableHeaderCell>Machine</CTableHeaderCell>
                                    <CTableHeaderCell>Action</CTableHeaderCell>
                                </CTableRow>
                            </CTableHead>
                            {allClasses !== undefined ? (
                                <CTableBody>
                                    {allClasses && allClasses.map((item, index) => {
                                        return (
                                            <CTableRow key={index}>
                                                <CTableHeaderCell>{index + 1}</CTableHeaderCell>
                                                <CTableDataCell>{item.reference_id}</CTableDataCell>
                                                <CTableDataCell>{item.type}</CTableDataCell>
                                                <CTableDataCell>{item.crop}-{item.variety}</CTableDataCell>
                                                <CTableDataCell>{item.machine_code}</CTableDataCell>
                                                <CTableDataCell className="button-cell">
                                                    <CButton onClick={() => handleViewButtonClick(item.crop_id, item.machine_code)}>VIEW</CButton>
                                                    <CButton
                                                        color="danger"
                                                        style={{ marginLeft: '15px' }}
                                                        onClick={() => {
                                                            handleDeleteButtonClick(index)
                                                        }}
                                                    >
                                                        <MdDeleteForever size={22} />
                                                    </CButton>
                                                </CTableDataCell>
                                            </CTableRow>
                                        );
                                    })}
                                </CTableBody>
                            ) : (
                                <CTableHeaderCell>No Data to display</CTableHeaderCell>
                            )}
                        </CTable>
                        <CRow>
                            <CCol xs="2">
                                <CDropdown>
                                    <CDropdownToggle color="secondary">
                                        {configClassType ? `${configClassType}` : "Select Class Type"}
                                    </CDropdownToggle>
                                    <span style={{ fontSize: "20px" }} className="text-danger">*</span>
                                    <CDropdownMenu>
                                        {classType &&
                                            classType.map((type, index) => (
                                                <CDropdownItem
                                                    key={index}
                                                    onClick={() => setConfigClassType(type)}
                                                >
                                                    {type}
                                                </CDropdownItem>
                                            ))}
                                    </CDropdownMenu>
                                </CDropdown>
                            </CCol>
                            <CCol xs="2">
                                <CDropdown>
                                    <CDropdownToggle color="secondary">
                                        {selectedCrop
                                            ? `${selectedCrop.crop} - ${selectedCrop.variety}`
                                            : "Select Crop"}
                                    </CDropdownToggle>
                                    <span style={{ fontSize: "20px" }} className="text-danger">*</span>
                                    <CDropdownMenu>
                                        {allCrops &&
                                            allCrops.map((crop, index) => (
                                                <CDropdownItem
                                                    key={index}
                                                    onClick={() => setSelectedCrop(crop)}
                                                >
                                                    {crop.crop} - {crop.variety}
                                                </CDropdownItem>
                                            ))}
                                    </CDropdownMenu>
                                </CDropdown>
                            </CCol>
                            <CCol xs="2">
                                <CDropdown>
                                    <CDropdownToggle color="secondary">
                                        {selectedMachine
                                            ? `${selectedMachine.machine_name} - ${selectedMachine.machine_code}`
                                            : "Select Machine"}
                                    </CDropdownToggle>
                                    <span style={{ fontSize: "20px" }} className="text-danger">*</span>
                                    <CDropdownMenu>
                                        {allMachines &&
                                            allMachines.map((machine, index) => (
                                                <CDropdownItem
                                                    key={index}
                                                    onClick={() => setSelectedMachine(machine)}
                                                >
                                                    {machine.machine_name} - {machine.machine_code}
                                                </CDropdownItem>
                                            ))}
                                    </CDropdownMenu>
                                </CDropdown>
                            </CCol>
                            <CCol xs="6" className="align-items-center justify-content-end button-cell">
                                <CButton color="success" onClick={() => handleAddButtonClick()}>+ ADD</CButton>
                                <CCol xs="2">
                                    <CDropdown>
                                        <CDropdownToggle color="primary">
                                            COPY
                                        </CDropdownToggle>
                                        <CDropdownMenu>
                                            {allClasses &&
                                                allClasses.map((item, index) => (
                                                    <CDropdownItem
                                                        key={index}
                                                        onClick={() => handleViewButtonClick(item.crop_id, item.machine_code)}
                                                    >
                                                        {item.crop}-{item.machine_code} - ({item.reference_id})
                                                    </CDropdownItem>
                                                ))}
                                        </CDropdownMenu>
                                    </CDropdown>
                                </CCol>
                                {getInitialResponceDefaultClassConfig && (
                                    <>
                                        <CButton color="primary" onClick={copyValuesToClipboard}>
                                            Copy Values
                                        </CButton>
                                        <CButton color="primary" onClick={pasteValuesFromClipboard}>
                                            Paste Values
                                        </CButton>
                                    </>)}
                            </CCol>
                        </CRow>
                        {
                            loading ?
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
                                    <CSpinner color="dark" />
                                </div>
                                :
                                <>
                                    {getResponceDefaultClassConfig &&
                                        <CRow>
                                            <CRow className="mt-5">
                                                <CRow>
                                                    <CCol>
                                                        Pipeline ID:  <strong>{defaultClassConfigurationList?.pipeline_id}</strong>
                                                    </CCol>
                                                    <CCol>
                                                        Consider Area: <strong>{defaultClassConfigurationList?._doc?.consider_area ? 'True' : 'False'}</strong>
                                                    </CCol>
                                                    <CCol>
                                                        Consider Length: <strong>{defaultClassConfigurationList?._doc?.consider_length ? 'True' : 'False'}</strong>
                                                    </CCol>
                                                </CRow>
                                                <CRow>
                                                    <CCol>
                                                        Consider Length Distribution: <strong>{defaultClassConfigurationList?._doc.consider_length_distribution ? 'True' : 'False'}</strong>
                                                    </CCol>
                                                    <CCol>
                                                        Consider Width: <strong>{defaultClassConfigurationList?._doc.consider_width ? 'True' : 'False'}</strong>
                                                    </CCol>
                                                    <CCol></CCol>
                                                </CRow>
                                            </CRow>
                                            <CRow className="mt-4">
                                                <CCol>
                                                    <CTable align="middle">
                                                        <CTableHead style={{ background: "aliceblue" }}>
                                                            <CTableRow>
                                                                <CTableHeaderCell>Class Order</CTableHeaderCell>
                                                            </CTableRow>
                                                        </CTableHead>
                                                        {classOrder ? (
                                                            <CTableBody>
                                                                {classOrder.map((item, index) => {
                                                                    return (
                                                                        <CTableRow key={index}>
                                                                            <CTableHeaderCell>{item}</CTableHeaderCell>
                                                                        </CTableRow>
                                                                    );
                                                                })}
                                                            </CTableBody>
                                                        ) : (
                                                            <CTableHeaderCell>No Data to display</CTableHeaderCell>
                                                        )}
                                                    </CTable>
                                                </CCol>
                                                <CCol>
                                                    <CTable align="middle">
                                                        <CTableHead style={{ background: "aliceblue" }}>
                                                            <CTableRow>
                                                                <CTableHeaderCell>Display Order</CTableHeaderCell>
                                                            </CTableRow>
                                                        </CTableHead>
                                                        {displayOrder ? (
                                                            <CTableBody>
                                                                {displayOrder.map((item, index) => {
                                                                    return (
                                                                        <CTableRow key={index}>
                                                                            <CTableHeaderCell>{item}</CTableHeaderCell>
                                                                        </CTableRow>
                                                                    );
                                                                })}
                                                            </CTableBody>
                                                        ) : (
                                                            <CTableHeaderCell>No Data to display</CTableHeaderCell>
                                                        )}
                                                    </CTable>
                                                </CCol>
                                                <CCol>
                                                    <CTable align="middle">
                                                        <CTableHead style={{ background: "aliceblue" }}>
                                                            <CTableRow>
                                                                <CTableHeaderCell>Class Mapping</CTableHeaderCell>
                                                            </CTableRow>
                                                        </CTableHead>
                                                        {classMapping ? (
                                                            <CTableBody>
                                                                {Object.entries(classMapping).map(([key, value], index) => {
                                                                    return (
                                                                        <CTableRow key={index}>
                                                                            <CTableHeaderCell>{key}: {value}</CTableHeaderCell>
                                                                        </CTableRow>
                                                                    );
                                                                })}
                                                            </CTableBody>
                                                        ) : (
                                                            <CTableHeaderCell>No Data to display</CTableHeaderCell>
                                                        )}
                                                    </CTable>
                                                </CCol>
                                            </CRow>
                                            <CRow className="mt-4">
                                                <CCol>
                                                    <CTable align="middle">
                                                        <CTableHead>
                                                            <CTableRow style={{ background: "aliceblue" }}>
                                                                <CTableHeaderCell>Distribution Keys</CTableHeaderCell>
                                                            </CTableRow>
                                                        </CTableHead>
                                                        {distributionKeyResponce ? (
                                                            <CTableBody>
                                                                {distributionKeyResponce.map((item, index) => {
                                                                    return (
                                                                        <CTableRow key={index}>
                                                                            <CTableHeaderCell>{item}</CTableHeaderCell>
                                                                        </CTableRow>
                                                                    );
                                                                })}
                                                            </CTableBody>
                                                        ) : (
                                                            <CTableHeaderCell>No Data to display</CTableHeaderCell>
                                                        )}
                                                    </CTable>
                                                </CCol>

                                                <CCol>
                                                    <CTable align="middle">
                                                        <CTableHead style={{ background: "aliceblue" }}>
                                                            <CTableRow>
                                                                <CTableHeaderCell>Distribution Avg Value</CTableHeaderCell>
                                                            </CTableRow>
                                                        </CTableHead>
                                                        {distributionAvgValuesResponce ? (
                                                            <CTableBody>
                                                                {distributionAvgValuesResponce.map((item, index) => {
                                                                    return (
                                                                        <CTableRow key={index}>
                                                                            <CTableHeaderCell>{item}</CTableHeaderCell>
                                                                        </CTableRow>
                                                                    );
                                                                })}
                                                            </CTableBody>
                                                        ) : (
                                                            <CTableHeaderCell>No Data to display</CTableHeaderCell>
                                                        )}
                                                    </CTable>
                                                </CCol>
                                            </CRow>
                                        </CRow>
                                    }
                                    {getInitialResponceDefaultClassConfig &&
                                        <CRow>
                                            <CRow className="mt-4">
                                                <CCol xs="auto" className="d-flex align-items-center">
                                                    <strong style={{ marginRight: "10px" }}>Pipeline ID</strong>
                                                    <span style={{ fontSize: "20px" }} className="text-danger">*</span>
                                                </CCol>
                                                <CCol xs="auto">
                                                    <CFormInput
                                                        type="text"
                                                        value={pipelineID}
                                                        onChange={(e) => setPipelineID(e.target.value)}
                                                        placeholder="Enter Pipeline ID"
                                                    />
                                                </CCol>
                                            </CRow>
                                            <CRow>
                                                <div style={{ marginTop: '30px' }}>
                                                    <strong style={{ fontSize: '20px' }}>Distribution Keys: </strong>
                                                    <div style={{ marginTop: '10px' }}>
                                                        <div>
                                                            <DropdownWithSelectedOptions options={distributionKeys.map((option) => option)} onSelect={handleSelectOptionForDistributionKeys} title={"Select an Option"} onRemove={handleRemoveOptionForDistributionKeys} selectedOptions={distributionKeyResponce} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </CRow>
                                            <CRow>
                                                <div style={{ marginTop: '30px' }}>
                                                    <strong style={{ fontSize: '20px' }}>Distribution Average Values: </strong>
                                                    <div style={{ marginTop: '10px' }}>
                                                        <div>
                                                            <DropdownWithSelectedOptions options={distributionAvgValues.map((option) => option)} onSelect={handleSelectOptionForDistributionAvgValues} title={"Select an Option"} onRemove={handleRemoveOptionForDistributionAvgValues} selectedOptions={distributionAvgValuesResponce} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </CRow>
                                            <CRow className="mt-5">
                                                <CRow>
                                                    <CCol>
                                                        <CFormCheck label="Consider Area" checked={considerArea} onChange={() => setConsiderArea(!considerArea)} />
                                                    </CCol>
                                                    <CCol>
                                                        <CFormCheck label="Consider Length" checked={considerLength} onChange={() => setConsiderLength(!considerLength)} />
                                                    </CCol>
                                                    <CCol>
                                                        <CFormCheck label="Consider Length Distribution" checked={considerLengthDistribution} onChange={() => setConsiderLengthDistribution(!considerLengthDistribution)} />
                                                    </CCol>
                                                </CRow>
                                                <CRow>
                                                    <CCol>
                                                        <CFormCheck label="Consider Width" checked={considerWidth} onChange={() => setConsiderWidth(!considerWidth)} />
                                                    </CCol>
                                                    <CCol>
                                                        <CFormCheck label="Show Segmented Image" checked={showSegmentedImage} onChange={() => setShowSegmentedImage(!showSegmentedImage)} />
                                                    </CCol>
                                                    <CCol></CCol>
                                                </CRow>
                                            </CRow>
                                            <hr className="mt-4"></hr>
                                            <div>
                                                <>
                                                    <div className="label-value-container">
                                                        <div className="label-box">
                                                            <strong>Class Order:</strong>
                                                        </div>
                                                        <div>{selectedClassOrderText}</div>
                                                    </div>
                                                    <div className="label-value-container">
                                                        <div className="label-box">
                                                            <strong>Display Order:</strong>
                                                        </div>
                                                        <div>{selectedDisplayOrderText}</div>
                                                    </div>
                                                    <CTable style={{ marginTop: '10px' }} align="middle">
                                                        <CTableHead style={{ background: "aliceblue" }}>
                                                            <CTableRow>
                                                                <CTableHeaderCell>S.No</CTableHeaderCell>
                                                                <CTableHeaderCell>Class Name</CTableHeaderCell>
                                                                <CTableHeaderCell>Class ID</CTableHeaderCell>
                                                                <CTableHeaderCell>Display Name</CTableHeaderCell>
                                                                <CTableHeaderCell>Class Code</CTableHeaderCell>
                                                                <CTableHeaderCell>Color</CTableHeaderCell>
                                                                <CTableHeaderCell>Language</CTableHeaderCell>
                                                                <CTableHeaderCell>
                                                                    <CFormCheck
                                                                        checked={
                                                                            selectedClassOrderIds.length
                                                                        }
                                                                        onChange={handleClassOrderHeaderClick}
                                                                    />
                                                                    <span className="checkbox-label">Class Order</span>
                                                                </CTableHeaderCell>
                                                                <CTableHeaderCell>
                                                                    <CFormCheck
                                                                        checked={
                                                                            selectedDisplayOrderIds.length
                                                                        }
                                                                        onChange={handleDisplayOrderHeaderClick}
                                                                    />
                                                                    <span className="checkbox-label">Display Order</span>
                                                                </CTableHeaderCell>
                                                            </CTableRow>
                                                        </CTableHead>
                                                        {cropClasses !== undefined ? (
                                                            <CTableBody>
                                                                {cropClasses.map((item, index) => {
                                                                    const classId = item.class_id;
                                                                    const isSelectedClassOrder = selectedClassOrderIds.includes(classId);
                                                                    const isSelectedDisplayOrder = selectedDisplayOrderIds.includes(classId);

                                                                    return (
                                                                        <CTableRow key={index}>
                                                                            <CTableHeaderCell>{index + 1}</CTableHeaderCell>
                                                                            <CTableDataCell>{item.class_name}</CTableDataCell>
                                                                            <CTableDataCell>{item.class_id}</CTableDataCell>
                                                                            <CTableDataCell>{item.class_display_name}</CTableDataCell>
                                                                            <CTableDataCell>{item.class_code}</CTableDataCell>
                                                                            <CTableDataCell>
                                                                                <CContainer
                                                                                    style={{
                                                                                        height: "20px",
                                                                                        backgroundColor: item.color,
                                                                                    }}
                                                                                ></CContainer>
                                                                            </CTableDataCell>
                                                                            <CTableDataCell>{item.language}</CTableDataCell>
                                                                            <CTableDataCell>
                                                                                <CFormCheck
                                                                                    checked={isSelectedClassOrder}
                                                                                    onChange={() => {
                                                                                        if (isSelectedClassOrder) {
                                                                                            setSelectedClassOrderIds(selectedClassOrderIds.filter((id) => id !== classId));
                                                                                        } else {
                                                                                            setSelectedClassOrderIds([...selectedClassOrderIds, classId]);
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </CTableDataCell>
                                                                            <CTableDataCell>
                                                                                <CFormCheck
                                                                                    checked={isSelectedDisplayOrder}
                                                                                    onChange={() => {
                                                                                        if (isSelectedDisplayOrder) {
                                                                                            setSelectedDisplayOrderIds(selectedDisplayOrderIds.filter((id) => id !== classId));
                                                                                        } else {
                                                                                            setSelectedDisplayOrderIds([...selectedDisplayOrderIds, classId]);
                                                                                        }
                                                                                        setQualityScoreDependencies([])
                                                                                    }}
                                                                                />
                                                                            </CTableDataCell>
                                                                        </CTableRow>
                                                                    );
                                                                })}
                                                            </CTableBody>
                                                        ) : (
                                                            <CTableHeaderCell>No Data to display</CTableHeaderCell>
                                                        )}
                                                    </CTable>
                                                    {selectedClassOrderIds.length > 0 && selectedDisplayOrderIds.length > 0 && (
                                                        <>
                                                            <CCol>
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <strong style={{ marginRight: '10px' }}>Quality Score Dependencies:</strong>
                                                                    <CDropdown>
                                                                        <CDropdownToggle color="primary">
                                                                            {qualityScoreDependencies && qualityScoreDependencies?.length > 0 ? qualityScoreDependencies?.join(', ') : "Select Quality Score Dependencies"}
                                                                        </CDropdownToggle>
                                                                        <CDropdownMenu>
                                                                            {selectedDisplayOrderIds &&
                                                                                selectedDisplayOrderIds.map((type, index) => (
                                                                                    <CDropdownItem
                                                                                        key={index}
                                                                                        onClick={() => onSelectQualityScoreDependencies(type)}
                                                                                    >
                                                                                        {qualityScoreDependencies?.includes(type) ? "✔ " : ""}{type}
                                                                                    </CDropdownItem>
                                                                                ))}
                                                                        </CDropdownMenu>
                                                                    </CDropdown>
                                                                </div>
                                                            </CCol>
                                                            <CTable style={{ marginTop: '50px' }} align="middle">
                                                                <CTableHead style={{ background: "aliceblue" }}>
                                                                    <CTableRow>
                                                                        <CTableHeaderCell>S.No</CTableHeaderCell>
                                                                        <CTableHeaderCell>Class Order</CTableHeaderCell>
                                                                        <CTableHeaderCell>Display Order</CTableHeaderCell>
                                                                    </CTableRow>
                                                                </CTableHead>
                                                                <CTableBody>
                                                                    {selectedClassOrderIds.map((classId, index) => (
                                                                        <CTableRow key={index}>
                                                                            <CTableHeaderCell>{index + 1}</CTableHeaderCell>
                                                                            <CTableDataCell>{classId}</CTableDataCell>
                                                                            <CTableDataCell>
                                                                                <CDropdown>
                                                                                    <CDropdownToggle color="primary">
                                                                                        {getDisplayOrderValue(classId)}
                                                                                    </CDropdownToggle>
                                                                                    <CDropdownMenu>
                                                                                        {selectedDisplayOrderIds.map((displayId, displayIndex) => (
                                                                                            <CDropdownItem
                                                                                                key={displayIndex}
                                                                                                onClick={() => handleDisplayOrderSelect(classId, displayId)}
                                                                                            >
                                                                                                {displayId}
                                                                                            </CDropdownItem>
                                                                                        ))}
                                                                                    </CDropdownMenu>
                                                                                </CDropdown>
                                                                            </CTableDataCell>
                                                                        </CTableRow>
                                                                    ))}
                                                                </CTableBody>
                                                            </CTable>
                                                            {addConfig ?
                                                                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                                                                    <button onClick={handleAddConfig} className="btn btn-primary">Submit</button>
                                                                </div>
                                                                :
                                                                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                                                                    <button onClick={handleAddConfig} className="btn btn-primary">Update</button>
                                                                </div>}
                                                        </>
                                                    )}
                                                </>
                                            </div>
                                        </CRow>
                                    }
                                </>
                        }
                    </CCardBody>
                </CCard>
            </div>
        </>
    )
}

export default CompanyDefaultClassConfigurations