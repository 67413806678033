import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//UI Elements
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CCardTitle,
  CImage,
  CFormSwitch,
  CFormLabel,
  CFormInput,
  CCollapse,
  CSpinner,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
  CContainer,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilReload } from "@coreui/icons";
//HTTP Libraries
import axios from "axios";
//Firebase
import { auth } from "../../../../firebase";
import AppConfirmModal from "../../../../components/AppConfirmModal";
import AppMessageModal from "../../../../components/AppMessageModal";

const CompanyAttachCrops = () => {
  //States
  const [loading, setLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [allCrops, setAllCrops] = useState([]);
  const [companyCrops, setCompanyCrops] = useState([]);
  const [filteredCrops, setFilteredCrops] = useState([]);
  const [cropId, setCropId] = useState("");
  const [selectedCrop, setSelectedCrop] = useState(null);
  const [message, setMessage] = useState("");
  const [selectedVariety, setSelectedVariety] = useState(null);
  const [title, setTitle] = useState("");
  const [messageModal, setMessageModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deletedCrop, setDeletedCrop] = useState("");
  const [filteredVarieties, setFilteredVarieties] = useState([]);
  //Functions

  useEffect(() => {
    getAllCrops();
  }, []);

  useEffect(() => {
    let filteredCrops = allCrops.filter(
      (crop) => !companyCrops.some((companyCrop) => companyCrop.id === crop.id)
    );
    setFilteredVarieties(filteredCrops)
    let uniqueCropNames = [...new Set(filteredCrops.map(crop => crop.crop))];
    setFilteredCrops(uniqueCropNames);
  }, [companyCrops, allCrops]);

  async function getAllCrops() {
    allCompanyCrops();

    let company_id = window.location.href.split("/").pop();
    try {
      await new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          } else {
            reject(new Error("User is not authenticated."));
          }
        });
      });

      let authToken = await auth.currentUser.getIdToken();
      let result = await axios.get(window.config.commonEndpoint + "crops/all", {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      let totalCrops = result.data.data.crops;
      let filteredCrops = totalCrops.filter(
        (crop) =>
          !companyCrops.some((companyCrop) => companyCrop.id === crop.id)
      );
      setAllCrops(totalCrops);
      setFilteredVarieties(filteredCrops)
      let uniqueCropNames = [...new Set(filteredCrops.map(crop => crop.crop))];
      setFilteredCrops(uniqueCropNames);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  // console.log(companyCrops);
  // console.log(filteredCrops);

  async function allCompanyCrops() {
    let company_id = window.location.href.split("/").pop();
    setLoading(true);
    try {
      await new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          } else {
            reject(new Error("User is not authenticated."));
          }
        });
      });

      let authToken = await auth.currentUser.getIdToken();
      let result = await axios.post(
        window.config.commonEndpoint + "crops/get/company",
        {
          company_id: company_id,
        },
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      setLoading(false);
      setCompanyCrops(result.data.data.crops);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  async function attachCrop() {
    let company_id = window.location.href.split("/").pop();
    setLoading(true);
    try {
      // console.log(selectedCrop)
      await new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          } else {
            reject(new Error("User is not authenticated."));
          }
        });
      });

      let authToken = await auth.currentUser.getIdToken();
      let result = await axios.post(
        window.config.commonEndpoint + "crops/attach/company",
        {
          company_id: company_id,
          crop_id: selectedVariety.id,
        },
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      if (result.status === 200) {
        setTitle("Success");
        setMessage("Crap and Variety are attached successfully");
        setMessageModal(true);
        setSelectedCrop(null);
        setSelectedVariety(null);
        getAllCrops();
      }
    } catch (error) {
      setMessage(error.response.data.message);
      setTitle('Error');
      setMessageModal(true);
    } finally {
      setLoading(false);
    }
  }

  async function detachCrop() {
    let company_id = window.location.href.split("/").pop();
    setLoading(true);
    setDeleteLoading(true);
    try {
      await new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          } else {
            reject(new Error("User is not authenticated."));
          }
        });
      });

      let authToken = await auth.currentUser.getIdToken();
      let result = await axios.post(
        window.config.commonEndpoint + "crops/detach/company",
        {
          company_id: company_id,
          crop_id: deletedCrop.id,
        },
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      getAllCrops();
      if (result.status === 200) {
        setMessage(
          `${deletedCrop.crop} ${deletedCrop.variety} detached successfully.`
        );
        setTitle("Success");
        setMessageModal(true);
        setDeleteLoading(false);
      }
      setLoading(false);
      console.log(result.data);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }
  const handleCropSelection = (crop) => {
    setSelectedCrop(crop);
    const varietiesForSelectedCrop = filteredVarieties
      .filter((variety) => variety.crop === crop)
      .map((variety) => variety.variety);
    setSelectedVariety(varietiesForSelectedCrop[0]);
  };
  function handleDetachCrop(crop) {
    setMessage(`Are you sure you want to detach ${crop.crop} ${crop.variety}`);
    setTitle("Detach Crop");
    setDeletedCrop(() => crop);
    setConfirmModal(true);
  }

  return (
    <div>
      <CCard>
        <CCard>
          <CCardHeader>
            <CCardTitle>
              <CRow>
                <CCol>
                  <h3>
                    <strong>Crops</strong>
                  </h3>
                </CCol>
                <CCol xs={2}>
                  <CButton color="light" onClick={() => getAllCrops()}>
                    <CIcon size="lg" icon={cilReload} />
                  </CButton>
                </CCol>
              </CRow>
            </CCardTitle>
          </CCardHeader>
          <CCardBody>
            {loading ? (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
                <CSpinner color="dark" />
              </div>
            ) : (
              <div style={{ maxHeight: "200px", overflowY: "auto" }}>
                <CTable align="middle">
                  <CTableHead>
                    <CTableRow>
                      <CTableHeaderCell>Crop</CTableHeaderCell>
                      <CTableHeaderCell>Variety</CTableHeaderCell>
                      <CTableHeaderCell>Action</CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>
                  {loading ? (
                    <CSpinner color="dark" />
                  ) : (
                    <CTableBody>
                      {companyCrops === undefined || companyCrops.length === 0 ? (
                        <CTableHeaderCell>No data to display</CTableHeaderCell>
                      ) : (
                        companyCrops.map((crop, index) => {
                          return (
                            <CTableRow key={index}>
                              <CTableDataCell>{crop.crop}</CTableDataCell>
                              <CTableDataCell>{crop.variety}</CTableDataCell>
                              <CTableDataCell>
                                <CButton
                                  color="danger"
                                  onClick={() => handleDetachCrop(crop)}
                                >
                                  Detach
                                </CButton>
                              </CTableDataCell>
                            </CTableRow>
                          );
                        })
                      )}
                    </CTableBody>
                  )}
                </CTable>
              </div>
            )}
            <CContainer>
              <CRow>
                <CCol>
                  <CDropdown>
                    <CDropdownToggle color="secondary">
                      {selectedCrop
                        ? `${selectedCrop}`
                        : "Select Crop"}
                    </CDropdownToggle>
                    <CDropdownMenu>
                      {filteredCrops &&
                        filteredCrops.map((crop, index) => (
                          <CDropdownItem
                            key={index}
                            onClick={() => handleCropSelection(crop)}
                          >
                            {crop}
                          </CDropdownItem>
                        ))}
                    </CDropdownMenu>
                  </CDropdown>
                </CCol>
                <CCol>
                  {selectedCrop && (
                    <CCol>
                      <CDropdown>
                        <CDropdownToggle color="secondary">
                          {selectedVariety?.variety ? `${selectedVariety?.variety}` : 'Select Variety'}
                        </CDropdownToggle>
                        <CDropdownMenu>
                          {filteredVarieties
                            .filter((variety) => variety.crop === selectedCrop)
                            .map((variety, index) => (
                              <CDropdownItem
                                key={index}
                                onClick={() => setSelectedVariety(variety)}
                              >
                                {variety.variety}
                              </CDropdownItem>
                            ))}
                        </CDropdownMenu>
                      </CDropdown>
                    </CCol>
                  )}
                </CCol>
                <CCol>
                  <CButton
                    className={selectedCrop && selectedVariety ? "APP-BACKGROUND" : "APP-FADE-BACKGROUND APP-FADE-BORDER"}
                    onClick={() => attachCrop()}
                    disabled={loading || !(selectedCrop && selectedVariety)}
                  >
                    {loading ? <CSpinner color="light" /> : "Attach a new crop"}
                  </CButton>
                </CCol>
              </CRow>
            </CContainer>
          </CCardBody>
        </CCard>
      </CCard>
      <AppConfirmModal
        isOpen={confirmModal}
        onCancel={() => setConfirmModal(false)}
        onConfirm={() => detachCrop()}
        title={title}
        message={message}
      />
      <AppMessageModal
        isOpen={messageModal}
        handleClose={() => setMessageModal(false)}
        title={title}
        message={message}
      />
      <br />
      <br />
    </div>
  );
};
export default CompanyAttachCrops;
