//React Components
import React, { useState } from "react";
//UI Components

import { useNavigate } from "react-router-dom";
//Firebase
import { auth, provider } from "../../firebase";
import { RecaptchaVerifier, signInWithPhoneNumber, signInWithPopup } from "firebase/auth";
import {
  CContainer,
  CCard,
  CCardHeader,
  CCardBody,
  CFormLabel,
  CFormInput,
  CButton,
  CCollapse,
  CCardFooter,
  CSpinner,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cibGoogle } from "@coreui/icons";
import axios from "axios";

const PhoneLogin = () => {
  const navigate = useNavigate();
  //Component states
  const [loading, setLoading] = useState(false);
  const [expandForm, setExpandForm] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");

  //Component functions
  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => { },
      },
      auth
    );
  };

  const handleChangePhoneNumber = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleChangeOtp = (e) => {
    setOtp(e.target.value);
  };

  const requestOTP = (e) => {
    e.preventDefault();
    setLoading(true);
    if (phoneNumber.length === 10) {
      setExpandForm(true);
      generateRecaptcha();
      let appVerifier = window.recaptchaVerifier;
      signInWithPhoneNumber(auth, "+91" + phoneNumber, appVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
        })
        .catch((error) => {
          console.log(error);
        });
    }
    setLoading(false);
  };

  const verifyOTP = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (otp.length === 6) {
      let confirmationResult = window.confirmationResult;
      try {
        const result = await confirmationResult.confirm(otp);
        const authToken = await auth.currentUser.getIdToken();
        const response = await axios.get(
          `${window.config.commonEndpoint}input/get_role`,
          {
            headers: { Authorization: `Bearer ${authToken}` },
          }
        );
        const { data } = response;
        if (data.status && data.data.userRole === "L0") {
          console.log(result.user);
          localStorage.setItem('user_id', result.user.uid)
          localStorage.setItem("authToken", result.user.accessToken);
          setLoading(false);
          navigate("/home");
        } else {
          await auth.signOut();
          alert("Unauthorized");
          setLoading(false);
        }
      } catch (error) {
        alert("Unauthorized");
        setLoading(false);
      }
    } else {
      alert("Incorrect otp");
      setLoading(false);
    }
  };

  const signInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const authToken = await auth.currentUser.getIdToken();
      const response = await axios.get(
        `${window.config.commonEndpoint}input/get_role`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      const { data } = response;
      if (data.status && data.data.userRole === "L0") {
        console.log(result.user);
        localStorage.setItem('user_id', result.user.uid)
        localStorage.setItem("authToken", result.user.accessToken);
        setLoading(false);
        navigate("/home");
      } else {
        await auth.signOut();
        alert("Unauthorized");
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      alert("Unauthorized");
    }
  }

  return (
    <div className="h-100vh d-flex justify-content-center align-items-center">
      <CContainer className="mb-5">
        <div className="d-flex justify-content-center align-items-center mb-5">
          <img src="/assets/logo.png" alt="logo" height='120' width='200' />
        </div>
        <CCard>
          <CCardHeader>
            <h3>
              <strong className="APP-COLOR-2">Phone Number Login</strong>
            </h3>
          </CCardHeader>
          <CCardBody>
            <CFormLabel>
              <h5 className="APP-COLOR">Phone Number</h5>
            </CFormLabel>
            <CFormInput
              type="text"
              id="phone"
              placeholder="Enter your phone number"
              onChange={handleChangePhoneNumber}
            />
            <CCollapse visible={expandForm}>
              <br />
              <CFormLabel>
                <h5 className="APP-COLOR">One Time Password</h5>
              </CFormLabel>
              <CFormInput
                type="text"
                id="otp"
                placeholder="Enter your OTP"
                onChange={handleChangeOtp}
              />
            </CCollapse>
            <br />
            <CButton
              className={expandForm ? "APP-BACKGROUND text-white otp-btn" : 'APP-BACKGROUND text-white register-btn'}
              color={expandForm ? "success" : "primary"}
              onClick={expandForm ? verifyOTP : requestOTP}
              disabled={loading}
            >
              {loading ? (
                <CSpinner color="light" />
              ) : expandForm ? (
                "Verify OTP"
              ) : (
                "Request OTP"
              )}
            </CButton>
          </CCardBody>
          <CCardFooter>
            <CButton style={{padding: '10px 67px'}} color="info" className="APP-BACKGROUND-2 text-white" onClick={() => signInWithGoogle()}>
              <CIcon icon={cibGoogle} /> Continue with Google
            </CButton>
          </CCardFooter>
        </CCard>
      </CContainer>
      <div id="recaptcha-container"></div>
    </div>
  );
};

export default PhoneLogin;
