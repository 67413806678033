import "./App.css";

//React Dependencies
import { BrowserRouter, Route, Routes } from "react-router-dom";

//Route Components
import DashboardLayout from "./views/home/DashboardLayout";
import PhoneLogin from "./views/authentication/PhoneLogin";
import "@coreui/coreui/dist/css/coreui.min.css";
import React from "react";
import AppRouter from "./routes/router";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

function App() {
  return (
    <BrowserRouter>
      <React.Suspense fallback={loading}>
        <AppRouter />
      </React.Suspense>
    </BrowserRouter>
  );
}

export default App;
