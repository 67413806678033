import {
  CCol,
  CFormInput,
  CFormLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CButton
} from "@coreui/react";
import React, { useState, useEffect } from "react";
import AppMessageModal from "../../../../components/AppMessageModal";
import AppConfirmModal from "../../../../components/AppConfirmModal";
import axios from "axios";
import { auth } from "../../../../firebase";

const UpdateSubCompanies = ({ isOpen, handleCancel, handleConfirm, companyID, companyDetails, setIsRefreshComponent }) => {
  const [companyName, setcompanyName] = useState('');
  const [latitude, setlatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [admin_name, setAdminName] = useState("");
  const [admin_contact_number, setAdminContactNumber] = useState("");
  const [sub_company_address_one, setSubCompanyAddressOne] = useState("");
  const [sub_company_address_two, setSubCompanyAddressTwo] = useState("");
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");
  const [messageModal, setMessageModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);

  useEffect(() => {
    if (companyDetails) {
      setcompanyName(companyDetails.company_name || '');
      setlatitude(companyDetails.company_location?.latitude || '');
      setLongitude(companyDetails.company_location?.longitude || '');
      setAdminName(companyDetails.company_admin_name || '');
      setAdminContactNumber(companyDetails.company_admin_contact_number || '');
      setSubCompanyAddressOne(companyDetails.company_address_one || '');
      setSubCompanyAddressTwo(companyDetails.company_address_two || '');
    }
  }, [companyDetails, isOpen]);

  function handleUserUpdateConfirm() {
    if (companyName === "") {
      setTitle("Error");
      setMessage("companyName cannot be empty");
      setMessageModal(true);
      return;
    }
    if (latitude === "") {
      setTitle("Error");
      setMessage("latitude cannot be empty");
      setMessageModal(true);
      return;
    }
    if (longitude === "") {
      setTitle("Error");
      setMessage("longitude cannot be empty");
      setMessageModal(true);
      return;
    }
    if (admin_name === "") {
      setTitle("Error");
      setMessage("Admin name cannot be empty");
      setMessageModal(true);
      return;
    }
    if (admin_contact_number === "") {
      setTitle("Error");
      setMessage("Contact Number cannot be empty");
      setMessageModal(true);
      return;
    }
    setTitle("Confirm");
    setMessage("Are you sure you want to update this Company?");
    setConfirmModal(true);
  }

  async function updateUser() {
    try {
      await new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          } else {
            reject(new Error("User is not authenticated."));
          }
        });
      });

      let authToken = await auth.currentUser.getIdToken();
      let result = await axios.post(
        window.config.endpoint + "sub_company/update",
        {
          company_name: companyName,
          company_location: {
            latitude: parseFloat(latitude),
            longitude: parseFloat(longitude),
          },
          sub_company_id: companyID,
          company_admin_name: admin_name,
          company_admin_contact_number: admin_contact_number,
          company_address_one: sub_company_address_one,
          company_address_two: sub_company_address_two
        },
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      if (result.status == 200) {
        setTitle("Success");
        setMessage(result.data.message);
        setMessageModal(true);
        handleConfirm();
        clear()
        setIsRefreshComponent(true)
      }
    } catch (error) {
      setTitle("Error");
      setMessage("Error while updating user");
      setMessageModal(true);
    }
  }

  const onHandleClear = () => {
    handleCancel();
    clear();
  };

  const clear = () => {
    setAdminName('');
    setcompanyName('');
    setLongitude('');
    setlatitude('');
    setAdminContactNumber('');
    setSubCompanyAddressOne('');
    setSubCompanyAddressTwo('');
  };


  return (
    <>
      <CModal
        alignment="center"
        backdrop="static"
        size="lg"
        visible={isOpen}
        onClose={() => onHandleClear()}
      >
        <CModalHeader onClose={() => onHandleClear()}>
          <CModalTitle>
            <h3>
              <strong>Update Sub-Company</strong>
            </h3>
          </CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CRow>
            <CCol>
              <CFormLabel>Company Name</CFormLabel>
              <CFormInput
                type="text"
                value={companyName}
                onChange={(e) => setcompanyName(e.target.value)}
                placeholder="Enter Company Name"
              />
            </CCol>
            <CCol>
              <CFormLabel>Latitude</CFormLabel>
              <CFormInput
                type="number"
                value={latitude}
                onChange={(e) => setlatitude(e.target.value)}
                placeholder="Enter Latitude"
              />
            </CCol>
            <CCol>
              <CFormLabel>Longitude</CFormLabel>
              <CFormInput
                type="number"
                value={longitude}
                onChange={(e) => setLongitude(e.target.value)}
                placeholder="Enter Longitude"
              />
            </CCol>
          </CRow>
          <br />
          <CRow>
            <CCol>
              <CFormLabel>Admin Name</CFormLabel>
              <CFormInput
                type="text"
                value={admin_name}
                onChange={(e) => setAdminName(e.target.value)}
                placeholder="Enter Admin Name"
              />
            </CCol>
            <CCol>
              <CFormLabel>Admin Contact Number</CFormLabel>
              <CFormInput
                type="text"
                value={admin_contact_number}
                onChange={(e) => setAdminContactNumber(e.target.value)}
                placeholder="Enter Contact Number"
              />
            </CCol>
          </CRow>
          <br />
          <CRow>
            <CCol>
              <CFormLabel>Company Address 1</CFormLabel>
              <CFormInput
                type="text"
                value={sub_company_address_one}
                onChange={(e) => setSubCompanyAddressOne(e.target.value)}
                placeholder="Enter Company Name 1"
              />
            </CCol>
          </CRow>
          <br />
          <CRow>
            <CCol>
              <CFormLabel>Company Address 2</CFormLabel>
              <CFormInput
                type="text"
                value={sub_company_address_two}
                onChange={(e) => setSubCompanyAddressTwo(e.target.value)}
                placeholder="Enter Company Name 2"
              />
            </CCol>
          </CRow>
        </CModalBody>
        <CModalFooter>
          <CButton color="success" onClick={() => handleUserUpdateConfirm()}>
            Confirm
          </CButton>{" "}
          <CButton color="danger" onClick={() => onHandleClear()}>
            Cancel
          </CButton>
        </CModalFooter>
      </CModal>
      {/* Message Modal */}
      <AppMessageModal
        isOpen={messageModal}
        title={title}
        message={message}
        handleClose={() => setMessageModal(false)}
      />
      {/* Confirm Modal */}
      <AppConfirmModal
        isOpen={confirmModal}
        title={title}
        message={message}
        onCancel={() => setConfirmModal(false)}
        onConfirm={() => updateUser()}
      />
    </>
  );
};

export default UpdateSubCompanies;
