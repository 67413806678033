import React, { useState, useEffect } from "react";
import { auth } from "../../../../firebase";
import axios from "axios";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CSpinner,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CCol,
  CRow,
  CCardTitle,
  CImage,
  CFormSwitch,
  CFormLabel,
  CFormInput,
  CDropdownMenu,
  CDropdownItem,
  CDropdown,
  CDropdownToggle,
} from "@coreui/react";
import { cilReload } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import AppMessageModal from "../../../../components/AppMessageModal";

const CompanyCreateOrUpdateByEmail = ({ setIsRefreshUserData }) => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailError, setEmailError] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const [messageModal, setMessageModal] = useState(false);
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");

  useEffect(() => {
    const hasRequiredFields = firstName && lastName && email && !emailError;
    setIsFormValid(hasRequiredFields);
  }, [firstName, lastName, email, emailError]);

  const validateEmail = (value) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!value) {
      setEmailError('');
    } else if (!emailRegex.test(value)) {
      setEmailError('Please enter a valid email address.');
    } else {
      setEmailError('');
    }
  };

  const handleEmailChange = (e) => {
    const newValue = e.target.value;
    setEmail(newValue);
    validateEmail(newValue);
  };
  async function AddNewUser() {
    try {
      setLoading(true);
      let company_id = window.location.href.split("/").pop();
      await new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          } else {
            reject(new Error("User is not authenticated."));
          }
        });
      });

      let authToken = await auth.currentUser.getIdToken();
      const user_created_responce = await axios.post(
        `${window.config.commonEndpoint}input/create_user`,
        {
          company_id: company_id,
          first_name: firstName.trim(),
          last_name: lastName.trim(),
          email: email.trim(),
          user_type: "FARMER",
        },
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      const user_delete_trading_response = await axios.post(
        `${window.config.endpoint}user/delete`,
        {
          firebase_user_id: user_created_responce.data.data.firebase_user_id
        },
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      )
      if (user_delete_trading_response.status === 200 && user_created_responce.status === 200) {
        setTitle("Success");
        setMessage("User created/updated successfully");
        setMessageModal(true);
        setIsRefreshUserData(true)
        setFirstName("");
        setLastName("");
        setEmail("");
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setTitle("Error");
      setMessage("Unable to create/update user");
      setMessageModal(true);
      setLoading(false);
    }
  }

  return (
    <>
      <div>
        <AppMessageModal
          isOpen={messageModal}
          message={message}
          title={title}
          handleClose={() => setMessageModal(false)}
        />
        <CCard>
          <CCardHeader>
            <CRow className="justify-items-between">
              <CCol xs={7}>
                <CCardTitle>
                  <h3>
                    <strong>Create/Update a User Role By Email</strong>
                  </h3>
                </CCardTitle>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol>
                <CFormLabel>
                  <strong>First Name</strong><span className="text-danger">*</span>
                </CFormLabel>
                <CFormInput
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder="First Name"
                />
              </CCol>
              <CCol>
                <CFormLabel>
                  <strong>Last Name</strong><span className="text-danger">*</span>
                </CFormLabel>
                <CFormInput
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder="Last Name"
                />
              </CCol>
              <CCol>
                <CFormLabel>
                  <strong>Email</strong><span className="text-danger">*</span>
                </CFormLabel>
                <CFormInput
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                  placeholder="Enter Email"
                  pattern="[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}"
                  title="Enter a valid email address"
                />
                {emailError && <p className="text-danger">{emailError}</p>}
              </CCol>
            </CRow>
            <br />
            <CButton
              style={{ marginRight: "10px" }}
              className={isFormValid ? "APP-BACKGROUND APP-BORDER-2" : "APP-FADE-BACKGROUND APP-FADE-BORDER"}
              onClick={AddNewUser}
              disabled={loading || !isFormValid}
            >
              {loading ? <CSpinner className="white"/> : "Add Role To User"}
            </CButton>
          </CCardBody>
        </CCard>
        <br />
      </div>
    </>
  );
};

export default CompanyCreateOrUpdateByEmail;
