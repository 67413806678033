import React from "react";
import { Route, Navigate, Routes } from "react-router-dom";
import PhoneLogin from "./../views/authentication/PhoneLogin";
import DashboardLayout from "./../views/home/DashboardLayout";
import Protected from "./protected_route";

const AppRouter = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Protected>
            <DashboardLayout />
          </Protected>
        }
      />
      <Route
        path="/home/*"
        element={
          <Protected>
            <DashboardLayout />
          </Protected>
        }
      />
      <Route path="/auth/phone" element={<PhoneLogin />} />
    </Routes>
  );
};

export default AppRouter;
