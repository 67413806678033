import React, { useState, useEffect } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCardTitle,
  CCol,
  CRow,
  CFormInput,
  CTable,
  CTableBody,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CSpinner,
} from "@coreui/react";
import { getEnamByDate } from "../../../../utils/api";
import { AppConfirmModal } from "../../../../components";
import { AppMessageModal } from "../../../../components";
import { acceptEnamReq, rejectEnamReq } from "../../../../utils/api";
import RejectReasonModal from "./RejectReasonModal";

const AllEnam = () => {
  const [loading, setLoading] = useState(false);
  const [sellerId, setSellerId] = useState("");
  const [requestDate, setRequestDate] = useState("");
  const [enamDetails, setEnamDetails] = useState({});
  const [acceptModal, setAcceptModal] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [requestData, setRequestData] = useState({});
  const [messageModal, setMessageModal] = useState(false);
  const [isRejectReasonModalOpen, setIsRejectReasonModalOpen] = useState(false);
  const [action, setAction] = useState("");
  const [details, setDetails] = useState("")

  useEffect(() => {
    if (action === "ACCEPT") {
      onAcceptEnamRequest();
    } else if (action === "REJECT") {
      onRejectEnamRequest();
    }
  }, [action]);

  const getEnam = async () => {
    setEnamDetails([{}])
    try {
      setLoading(true);
      const response = await getEnamByDate(sellerId, requestDate);
      setLoading(false);
      if (response.status === 200) {
        setEnamDetails(response.data.data);
      } else {
        setMessageModal(true);
        setMessage(response.response.data.error);
        setTitle("Error");
      }
    } catch (error) {
      setMessageModal(true);
      setMessage("Error while fetching the request");
      setTitle("Error");
    }
  };

  const acceptEnamRequest = (detail) => {
    setAcceptModal(true);
    setRequestData({
      enam_details: detail,
      request_status: "ACCEPT",
      reject_reason: "",
      firebase_user_id: enamDetails.firebaseUserId,
      request_date: requestDate,
    });
  };

  const onAcceptEnamRequest = async () => {
    try {
      const response = await acceptEnamReq(requestData);
      setAction("");
      setAcceptModal(false);
      if (response.status === 200) {
        getEnam();
        setTitle("Success");
        setMessage(response.data.message);
      } else {
        setTitle("Error");
        setMessage("Error while accepting the request");
      }
    } catch (error) {
      setMessage("Error while accepting the request");
      setTitle("Error");
    }
    setMessageModal(true);
    setAcceptModal(false);
  };

  const submitRejectReason = (rejectReason) => {
    setRequestData({
      enam_details: details,
      request_status: "REJECT",
      reject_reason: rejectReason,
      firebase_user_id: enamDetails.firebaseUserId,
      request_date: requestDate,
    });
    setIsRejectReasonModalOpen(false);
    setAction("REJECT");
  };

  const handleRejectCancel = () => {
    setIsRejectReasonModalOpen(false);
  };

  const onRejectEnamRequest = async () => {
    try {
      const response = await rejectEnamReq(requestData);
      setAction("");
      setMessageModal(true);
      if (response.status === 200) {
        getEnam();
        setTitle("Success");
        setMessage(response.data.message);
      } else {
        setTitle("Error");
        setMessage("Error while rejecting the request");
      }
    } catch (error) {
      setMessage("Error while rejecting the request");
      setTitle("Error");
    }
  };

  return (
    <>
      <CCard>
        <CCardHeader>
          <CRow className="justify-items-between">
            <CCol xs={7}>
              <CCardTitle>
                <h3>
                  <strong>Enam</strong>
                </h3>
              </CCardTitle>
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol>
              <label htmlFor="sellerId">Seller ID:</label>
              <CFormInput
                type="number"
                id="sellerId"
                name="sellerId"
                value={sellerId}
                onChange={(e) => setSellerId(e.target.value)}
              />
            </CCol>
            <CCol>
              <label htmlFor="sellerId">Request Date:</label>
              <CFormInput
                type="date"
                id="requestDate"
                name="requestDate"
                value={requestDate}
                onChange={(e) => setRequestDate(e.target.value)}
              />
            </CCol>
            <CCol>
              <CButton
                style={{ marginTop: "23px" }}
                color="success"
                className="text-white"
                onClick={() => getEnam()}
              >
                Submit
              </CButton>
            </CCol>
          </CRow>
          <CRow>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "300px",
                }}
              >
                <CSpinner />
              </div>
            ) : (
              enamDetails &&
              enamDetails.poplotsampleassayhdr && (
                <CTable align="middle" style={{ marginTop: "50px" }}>
                  <CTableHead style={{ background: "aliceblue" }}>
                    <CTableRow>
                      <CTableHeaderCell>S.No</CTableHeaderCell>
                      <CTableHeaderCell>Upjao Registered Name</CTableHeaderCell>
                      <CTableHeaderCell>Company Name</CTableHeaderCell>
                      <CTableHeaderCell>Seller Name</CTableHeaderCell>
                      <CTableHeaderCell>Seller ID</CTableHeaderCell>
                      <CTableHeaderCell>Lot ID</CTableHeaderCell>
                      <CTableHeaderCell>Actions</CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>
                  <CTableBody>
                    {enamDetails.poplotsampleassayhdr.length === 0 ? (
                       <CTableRow>
                      <CTableHeaderCell colSpan={7} style={{textAlign: "center"}}>No requests found for the seller id on that date</CTableHeaderCell>
                      </CTableRow>
                    ) : (
                      enamDetails.poplotsampleassayhdr.length &&
                      enamDetails.poplotsampleassayhdr.map((detail, index) => {
                        return (
                          <CTableRow key={index}>
                            <CTableHeaderCell>{index + 1}</CTableHeaderCell>
                            <CTableHeaderCell>
                              {enamDetails.upjaoRegisteredName}
                            </CTableHeaderCell>
                            <CTableHeaderCell>
                              {enamDetails.companyName}
                            </CTableHeaderCell>
                            <CTableHeaderCell>
                              {detail.sellername}
                            </CTableHeaderCell>
                            <CTableHeaderCell>
                              {detail.sellerid}
                            </CTableHeaderCell>
                            <CTableHeaderCell>{detail.lotid}</CTableHeaderCell>

                            <CTableHeaderCell>
                              <CButton
                                color="primary"
                                onClick={() => acceptEnamRequest(detail)}
                              >
                                Accept
                              </CButton>
                              <CButton
                                color="danger"
                                style={{ marginLeft: "15px" }}
                                onClick={() => {
                                  setIsRejectReasonModalOpen(true);
                                  setDetails(detail)
                                }}
                              >
                                Reject
                              </CButton>
                              <RejectReasonModal
                                handleRejectCancel={handleRejectCancel}
                                isRejectReasonModalOpen={
                                  isRejectReasonModalOpen
                                }
                                submitRejectReason={submitRejectReason}
                              />
                            </CTableHeaderCell>
                          </CTableRow>
                        );
                      })
                    )}
                  </CTableBody>
                </CTable>
              )
            )}
          </CRow>
        </CCardBody>
      </CCard>

      {/* Accept Confirm Modal */}
      <AppConfirmModal
        isOpen={acceptModal}
        title={"Accept"}
        message={"Are you sure you want to accept?"}
        onCancel={() => setAcceptModal(false)}
        onConfirm={() => setAction("ACCEPT")}
      />

      <AppMessageModal
        message={message}
        title={title}
        handleClose={() => setMessageModal(false)}
        isOpen={messageModal}
      />
    </>
  );
};

export default AllEnam;
