import { CModal, CModalHeader, CModalTitle, CModalBody, CButton, CModalFooter, CSpinner } from "@coreui/react";
import React from "react";

const AppConfirmModal = ({ title, message, onConfirm, onCancel, isOpen, loading }) => {
    return (
        <CModal alignment="center" visible={isOpen} onClose={() => onCancel()} color="danger">
            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100px' }}>
                    <CSpinner />
                </div>
            ) : (
                <>
                    <CModalHeader onClose={() => onCancel()}>
                        <CModalTitle><h3><strong>{title}</strong></h3></CModalTitle>
                    </CModalHeader>
                    <CModalBody><h5>{message}</h5></CModalBody>
                    <CModalFooter>
                        <CButton color="success" onClick={() => onConfirm()}>Confirm</CButton>
                        {" "}
                        <CButton color="danger" onClick={() => onCancel()}>Cancel</CButton>
                    </CModalFooter>
                </>)}
        </CModal>
    );
}

export default AppConfirmModal;