import CompanyInfo from "./views/home/Company/CompanyInfo";
import CompanyList from "./views/home/Company/CompanyList";
import CropClasses from "./views/home/Crops/CropClasses";
import CropsList from "./views/home/Crops/Crops";
import Dashboard from "./views/home/Dashboard/OldDashboard";
import Home from "./views/home/Home/Home";
import MachinesList from "./views/home/Machine/MachinesList";
import CropsListInfo from "./views/home/Crops/CropsListInfo";
import MachineListInfo from "./views/home/Machine/MachineListInfo";
import ParakhManagement from "./views/home/Company/ParakhManagement";
import UserLayout from "./views/home/Users/UserLayout";
import DefaultMetadata from './views/home/Settings/DefaultMetadata';
import ManageRoles from './views/home/Settings/ManageRoles';
import enamLayout from "./views/home/Enam/enamLayout";
import feedbackLayout from "./views/home/Feedback/feedbackLayout";


const navItems = [
  { path: "/", exact: true, name: "Home", element: Home },
  //Crops Router
  { path: "/crops/create", exact: true, name: "Crops", element: CropsList },
  {
    path: "/crops/class",
    exact: true,
    name: "Crop Classes",
    element: CropClasses,
  },
  {
    path: "/crops/listinfo/:id",
    exact: true,
    name: "Crop List Info",
    element: CropsListInfo,
  },
  //Company Router
  {
    path: "/company/create",
    exact: true,
    name: "Create Company",
    element: CompanyList,
  },
  {
    path: "/company/parakh/manage/:id",
    exact: true,
    name: "Parakh Management",
    element: ParakhManagement,
  },
  {
    path: "/company/info/:id",
    exact: true,
    name: "Company Info",
    element: CompanyInfo,
  },
  //Machines Router
  {
    path: "/machines/create",
    exact: true,
    name: "Create Machine",
    element: MachinesList,
  },
  {
    path: "/machines/listinfo/:machine_code",
    exact: true,
    name: "Machine List Info",
    element: MachineListInfo,
  },
  //Users
  {
    path: "/users",
    exact: true,
    name: "Users Info",
    element: UserLayout,
  },
   //Enam
   {
    path: "/enam",
    exact: true,
    name: "Enam Info",
    element: enamLayout,
  },
  //feedbackLayout
  {
    path: "/user/feedback",
    exact: true,
    name: "Feedback Info",
    element: feedbackLayout,
  },
  //Settings
  {
    path: "/settings/metadata",
    exact: true,
    name: "Metadata info",
    element: DefaultMetadata,
  },
  {
    path: "/settings/roles",
    exact: true,
    name: "Roles info",
    element: ManageRoles,
  },
  //Old Dashboard
  {
    path: "/dashboard",
    exact: true,
    name: "Old Dashboard",
    element: Dashboard,
  },
];

const prod = false;

// const baseUrl = prod
//   ? "https://endpoints.apps.upjao.ai/"
//   : "https://endpoints-test.apps.upjao.ai/";
// const tradingUrl = prod
//   ? "trading.apps.upjao.ai/"
//   : "https://trading-test.apps.upjao.ai/";

const tradingUrl = window.config.endpoint;
const baseUrl = window.config.commonEndpoint;

export { navItems };
