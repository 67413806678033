import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//UI Elements
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CCardTitle,
  CImage,
  CFormSwitch,
  CFormLabel,
  CFormInput,
  CCollapse,
  CSpinner,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilReload } from "@coreui/icons";
//HTTP Libraries
import axios from "axios";
//Firebase
import { auth } from "../../../../firebase";
//Components
import AppMessageModal from "../../../../components/AppMessageModal";

const CompanyAddCompanyMetadata = ({ setIsFreshMetaDataData }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [metadataValue, setMetadataValue] = useState("");
  const [messageModal, setMessageModal] = useState(false);
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");

  //Functions
  async function addCustomField() {
    let company_id = window.location.href.split("/").pop();
    const keyName = metadataValue.trim().toLowerCase().replace(/ /g, "_").replace(/\./g, "");
    setLoading(true);
    try {
      await new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          } else {
            reject(new Error("User is not authenticated."));
          }
        });
      });

      let authToken = await auth.currentUser.getIdToken();
      let result = await axios.post(
        window.config.commonEndpoint + "scans/add_company_metadata",
        {
          company_id: company_id,
          metadata_value: metadataValue,
          metadata_type: 2,
          metadata_key: keyName,
        },
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      if (result.status === 200) {
        setTitle("Success");
        setMessage(result.data.message);
        setMessageModal(true);
        setIsFreshMetaDataData(true)
      }
      setLoading(false);
      setMetadataValue("");
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  return (
    <>
      <AppMessageModal
        isOpen={messageModal}
        message={message}
        title={title}
        handleClose={() => setMessageModal(false)}
      />
      <CCard>
        <CCardHeader>
          <CRow className="justify-items-between">
            <CCol xs={7}>
              <CCardTitle>
                <h3>
                  <strong>Add Company Metadata</strong>
                </h3>
              </CCardTitle>
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardBody>
          <br />
          <CRow>
            <CCol>
              <CFormLabel>
                <strong>Metadata Value</strong>
              </CFormLabel>
              <CFormInput
                type="text"
                value={metadataValue}
                onChange={(e) => setMetadataValue(e.target.value)}
                placeholder="Enter Metadata Value"
              />
            </CCol>
          </CRow>
          <br />
          <CButton className={metadataValue ? "APP-BACKGROUND APP-BORDER-2" : "APP-FADE-BACKGROUND APP-FADE-BORDER"} onClick={() => addCustomField()} disabled={loading || !metadataValue}>
            {loading ? <CSpinner color="light" /> : "Add Metadata"}
          </CButton>
        </CCardBody>
        <br />
        <br />
      </CCard>
      <br />
    </>
  );
};

export default CompanyAddCompanyMetadata;
