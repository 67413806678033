import { cilReload } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCardTitle,
  CContainer,
  CSpinner,
  CTable,
  CTableBody,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import React, { useState, useEffect } from "react";
import { auth } from "../../../firebase";
import axios from "axios";
import AddMachine from "./components/AddMachines";
import { useNavigate } from "react-router-dom";
import AppConfirmModal from "../../../components/AppConfirmModal";
import AppMessageModal from "../../../components/AppMessageModal";
import { deleteMachineUsingID, getMachines } from '../../../utils/api'
const MachinesList = () => {
  const navigate = useNavigate();
  //States

  const [loading, setLoading] = useState(false);
  const [machineList, setMachineList] = useState(undefined);
  const [machineModal, setMachineModal] = useState(false);
  const [message, setMessage] = useState('');
  const [title, setTitle] = useState('');
  const [confirmModal, setConfirmModal] = useState(false);
  const [messageModal, setMessageModal] = useState(false);
  const [messagetitle, setMessageTitle] = useState('');
  const [machineCode, setMachineCode] = useState('')
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  //Functions
  useEffect(() => {
    getMachineList();
  }, []);

  async function getMachineList() {
    setMachineList(undefined);
    setLoading(true);
    try {
      let result = await getMachines()
      setMachineList(result?.data?.data?.machines);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  function handleConfirm() {
    setMachineModal(false);
    getMachineList();
  }

  function openMachineListInfo(machine_code) {
    navigate("/home/machines/listinfo/" + machine_code);
  }
  const onDeleteConfirmation = (id) => {
    setMachineCode(id)
    setConfirmModal(true)
    setMessage("Are you sure you want to delete this Machine?");
    setTitle("Delete Machine");
  }
  const onHandleMachineDelete = async () => {
    try {
      const responce = await deleteMachineUsingID(machineCode)
      if (responce.status === 200) {
        setConfirmModal(false);
        setMessageTitle('Success');
        setMessage(responce.data.message);
        setMessageModal(true);
        getMachineList();
      }
    } catch (error) {
      setLoading(false);
      setMessage("Error while deleting company");
      setTitle("Error");
      setConfirmModal(true);
    }
  }
  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const visibleMachineList = machineList && machineList.slice(startIndex, endIndex);

  return (
    <>
      <AppMessageModal message={message} title={messagetitle} handleClose={() => setMessageModal(false)} isOpen={messageModal} />
      <AppConfirmModal isOpen={confirmModal} title={title} message={message} onCancel={() => setConfirmModal(false)} onConfirm={() => onHandleMachineDelete()} />
      <CContainer>
        <CCard>
          <CCardHeader>
            <CCardTitle>
              <h3>
                <strong>Machines</strong>
              </h3>
            </CCardTitle>
          </CCardHeader>
          <CCardBody>
            <CButton
              style={{ marginRight: "10px" }}
              className="APP-BACKGROUND"
              onClick={() => setMachineModal(true)}
            >
              Create new machine
            </CButton>
            <CButton color="light" onClick={() => getMachineList()}>
              <CIcon size="lg" icon={cilReload} />
            </CButton>
          </CCardBody>
          <CCardBody>
            {loading ? (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
                <CSpinner />
              </div>
            ) : (
              <>
                <CTable align="middle">
                  <CTableHead style={{ background: "aliceblue" }}>
                    <CTableRow>
                      <CTableHeaderCell>S.No</CTableHeaderCell>
                      <CTableHeaderCell>Machine Name</CTableHeaderCell>
                      <CTableHeaderCell>Machine Code</CTableHeaderCell>
                      <CTableHeaderCell>Created On</CTableHeaderCell>
                      <CTableHeaderCell>Actions</CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>
                  {visibleMachineList != undefined ? (
                    <CTableBody>
                      {visibleMachineList.map((machine, index) => {
                        return (
                          <CTableRow key={index}>
                            <CTableHeaderCell>{startIndex + index + 1}</CTableHeaderCell>
                            <CTableHeaderCell>
                              {machine.machine_name}
                            </CTableHeaderCell>
                            <CTableHeaderCell>
                              {machine.machine_code}
                            </CTableHeaderCell>
                            <CTableHeaderCell>
                              {machine.created_at.substring(0, 10)}
                            </CTableHeaderCell>
                            <CTableHeaderCell>
                              <CButton
                                className="APP-BACKGROUND"
                                onClick={() =>
                                  openMachineListInfo(machine.machine_code)
                                }
                              >
                                More Action
                              </CButton>
                              <CButton
                                style={{ marginLeft: '15px' }}
                                className="APP-BACKGROUND-2 APP-BORDER"
                                onClick={() => onDeleteConfirmation(machine.machine_code)}
                              >Delete</CButton>
                            </CTableHeaderCell>
                            {/* <CTableHeaderCell><CButton color="danger">Delete</CButton></CTableHeaderCell> */}
                          </CTableRow>
                        );
                      })}
                    </CTableBody>
                  ) : (
                    <CTableHeaderCell>No data to display</CTableHeaderCell>
                  )}
                </CTable>
                {machineList && machineList.length && (
                  <div style={{ marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <span style={{ marginRight: '10px' }}>Rows per page</span>
                    <select value={rowsPerPage} onChange={handleChangeRowsPerPage} style={{ marginRight: '5px' }}>
                      <option value={5}>5</option>
                      <option value={10}>10</option>
                      <option value={50}>50</option>
                    </select>
                    <span style={{ margin: '0 10px' }}>
                      {startIndex + 1}-{Math.min(endIndex, machineList.length)} of {machineList.length}
                    </span>
                    <button
                      disabled={page === 0}
                      onClick={() => handleChangePage(page - 1)}
                      style={{ marginRight: '5px', fontWeight: 'bold', fontSize: '20px', backgroundColor: 'transparent', color: 'black', border: 'none', cursor: 'pointer' }}
                    >
                      {"<"}
                    </button>
                    <button
                      disabled={endIndex >= machineList.length}
                      onClick={() => handleChangePage(page + 1)}
                      style={{ marginLeft: '5px', padding: '4px', fontWeight: 'bold', fontSize: '20px', backgroundColor: 'transparent', color: 'black', border: 'none', cursor: 'pointer' }}
                    >
                      {">"}
                    </button>
                  </div>
                )}
              </>
            )}
          </CCardBody>
        </CCard>
        {/* Machine Modal */}
        <AddMachine
          isOpen={machineModal}
          handleCancel={() => setMachineModal(false)}
          handleConfirm={() => handleConfirm()}
        />
      </CContainer>
    </>
  );
};

export default MachinesList;
