import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CCardTitle,
  CImage,
  CFormSwitch,
  CFormLabel,
  CFormInput,
  CCollapse,
  CSpinner,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
  CContainer,
  CFormTextarea,
  CBadge,
} from "@coreui/react";
import React, { useState, useEffect } from "react";
import CIcon from "@coreui/icons-react";
import { cilReload } from "@coreui/icons";
//HTTP Libraries
import axios from "axios";
//Firebase
import { auth } from "../../../../firebase";

const AddRequestConfig = () => {
  const [loading, setLoading] = useState(false);
  const [displayName, setDisplayName] = useState("");
  const [enteredValues, setEnteredValues] = useState([]);
  const [values, setValues] = useState("");
  const [selectedMachine, setSelectedMachine] = useState(null);
  const [allMachines, setAllMachines] = useState([]);

  useEffect(() => {
    getMachines();
  }, []);

  async function getMachines() {
    let crop_id = window.location.href.split("/").pop();

    try {
      await new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          } else {
            reject(new Error("User is not authenticated."));
          }
        });
      });
      let authToken = await auth.currentUser.getIdToken();
      let result = await axios.get(
        window.config.commonEndpoint + "machines/all",
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      //   console.log(result.data.data);
      setAllMachines(result.data.data.machines);
    } catch (error) {
      console.log(error);
    }
  }

  async function addNewConfig() {
    let crop_id = window.location.href.split("/").pop();
    const keyName = displayName.toLowerCase().replace(/ /g, "_");
    let array_values = enteredValues.join(", ");
    setLoading(true);
    try {
      await new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          } else {
            reject(new Error("User is not authenticated."));
          }
        });
      });
      
      let authToken = await auth.currentUser.getIdToken();
      // console.log(authToken);
      // console.log({
      //   crop_id: crop_id,
      //   machine_code: selectedMachine.machine_code,
      //   display_name: displayName,
      //   key_name: keyName,
      //   values: array_values,
      // });
      let result = await axios.post(
        window.config.commonEndpoint + "crops/add/request_configurations",
        {
          crop_id: crop_id,
          machine_code: selectedMachine.machine_code,
          display_name: displayName,
          key_name: keyName,
          values: array_values,
        },
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      console.log(result.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  const handleAddValue = () => {
    if (values.trim() !== "") {
      setEnteredValues((prevEnteredValues) => [
        ...prevEnteredValues,
        values.trim(),
      ]);
      setValues("");
    }
  };

  const handleRemoveValue = (index) => {
    const updatedEnteredValues = [...enteredValues];
    updatedEnteredValues.splice(index, 1);
    setEnteredValues(updatedEnteredValues);
  };

  // console.log(enteredValues);

  return (
    <div>
      <CCard>
        <CCardHeader>
          <CRow className="justify-items-between">
            <CCol>
              <CCardTitle>
                <h3>
                  <strong>Add New Config</strong>
                </h3>
              </CCardTitle>
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardBody>
          <br />
          <CRow>
            <CCol>
              <CFormLabel>
                <strong>Display Name</strong>
              </CFormLabel>
              <CFormInput
                type="text"
                value={displayName}
                onChange={(e) => setDisplayName(e.target.value)}
                placeholder="Display Name"
              />
            </CCol>
          </CRow>
          <br />
          <CRow>
            <CCol xs={9}>
              <CFormLabel>
                <strong>Values</strong>
              </CFormLabel>
              <br />
              {enteredValues.map((enteredValue, index) => (
                <CButton
                  color="danger"
                  variant="outline"
                  className="m-2"
                  key={index}
                  onClick={() => handleRemoveValue(index)}
                >
                  {enteredValue}
                </CButton>
              ))}

              <CFormInput
                type="text"
                value={values}
                onChange={(e) => setValues(e.target.value)}
                placeholder="Value"
              />
            </CCol>
            <CCol className="align-self-end">
              <CButton color="info" onClick={handleAddValue} disabled={loading}>
                Add Input
              </CButton>
            </CCol>
          </CRow>
          <br />
          <CRow>
            <CCol>
              <CDropdown>
                <CDropdownToggle color="secondary">
                  {selectedMachine
                    ? `${selectedMachine.machine_name} - ${selectedMachine.machine_code}`
                    : "Select Machine"}
                </CDropdownToggle>
                <CDropdownMenu>
                  {allMachines &&
                    allMachines.map((machine, index) => (
                      <CDropdownItem
                        key={index}
                        onClick={() => setSelectedMachine(machine)}
                      >
                        {machine.machine_name} - {machine.machine_code}
                      </CDropdownItem>
                    ))}
                </CDropdownMenu>
              </CDropdown>
            </CCol>
          </CRow>
          <br />
          <CButton
            color="success"
            onClick={() => addNewConfig()}
            disabled={loading}
          >
            {loading ? <CSpinner color="light" /> : "Add Input"}
          </CButton>
        </CCardBody>
      </CCard>
    </div>
  );
};

export default AddRequestConfig;
