import React, { useState, useEffect } from "react";
import { auth } from "../../../../firebase";
import axios from "axios";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CSpinner,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CCol,
  CRow,
  CCardTitle,
  CImage,
  CFormSwitch,
  CFormLabel,
  CFormInput,
  CDropdownMenu,
  CDropdownItem,
  CDropdown,
  CDropdownToggle,
} from "@coreui/react";
import { cilReload } from "@coreui/icons";
import CIcon from "@coreui/icons-react";

const ParakhCreateOrUpdateByPhone = ({ is_refresh_component }) => {
  const [loading, setLoading] = useState(false);
  const [phNo, setPhNo] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [role, setRole] = useState("");
  const [userType, setUserType] = useState("");
  const [subCompanies, setSubCompanies] = useState([]);
  const [selectedSubCompany, setSelectedSubCompany] = useState("");
  const [companyType, setCompanyType] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [phNoError, setPhNoError] = useState('');

  useEffect(() => {
    const hasRequiredFields = firstName && lastName && phNo && !phNoError;
    const isDropdownsSelected = role && selectedSubCompany.company_name;
    setIsFormValid(hasRequiredFields && isDropdownsSelected);
  }, [firstName, lastName, phNo, phNoError, role, selectedSubCompany]);

  useEffect(() => {
    getSubCompanies();
    getCompanyDetails();
  }, []);

  useEffect(() => {
    if (is_refresh_component) {
      getSubCompanies();
      getCompanyDetails();
    }
  }, [is_refresh_component])

  const validatePhoneNumber = (value) => {
    const phoneRegex = /^\d{10}$/;

    if (!value) {
      setPhNoError('');
    } else if (!phoneRegex.test(value)) {
      setPhNoError('Please enter a valid 10-digit phone number.');
    } else {
      setPhNoError('');
    }
  };
  const handlePhoneNumberChange = (e) => {
    const newValue = e.target.value;
    setPhNo(newValue);
    validatePhoneNumber(newValue);
  };
  async function getCompanyDetails() {
    setLoading(true);
    let company_id = window.location.href.split("/").pop();
    try {
      await new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          } else {
            reject(new Error("User is not authenticated."));
          }
        });
      });

      let authToken = await auth.currentUser.getIdToken();
      let result = await axios.get(
        window.config.commonEndpoint + "company/get/" + company_id,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      setCompanyType(result.data.data.companyInfo.company_type);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }
  async function getSubCompanies() {
    setLoading(true);
    let company_id = window.location.href.split("/").pop();
    try {
      await new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          } else {
            reject(new Error("User is not authenticated."));
          }
        });
      });

      let authToken = await auth.currentUser.getIdToken();
      let result = await axios.get(
        window.config.endpoint + "sub_company/for_parent/" + company_id,
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      setSubCompanies(result.data.data);
      console.log(result.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  async function AddNewUser() {
    try {
      setLoading(true);
      let company_id = window.location.href.split("/").pop();
      await new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          } else {
            reject(new Error("User is not authenticated."));
          }
        });
      });

      let authToken = await auth.currentUser.getIdToken();
      console.log({
        first_name: firstName,
        last_name: lastName,
        phone_number: phNo,
        user_type: userType,
        company_id: company_id,
      });
      const res = await axios.post(
        `${window.config.commonEndpoint}input/create_user`,
        {
          first_name: firstName,
          last_name: lastName,
          phone_number: phNo,
          user_type: userType,
          company_id: company_id,
        },
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      let firebase_user_id = res.data.data.firebase_user_id;
      // console.log(firebase_user_id);
      try {
        const res = await axios.post(
          `${window.config.endpoint}user`,
          {
            firebase_user_id: firebase_user_id,
            user_role: role,
            parent_company_id: company_id,
            sub_company_id: selectedSubCompany.sub_company_id,
          },
          {
            headers: { Authorization: `Bearer ${authToken}` },
          }
        );
        console.log(res.data);
        setLoading(false);
        alert(res.data.data)
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }

  return (
    <>
      <div>
        <CCard>
          <CCardHeader>
            <CRow className="justify-items-between">
              <CCol xs={7}>
                <CCardTitle>
                  <h3>
                    <strong>Create/Update a User Role By Phone</strong>
                  </h3>
                </CCardTitle>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol>
                <CFormLabel>
                  <strong>First Name</strong><span className="text-danger">*</span>
                </CFormLabel>
                <CFormInput
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder="First Name"
                />
              </CCol>
              <CCol>
                <CFormLabel>
                  <strong>Last Name</strong><span className="text-danger">*</span>
                </CFormLabel>
                <CFormInput
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder="Last Name"
                />
              </CCol>
              <CCol>
                <CFormLabel>
                  <strong>Phone</strong><span className="text-danger">*</span>
                </CFormLabel>
                <CFormInput
                  type="text"
                  value={phNo}
                  onChange={handlePhoneNumberChange}
                  placeholder="Enter Phone"
                  maxLength="10"
                />
                {phNoError && <p className="text-danger">{phNoError}</p>}
              </CCol>
              <CCol>
                <CFormLabel>
                  <strong>Role For User</strong><span className="text-danger">*</span>
                </CFormLabel>
                <CDropdown>
                  <CDropdownToggle className="APP-FADE-BACKGROUND APP-FADE-BORDER text-dark">
                    {role
                      ? `${role}`
                      : "Select Role"}
                  </CDropdownToggle>
                  <CDropdownMenu>
                    {
                      companyType === "PARAKH_PACS" ? (
                        <>
                          <CDropdownItem onClick={() => {
                            setRole("PACS-Admin")
                            setUserType("FARMER")
                          }}>
                            <p style={{margin: 0, padding:0}} className="text-dark">PACS Admin</p>
                          </CDropdownItem>
                          <CDropdownItem onClick={() => {
                            setRole("Farmer")
                            setUserType("FARMER")
                          }}>
                            <p style={{margin: 0, padding:0}} className="text-dark">Farmer</p>
                          </CDropdownItem>
                        </>
                      ) : (
                        <>
                          <CDropdownItem onClick={() => {
                            setRole("APMC-Admin")
                            setUserType("TRADER")
                          }}>
                            <p style={{margin: 0, padding:0}} className="text-dark">APMC Admin</p>
                          </CDropdownItem>
                          <CDropdownItem onClick={() => {
                            setRole("Trader")
                            setUserType("TRADER")
                          }}>
                            <p style={{margin: 0, padding:0}} className="text-dark">Trader</p>
                          </CDropdownItem>
                        </>
                      )
                    }
                  </CDropdownMenu>
                </CDropdown>
              </CCol>
              <CCol>
                <CRow>
                  <CFormLabel>
                    <strong>Sub Company</strong><span className="text-danger">*</span>
                  </CFormLabel>
                  <CDropdown>
                    <CDropdownToggle className="APP-FADE-BACKGROUND APP-FADE-BORDER text-dark">
                      {selectedSubCompany.company_name
                        ? `${selectedSubCompany.company_name}`
                        : "Select Sub Company"}
                    </CDropdownToggle>
                    <CDropdownMenu>
                      {subCompanies &&
                        subCompanies.map((subCompany, index) => (
                          <CDropdownItem
                            key={index}
                            onClick={() => setSelectedSubCompany(subCompany)}
                          >
                            <p style={{margin: 0, padding:0}} className="text-dark">{subCompany.company_name}</p>
                          </CDropdownItem>
                        ))}
                    </CDropdownMenu>
                  </CDropdown>
                </CRow>
              </CCol>
            </CRow>
            <br />
            <CButton
              style={{ marginRight: "10px" }}
              className={!isFormValid || loading ? "APP-FADE-BACKGROUND APP-FADE-BORDER":"APP-BACKGROUND"}
              onClick={AddNewUser}
              disabled={!isFormValid || loading}
            >
              {loading ? <CSpinner color="dark" /> : "Add Role to User"}
            </CButton>
          </CCardBody>
        </CCard>
        <br />
      </div>
    </>
  );
};

export default ParakhCreateOrUpdateByPhone;
