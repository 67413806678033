import { CCol, CContainer, CRow, CNavbar, CNavbarBrand } from "@coreui/react";
import React from "react";
import GetMachineByCode from "./components/GetMachineByCode";

const MachineListInfo = () => {
  return (
    <div>
      <CContainer>
        <CRow>
          <CCol>
            <GetMachineByCode />
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default MachineListInfo;
