import {
  CCol,
  CContainer,
  CRow,
  CNavbar,
  CNavbarBrand,
  CButton,
} from "@coreui/react";
import React, { useState, useEffect } from "react";
import CIcon from "@coreui/icons-react";
import { cilArrowCircleLeft } from "@coreui/icons";
//HTTP Libraries
import axios from "axios";
//Firebase
import { auth } from "../../../firebase";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ParakhAddSubCompanies from "./components/ParakhAddSubCompanies";
import ParakhGetSubcompanies from "./components/ParakhGetSubcompanies";
import ParakhGetUsers from "./components/ParakhGetUsers";
import ParakhCreateOrUpdateByEmail from "./components/ParakhCreateOrUpdateByEmail";
import ParakhCreateOrUpdateByPhone from "./components/ParakhCreateOrUpdateByPhone";

const ParakhManagement = () => {
  const navigate = useNavigate();
  const headerTitle = useSelector((state) => state.headerTitle);
  const [is_refresh_component, setIsRefreshComponent] = useState(false)

  useEffect(() =>{
    if(is_refresh_component) {
      setIsRefreshComponent(false) 
    }
  }, [is_refresh_component])

  return (
    <>
      <CContainer>
        <div class="mb-4">
        <div className="d-flex align-items-center">
          <div>
          <CButton
            className="APP-BACKGROUND"
            onClick={() => {
              navigate(
                "/home/company/info/" + window.location.href.split("/").pop()
              );
            }}
          >
            <CIcon icon={cilArrowCircleLeft} />
            
          </CButton>
          </div>
          <div style={{marginLeft:'25px',fontSize:'20px'}}><strong>{headerTitle}</strong></div>
          </div>
        </div>
        <CRow>
          <CCol>
            <ParakhAddSubCompanies setIsRefreshComponent={setIsRefreshComponent}/>
          </CCol>
          <CCol>
            <ParakhGetSubcompanies is_refresh_component={is_refresh_component} setIsRefreshComponent={setIsRefreshComponent}/>
          </CCol>
        </CRow>
        <br />
        <ParakhCreateOrUpdateByEmail is_refresh_component={is_refresh_component}/>
        <ParakhCreateOrUpdateByPhone is_refresh_component={is_refresh_component}/>
        <ParakhGetUsers is_refresh_component={is_refresh_component}/>
      </CContainer>
    </>
  );
};

export default ParakhManagement;
