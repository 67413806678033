import React, { useEffect, useState } from "react";
import { auth } from "../../../../firebase";
import axios from "axios";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCardTitle,
  CCol,
  CRow,
  CSpinner,
  CTable,
  CTableBody,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CFormInput,
  CForm,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
} from "@coreui/react";
import { cilReload } from "@coreui/icons";
import CIcon from "@coreui/icons-react";

const ParakhGetUsers = ({ is_refresh_component }) => {
  const [loading, setLoading] = useState(false);
  const [subCompanyUsers, setSubCompanyUsers] = useState([]);
  const [selectedSubCompany, setSelectedSubCompany] = useState("");
  const [subCompanies, setSubCompanies] = useState(undefined);

  useEffect(() => {
    getSubCompanies();
  }, []);

  useEffect(() => {
    if (is_refresh_component) {
      getSubCompanies();
    }
  }, [is_refresh_component])

  useEffect(() => {
    console.log(selectedSubCompany);
    if (selectedSubCompany !== "") {
      getSubCompanyUsers(selectedSubCompany.sub_company_id);
    }
  }, [selectedSubCompany]);

  async function getSubCompanies() {
    setLoading(true);
    let company_id = window.location.href.split("/").pop();
    try {
      await new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          } else {
            reject(new Error("User is not authenticated."));
          }
        });
      });

      let authToken = await auth.currentUser.getIdToken();
      let result = await axios.get(
        window.config.endpoint + "sub_company/for_parent/" + company_id,
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      setSubCompanies(result.data.data);
      // console.log(result.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  async function getSubCompanyUsers(sub_company_id) {
    setLoading(true);
    setSubCompanyUsers([]);
    let company_id = window.location.href.split("/").pop();
    try {
      await new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          } else {
            reject(new Error("User is not authenticated."));
          }
        });
      });

      let authToken = await auth.currentUser.getIdToken();
      let result = await axios.post(
        window.config.endpoint + "users/company",
        {
          company_id: company_id,
          sub_company_id: sub_company_id,
        },
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      console.log(result.data.data);
      setSubCompanyUsers(result.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  console.log(subCompanyUsers);

  return (
    <div>
      <div style={{ maxHeight: "430px", overflowY: "auto" }}>
        <CCard>
          <CCardHeader>
            <CRow className="justify-items-between">
              <CCol xs={7}>
                <CCardTitle>
                  <h3>
                    <strong>Sub Company Users</strong>
                  </h3>
                  <CButton
                    color="light"
                    onClick={() =>
                      getSubCompanyUsers(selectedSubCompany.sub_company_id)
                    }
                  >
                    <CIcon size="lg" icon={cilReload} />
                  </CButton>
                </CCardTitle>
              </CCol>
              <CCol>
                <CDropdown>
                  <CDropdownToggle className="APP-FADE-BACKGROUND APP-FADE-BORDER text-dark">
                    {selectedSubCompany.company_name
                      ? `${selectedSubCompany.company_name}`
                      : "Select Sub Company"}
                  </CDropdownToggle>
                  <CDropdownMenu>
                    {subCompanies &&
                      subCompanies.map((subCompany, index) => (
                        <CDropdownItem
                          key={index}
                          onClick={() => setSelectedSubCompany(subCompany)}
                        >
                          <p style={{ margin: 0, padding: 0 }} className="text-dark">{subCompany.company_name}</p>
                        </CDropdownItem>
                      ))}
                  </CDropdownMenu>
                </CDropdown>
              </CCol>
              {/* <CCol xs={2}>
                <CButton color="info" onClick={() => getSubCompanyUsers()}>
                  Get Users
                </CButton>
              </CCol> */}
            </CRow>
          </CCardHeader>
          <CCardBody>
            <div style={{ maxHeight: "200px", overflowY: "auto" }}>
              <CTable align="middle">
                <CTableHead>
                  <CTableRow>
                    <CTableHeaderCell>S.No</CTableHeaderCell>
                    <CTableHeaderCell>Name</CTableHeaderCell>
                    <CTableHeaderCell>Phone Number</CTableHeaderCell>
                    <CTableHeaderCell>Email</CTableHeaderCell>
                    <CTableHeaderCell>Role</CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                {loading ? (
                  <CSpinner color="dark" />
                ) : (
                  <CTableBody>
                    {subCompanyUsers === null ? (
                      <CTableRow>
                        <CTableHeaderCell colSpan={5}>
                          No users in this Sub Company
                        </CTableHeaderCell>
                      </CTableRow>
                    ) : subCompanyUsers.length === 0 ? (
                      <CTableRow>
                        <CTableHeaderCell colSpan={5}>
                          Please select a <u>Sub Company</u> from
                          <strong> dropdown</strong>
                        </CTableHeaderCell>
                      </CTableRow>
                    ) : (
                      subCompanyUsers.map((user, index) => (
                        <CTableRow key={index}>
                          <CTableHeaderCell>{index + 1}</CTableHeaderCell>
                          <CTableHeaderCell>
                            {user.first_name} {user.last_name}
                          </CTableHeaderCell>
                          <CTableHeaderCell>{user.phone_number}</CTableHeaderCell>
                          <CTableHeaderCell>{user.email}</CTableHeaderCell>
                          <CTableHeaderCell>{user.user_role}</CTableHeaderCell>
                        </CTableRow>
                      ))
                    )}
                  </CTableBody>
                )}
              </CTable>
            </div>
          </CCardBody>
        </CCard>
        <br />
      </div>
    </div>
  );
};

export default ParakhGetUsers;
