import {
  CCol,
  CFormInput,
  CFormLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CButton,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
} from "@coreui/react";
import React, { useState } from "react";
import AppMessageModal from "../../../../components/AppMessageModal";
import AppConfirmModal from "../../../../components/AppConfirmModal";
import axios from "axios";
import { auth } from "../../../../firebase";

const UpdateUser = ({ isOpen, handleCancel, handleConfirm, userId }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");
  const [messageModal, setMessageModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);

  function handleUserUpdateConfirm() {
    if (firstName === "") {
      setTitle("Error");
      setMessage("First Name cannot be empty");
      setMessageModal(true);
      return;
    }
    if (lastName === "") {
      setTitle("Error");
      setMessage("Last Name cannot be empty");
      setMessageModal(true);
      return;
    }
    setTitle("Confirm");
    setMessage("Are you sure you want to update this user?");
    setConfirmModal(true);
  }

  async function updateUser() {
    try {
      await new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          } else {
            reject(new Error("User is not authenticated."));
          }
        });
      });

      let authToken = await auth.currentUser.getIdToken();
      let result = await axios.post(
        window.config.commonEndpoint + "users/update/other_user",
        {
          first_name: firstName,
          last_name: lastName,
          firebase_user_id: userId,
        },
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      if (result.status == 200) {
        setTitle("Success");
        setMessage("User updated successfully");
        setMessageModal(true);
        handleConfirm();
      }
      setFirstName("");
      setLastName("");
      console.log(result.data);
    } catch (error) {
      setTitle("Error");
      setMessage("Error while updating user");
      setMessageModal(true);
    }
  }

  return (
    <>
      <CModal
        alignment="center"
        backdrop="static"
        size="lg"
        visible={isOpen}
        onClose={() => handleCancel()}
      >
        <CModalHeader onClose={() => handleCancel()}>
          <CModalTitle>
            <h3>
              <strong>Update this user</strong>
            </h3>
          </CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CRow>
            <CCol>
              <CFormLabel>First Name</CFormLabel>
              <CFormInput
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="Enter your first name"
              />
            </CCol>
            <CCol>
              <CFormLabel>Last Name</CFormLabel>
              <CFormInput
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Enter your last name "
              />
            </CCol>
          </CRow>
        </CModalBody>
        <CModalFooter>
          <CButton color="success" onClick={() => handleUserUpdateConfirm()}>
            Confirm
          </CButton>{" "}
          <CButton color="danger" onClick={() => handleCancel()}>
            Cancel
          </CButton>
        </CModalFooter>
      </CModal>
      {/* Message Modal */}
      <AppMessageModal
        isOpen={messageModal}
        title={title}
        message={message}
        handleClose={() => setMessageModal(false)}
      />
      {/* Confirm Modal */}
      <AppConfirmModal
        isOpen={confirmModal}
        title={title}
        message={message}
        onCancel={() => setConfirmModal(false)}
        onConfirm={() => updateUser()}
      />
    </>
  );
};

export default UpdateUser;
