import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CContainer,
  CHeader,
  CHeaderDivider,
  CHeaderToggler,
  CButton,
  CRow,
  CCol,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilMenu } from "@coreui/icons";
import "@coreui/coreui/dist/css/coreui.min.css";

import { AppBreadcrumb } from "./index";
import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";

const AppHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sidebarShow = useSelector((state) => state.sidebarShow);
  const headerTitle = useSelector((state) => state.headerTitle);

  async function logout() {
    try {
      localStorage.removeItem("authToken");
      await auth.signOut();
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <CHeader position="sticky" className="p-0 mb-5">
      <div className="APP-BACKGROUND d-flex justify-content-between w-100">
        <div>
          <CRow className="">
            <CCol>
              <CContainer fluid>
                <CHeaderToggler
                  className="ps-1"
                  onClick={() =>
                    dispatch({ type: "set", sidebarShow: sidebarShow })
                  }
                >
                  <CIcon className="mt-3 text-white" icon={cilMenu} size="lg" />
                </CHeaderToggler>
              </CContainer>
            </CCol>
          </CRow>
        </div>
        <div className="d-flex">
          {/* <div className="text-start p-2">
          <h3>{headerTitle}</h3>
        </div> */}
          <div className="text-end p-2">
            <CButton color="danger" className="text-white" onClick={() => logout()}>
              Logout
            </CButton>
          </div>
        </div>
      </div>
      <CHeaderDivider className="m-0" />
      <CContainer fluid>
        <AppBreadcrumb />
      </CContainer>
    </CHeader>
  );
};

export default AppHeader;
