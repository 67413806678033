import { CButton, CCard, CCardBody, CCardHeader, CCardTitle, CCol, CContainer, CFormInput, CFormLabel, CFormSelect, CRow, CSpinner } from "@coreui/react";
import React, { useState, useEffect } from "react";
import { auth, db } from "../../../firebase";
import { DocumentReference, addDoc, doc, setDoc } from "firebase/firestore";

const Dashboard = () => {
    //States
    const [loading, setLoading] = useState(false)
    const [selectedCompany, setSelectedCompany] = useState('')
    const [selectedCCode, setSelectedCCode] = useState('')
    const [selectedRole, setSelectedRole] = useState('')
    const [emailAddress, setEmailAddress] = useState('')
    //Functions


    const handleCompanyChange = (e) => {
        let data = e.target.value.split('~')
        setSelectedCompany(data[0])
        setSelectedCCode(data[1])
    }

    const handleRoleChange = (e) => {
        console.log(e.target.value)
        setSelectedRole(e.target.value)
    }

    const handleEmailChange = (e) => {
        setEmailAddress(e.target.value)
    }

    async function createDashboardUser() {
        if (selectedCompany === '' || selectedRole === '' || emailAddress === '') {
            alert('Please fill all the fields')
            return
        } else if (selectedCompany === 'Select Company' || selectedRole === 'Select Role') {
            alert('Please select a valid option')
            return
        } else {
            try {
                setLoading(true)
                const docRef = doc(db, 'account_details', 'Dashboard', 'Accounts', emailAddress)
                await setDoc(docRef, {
                    email: emailAddress,
                    company_code: selectedCCode,
                    company_name: selectedCompany,
                    company_display: selectedCompany + '~' + selectedCCode,
                    role: selectedRole,
                    state: '',
                    token: '',
                    password: '',
                    type: selectedRole !== 'L1' ? 'user' : 'client',
                    date: new Date().toLocaleString(),
                    user: auth.currentUser.displayName,
                });
                setLoading(false)
                alert("User added successfully")
            } catch (error) {
                alert(error.message)
            }
        }
    }

    return (
        <CContainer>
            <CCard>
                <CCardHeader>
                    <CCardTitle><h3><strong>Old Dashboard</strong></h3></CCardTitle>
                </CCardHeader>
                <CCardBody>
                    <CRow>
                        <CCol>
                            <CFormLabel><h5>Select Company</h5></CFormLabel>
                            <CFormSelect aria-label="Select Company" options={
                                [
                                    'Select Company',
                                    { label: 'Maize_Products', value: 'Maize_Products~mp' },
                                    { label: 'Olam', value: 'Olam~ol' },
                                    { label: 'Upjao', value: 'Upjao~up' },
                                    { label: 'Public', value: 'Public~pb' },
                                    { label: 'Farmart', value: 'Farmart~fm' },
                                    { label: 'Individual', value: 'Individual~in' },
                                    { label: 'UAT', value: 'UAT~up' },
                                    { label: 'UP Mandi Demo', value: 'UP_Mandi_Demo~um' },
                                    { label: 'MPSAMB', value: 'MPSAMB~ms' },
                                    { label: 'Smart AgriBiz', value: 'Smart_AgriBiz~ab' },
                                    { label: 'Gujcomasol', value: 'Gujcomasol~gc' },
                                    { label: 'TNSAMB', value: 'TNSAMB~fo' },
                                    { label: 'Reliance Foundation', value: 'Reliance_Foundation~rf' },
                                    { label: 'Food corporation of India', value: 'Food_corporation_of_India~fc' },
                                ]
                            } onChange={handleCompanyChange} />
                        </CCol>
                        <CCol>
                            <CFormLabel><h5>Role</h5></CFormLabel>
                            <CFormSelect aria-label="Select Role" options={
                                [
                                    'Select Role',
                                    { label: 'L1', value: 'L1' },
                                    { label: 'L2', value: 'L2' },
                                    { label: 'L3', value: 'L3' },
                                ]
                            } onChange={handleRoleChange} />
                        </CCol>
                    </CRow>
                    <br />
                    <CFormLabel><h5>Email Address</h5></CFormLabel>
                    <CFormInput type="text" placeholder="Enter Email Address" onChange={handleEmailChange} />
                    <br />
                    <CButton color="primary" block onClick={() => createDashboardUser()}>{loading ? <CSpinner color="light" /> : 'Submit'}</CButton>
                </CCardBody>
            </CCard>
        </CContainer>
    );
}

export default Dashboard;