import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//UI Elements
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CCardTitle,
  CImage,
  CFormSwitch,
  CFormLabel,
  CFormInput,
  CCollapse,
  CSpinner,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilReload } from "@coreui/icons";
//HTTP Libraries
import axios from "axios";
//Firebase
import { auth } from "../../../../firebase";
//Components
import AppMessageModal from "../../../../components/AppMessageModal";
import AppConfirmModal from "../../../../components/AppConfirmModal";

const CompanyAddCustomInput = ({ setIsRefreshCustomInputData }) => {
  const navigate = useNavigate();
  //States
  const [loading, setLoading] = useState(false);
  const [displayName, setDisplayName] = useState("");
  const [fieldType, setFieldType] = useState("");
  const [messageModal, setMessageModal] = useState(false);
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");

  //Functions

  async function addCustomField() {
    let company_id = window.location.href.split("/").pop();
    const keyName = displayName.toLowerCase().replace(/ /g, "_");
    setLoading(true);
    try {
      await new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          } else {
            reject(new Error("User is not authenticated."));
          }
        });
      });

      let authToken = await auth.currentUser.getIdToken();
      let result = await axios.post(
        window.config.commonEndpoint + "company/webhook/add/custom_inputs",
        {
          company_id: company_id,
          display_name: displayName,
          field_type: 2,
          key_name: keyName,
        },
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      if (result.status === 200) {
        setTitle("Success");
        setMessage("User created successfully");
        setMessageModal(true);
        setIsRefreshCustomInputData(true)
        setDisplayName("");
      }
    } catch (error) {
      setTitle("Error");
      setMessage("Unable to add custom input");
      setMessageModal(true);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <AppMessageModal
        isOpen={messageModal}
        message={message}
        title={title}
        handleClose={() => setMessageModal(false)}
      />
      <CCard>
        <CCardHeader>
          <CRow className="justify-items-between">
            <CCol>
              <CCardTitle>
                <h3>
                  <strong>Add Custom Input</strong>
                </h3>
              </CCardTitle>
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardBody>
          <br />
          <CRow>
            <CCol>
              <CFormLabel>
                <strong>Display Name</strong>
              </CFormLabel>
              <CFormInput
                type="text"
                value={displayName}
                onChange={(e) => setDisplayName(e.target.value)}
                placeholder="Enter Field Display Name"
              />
            </CCol>
          </CRow>
          <br />
          <CButton
            className={displayName ? "APP-BACKGROUND APP-BORDER-2" : "APP-FADE-BACKGROUND APP-FADE-BORDER"}
            onClick={() => addCustomField()}
            disabled={loading || !displayName}
          >
            {loading ? <CSpinner color="light" /> : "Add Input"}
          </CButton>
        </CCardBody>
      </CCard>
    </>
  );
};

export default CompanyAddCustomInput;
