import {
  CCol,
  CContainer,
  CRow,
  CNavbar,
  CNavbarBrand,
  CButton,
} from "@coreui/react";
import React, { useState, useEffect } from "react";
import CompanyUpdate from "./components/CompanyUpdate";
import CompanyUserApproval from "./components/CompanyUserApproval";
import PaymentHistory from "./components/PaymentHistory";
import CompanyAddCustomInput from "./components/CompanyAddCustomInput";
import CompanyGetCustomInput from "./components/CompanyGetCustomInput";
import WebhookDetails from "./components/WebhookDetails";
import CompanyAttachCrops from "./components/CompanyAttachCrops";
import CompanyAttachMachines from "./components/CompanyAttachMachines";
import CompanyAddCompanyMetadata from "./components/CompanyAddCompanyMetadata";
import CompanyGetCompanyMetadata from "./components/CompanyGetCompanyMetadata";
import CompanyCreateOrUpdateByPassword from "./components/CompanyCreateOrUpdateByPassword";
import CIcon from "@coreui/icons-react";
import { cilReload, cilArrowCircleLeft } from "@coreui/icons";
//HTTP Libraries
import axios from "axios";
//Firebase
import { auth } from "../../../firebase";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import TradingUserList from "./components/TradingUserList";
import PaymentAccessHistory from "./components/PaymentAccessHistory";
import CompanyCreateOrUpdateByEmail from './components/CompanyCreateOrUpdateByEmail';
import CompanyCreateOrUpdateByPhone from './components/CompanyCreateOrUpdateByPhone';
import CompanyDefaultClassConfigurations from './components/CompanyDefaultClassConfigurations'
import CompanyDefaultResponseConfigurations from './components/CompanyDefaultResponseConfigurations'

const CompanyInfo = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const headerTitle = useSelector((state) => state.headerTitle);
  const [companyType, setCompanyType] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [scanFlowType, setScanFlowType] = useState("");
  const [loading, setLoading] = useState(false);
  const [isFreshMetaDataData, setIsFreshMetaDataData] = useState(false);
  const [isRefreshUserData, setIsRefreshUserData] = useState(false);
  const [isRefreshCustomInputData, setIsRefreshCustomInputData] = useState(false);
  const [isRefreshAccessPayment, setIsRefreshAccessPayment] = useState(false);


  useEffect(() => {
    getCompanyDetails();
    setCompanyId(window.location.href.split("/").pop());
    // return () => {
    //   dispatch({ type: "setHeaderTitle", headerTitle: "" });
    // };
  }, []);

  async function getCompanyDetails() {
    let company_id = window.location.href.split("/").pop();
    try {
      await new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          } else {
            reject(new Error("User is not authenticated."));
          }
        });
      });

      let authToken = await auth.currentUser.getIdToken();
      let result = await axios.get(
        window.config.commonEndpoint + "company/get/" + company_id,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      dispatch({
        type: "setHeaderTitle",
        headerTitle: result.data.data.companyInfo.name,
      });
      console.log(result.data.data.companyInfo.company_type);
      setCompanyType(result.data.data.companyInfo.company_type);
      setScanFlowType(result.data.data.companyInfo.scan_flow_type);
    } catch (error) {
      console.log(error);
    }
  }
  // async function makeCompanySyncAsync() {
  //   let company_id = window.location.href.split("/").pop();
  //   try {
  //     setLoading(true);
  //     await new Promise((resolve, reject) => {
  //       auth.onAuthStateChanged((user) => {
  //         if (user) {
  //           resolve(user);
  //         } else {
  //           reject(new Error("User is not authenticated."));
  //         }
  //       });
  //     });

  //     let authToken = await auth.currentUser.getIdToken();
  //     let result = await axios.post(
  //       window.config.commonEndpoint + "company/make_company_sync_async",
  //       {
  //         company_id: company_id,
  //         scan_flow_type: "SYNC",
  //       },
  //       {
  //         headers: { Authorization: `Bearer ${authToken}` },
  //       }
  //     );
  //     alert(result.data.message);
  //     await getCompanyDetails();
  //   } catch (error) {
  //     await getCompanyDetails();
  //     console.log(error);
  //   } finally {
  //     setLoading(false);
  //   }
  // }

  return (
    <>
      <br />
      <CContainer>
        <div class="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <CButton
              className="APP-BACKGROUND"
              onClick={() => {
                navigate("/home/company/create");
              }}
            >
              <CIcon icon={cilArrowCircleLeft} />
            </CButton>
            <div style={{ marginLeft: '25px', fontSize: '20px' }}><strong>{headerTitle}</strong></div>
          </div>
          <div className="d-flex justify-content-end">
            {/* <div className="ml-auto text-right">
              <CButton
                color="info"
                disabled={loading}
                onClick={() => makeCompanySyncAsync()}
              >
                {!loading ? `Change To SYNC Flow` : `Loading...`}
              </CButton>
              <br />
            </div> */}
            {(companyType === "PARAKH_APMC" || companyType === "PARAKH_PACS") && (
              <>
                <div className="ml-auto text-right" style={{ marginLeft: '25px' }}>
                  <CButton
                    className="APP-BACKGROUND"
                    onClick={() => {
                      navigate("/home/company/parakh/manage/" + companyId);
                    }}
                  >
                    Manage Parakh
                  </CButton>
                  <br />
                </div>
              </>
            )}
          </div>
        </div>
        <br />
        <CRow>
          <CCol>
            <CompanyUpdate />
          </CCol>
          <CCol>
            <CompanyUserApproval isRefreshUserData={isRefreshUserData} setIsRefreshUserData={setIsRefreshUserData}/>
          </CCol>
        </CRow>
        <br />
        <CRow>
          <CCol>
            <PaymentHistory setIsRefreshAccessPayment={setIsRefreshAccessPayment}/>
          </CCol>
        </CRow>
        <br />
        <PaymentAccessHistory isRefreshAccessPayment={isRefreshAccessPayment} setIsRefreshAccessPayment={setIsRefreshAccessPayment}/>
        <br />
        <CRow>
          <CCol>
            <CompanyAddCustomInput setIsRefreshCustomInputData={setIsRefreshCustomInputData}/>
          </CCol>
          <CCol>
            <CompanyGetCustomInput isRefreshCustomInputData={isRefreshCustomInputData} setIsRefreshCustomInputData={setIsRefreshCustomInputData}/>
          </CCol>
        </CRow>
        <br />
        <CRow>
          <CCol>
            <WebhookDetails />
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CompanyAttachCrops />
          </CCol>
          <CCol>
            <CompanyAttachMachines />
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CompanyAddCompanyMetadata setIsFreshMetaDataData={setIsFreshMetaDataData} />
          </CCol>
          <CCol>
            <CompanyGetCompanyMetadata isFreshMetaDataData={isFreshMetaDataData} setIsFreshMetaDataData={setIsFreshMetaDataData}/>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CompanyCreateOrUpdateByEmail setIsRefreshUserData={setIsRefreshUserData} />
          </CCol>
          <CCol>
            <CompanyCreateOrUpdateByPhone setIsRefreshUserData={setIsRefreshUserData}/>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CompanyCreateOrUpdateByPassword setIsRefreshUserData={setIsRefreshUserData}/>
          </CCol>
        </CRow>
        <CRow>
          <CompanyDefaultClassConfigurations />
        </CRow>
        <CRow>
          <CompanyDefaultResponseConfigurations />
        </CRow>
      </CContainer>
    </>
  );
};

export default CompanyInfo;
