import { CModal, CSpinner, CModalHeader, CModalTitle, CModalBody, CButton, CModalFooter } from "@coreui/react";

const AppMessageModal = ({ title, message, handleClose, isOpen, loading }) => {

    return (
        <CModal alignment="center" visible={isOpen} onClose={() => handleClose()}>
            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100px' }}>
                    <CSpinner />
                </div>
            ) : (
                <>
                    <CModalHeader onClose={() => handleClose()}>
                        <CModalTitle><h3><strong>{title}</strong></h3></CModalTitle>
                    </CModalHeader>
                    <CModalBody><h5>{message}</h5></CModalBody>
                    <CModalFooter>
                        <CButton color="warning" onClick={() => handleClose()}>Close</CButton>
                    </CModalFooter>
                </>
            )}
        </CModal>
    );
}

export default AppMessageModal;