import {
    CCol,
    CContainer,
    CRow,
    CNavbar,
    CNavbarBrand,
    CButton,
  } from "@coreui/react";
  import React, { useState, useEffect } from "react";
  import AllEnam from "./Components/AllEnam";
  
  const enamLayout = () => {
    return (
      <CContainer>
        <CRow>
          <CCol>
            <AllEnam />
          </CCol>
        </CRow>
      </CContainer>
    );
  };
  
  export default enamLayout;