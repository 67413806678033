import React, { useEffect, useState } from "react";
import {
  CCard,
  CCardBody,
  CCardImage,
  CButton,
  CCardTitle,
  CCardText,
  CRow,
  CCol,
  CSpinner,
} from "@coreui/react";
import { auth } from "../../../firebase";
import axios from "axios";
import { CChart } from "@coreui/react-chartjs";
import moment from "moment/moment";

const Home = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getHomePageData();
  }, [])

  async function getHomePageData() {
    try {
      setLoading(true);
      await new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          } else {
            reject(new Error("User is not authenticated."));
          }
        });
      });

      let authToken = await auth.currentUser.getIdToken();
      let result = await axios.get(
        window.config.commonEndpoint + "input/home_page_data",
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "User-Timezone": Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        }
      );
      console.log(result.data.data);
      setData(result.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  async function clearCache() {
    try {
      setLoading(true);
      await new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          } else {
            reject(new Error("User is not authenticated."));
          }
        });
      });

      let authToken = await auth.currentUser.getIdToken();
      let result = await axios.get(
        window.config.commonEndpoint + "input/clear_cache",
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "User-Timezone": Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        }
      );
      console.log(result.data);
      alert(result.data.message)
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      alert(error.message)
    }
  }

  return (
    <>
      {loading ? <center><CSpinner color="dark" /></center> : <div className="m-5">
      <CButton
            size="lg"
            className="mb-3 APP-BACKGROUND"
            onClick={() => clearCache()}
          >
            Delete Cache
          </CButton>
        <CRow>
          <CCol>
            <CCard>
              {/* <CCardImage orientation="top" src={ReactImg} /> */}
              <CCardBody>
                <CCardTitle>
                  <h1>Environment</h1>
                </CCardTitle>
                <CCardText>
                  <h5>{window.config.env.toUpperCase()}</h5>
                </CCardText>
              </CCardBody>
            </CCard>
          </CCol>
          <CCol>
            <CCard>
              {/* <CCardImage orientation="top" src={ReactImg} /> */}
              <CCardBody>
                <CCardTitle>
                  <h1>Companies</h1>
                </CCardTitle>
                <CCardText>
                  <h5>{data?.totalCompanies}</h5>
                </CCardText>
              </CCardBody>
            </CCard>
          </CCol>
          <CCol>
            <CCard>
              {/* <CCardImage orientation="top" src={ReactImg} /> */}
              <CCardBody>
                <CCardTitle>
                  <h1>Crops</h1>
                </CCardTitle>
                <CCardText>
                  <h5>{data?.totalCrops}</h5>
                </CCardText>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
        <br></br>
        <CRow>
          <CCol>
            <CCard>
              {/* <CCardImage orientation="top" src={ReactImg} /> */}
              <CCardBody>
                <CCardTitle>
                  <h1>Scans</h1>
                </CCardTitle>
                <CCardText>
                  <h5>Complete: {data?.scanResults?.totalCompletedScans} | Incomplete: {data?.scanResults?.totalIncompleteScans}</h5>
                </CCardText>
              </CCardBody>
            </CCard>
          </CCol>
          <CCol>
            <CCard>
              {/* <CCardImage orientation="top" src={ReactImg} /> */}
              <CCardBody>
                <CCardTitle>
                  <h1>Machines</h1>
                </CCardTitle>
                <CCardText>
                  <h5>{data?.totalMachines}</h5>
                </CCardText>
              </CCardBody>
            </CCard>
          </CCol>
          <CCol>
            <CCard>
              {/* <CCardImage orientation="top" src={ReactImg} /> */}
              <CCardBody>
                <CCardTitle>
                  <h1>Users</h1>
                </CCardTitle>
                <CCardText>
                  <h5>{data?.totalUsers}</h5>
                </CCardText>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
        <hr></hr>
        <h4 className="APP-COLOR">Scans for Today - {data?.scanResults?.totalScansCompletedTodayData?.reduce((total, curr) => total + curr.counts, 0)}</h4>
        <CChart
          type="line"
          data={{
            labels: data?.scanResults?.totalScansCompletedTodayData?.map((item) => moment(item.hours, 'HH').format('hh:mm A')),
            datasets: [
              {
                label: "Scans Completed",
                backgroundColor: "rgba(151, 187, 205, 0.2)",
                borderColor: "rgba(151, 187, 205, 1)",
                pointBackgroundColor: "rgba(151, 187, 205, 1)",
                pointBorderColor: "#fff",
                data: data?.scanResults?.totalScansCompletedTodayData?.map((item) => item.counts),
              }
            ],
          }}
          options={{
            scales: {
              yAxes: [{
                ticks: {
                  beginAtZero: true
                }
              }]
            }
          }}
        />
      </div>}
    </>
  );
};

export default Home;
