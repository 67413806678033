import React, { useEffect, useState } from "react";
import { auth } from "../../../firebase";
import axios from "axios";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCardTitle,
  CCol,
  CRow,
  CSpinner,
  CTable,
  CTableBody,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CFormInput,
  CForm,
  CContainer,
  CFormLabel,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
} from "@coreui/react";
import { cilReload } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import AppConfirmModal from "../../../components/AppConfirmModal";
import AppMessageModal from "../../../components/AppMessageModal";
import "./ManageRoles.css";
import GetUserRole from "./Components/GetUserRole";

const ManageRoles = () => {
  const [loading, setLoading] = useState(false);
  const [userList, setUserList] = useState(undefined);
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");
  const [confirmModal, setConfirmModal] = useState(false);
  const [messageModal, setMessageModal] = useState(false);
  const [activeFirebaseID, setActiveFirebaseID] = useState(undefined);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredUserList, setFilteredUserList] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [roleCode, setRoleCode] = useState("");
  const [appType, setAppType] = useState("");

  useEffect(() => {
    getUserList();
  }, []);

  useEffect(() => {
    handleSearch();
  }, [searchTerm]);


  async function getUserList() {
    setUserList(undefined);
    setLoading(true);
    try {
      await new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          } else {
            reject(new Error("User is not authenticated."));
          }
        });
      });

      let authToken = await auth.currentUser.getIdToken();
      let result = await axios.get(
        window.config.commonEndpoint + "input/all_users",
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      setFilteredUserList(result.data.data.users);
      setUserList(result.data.data.users);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  async function addUserRole() {
    if (selectedUser === "") {
      setTitle("Error");
      setMessage("Select a user first");
      setMessageModal(true);
      return;
    }
    if (roleCode === "") {
      setTitle("Error");
      setMessage("Select a role");
      setMessageModal(true);
      return;
    }
    if (appType === "") {
      setTitle("Error");
      setMessage("Select a app type");
      setMessageModal(true);
      return;
    }
    setLoading(true);
    try {
      await new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          } else {
            reject(new Error("User is not authenticated."));
          }
        });
      });

      console.log({
        firebase_user_id: selectedUser.firebase_user_id,
        role_code: roleCode,
        app_type: appType,
      });

      let authToken = await auth.currentUser.getIdToken();
      let result = await axios.post(
        window.config.commonEndpoint + "input/add_user_role",
        {
          firebase_user_id: selectedUser.firebase_user_id,
          role_code: roleCode,
          app_type: appType,
        },
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      console.log(result.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  const handleSearch = () => {
    if (userList) {
      const filteredUserList = userList.filter((user) => {
        const name = `${user.first_name || ""} ${user.last_name || ""}`;
        return (
          name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          (user.email &&
            user.email.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (user.phone_number && user.phone_number.includes(searchTerm)) ||
          user.user_type.toLowerCase().includes(searchTerm.toLowerCase())
        );
      });
      setFilteredUserList(filteredUserList);
    }
  };

  const handleUserSelection = (user) => {
    setSelectedUser(user);
    setModalVisible(false);
  };

  return (
    <>
      <CCard>
        <CCardHeader>
          <CCardTitle>Select User</CCardTitle>
          <CButton color="primary" className="APP-BACKGROUND" onClick={() => setModalVisible(true)}>
            Open User List
          </CButton>
          {selectedUser ? (
            <div>
              <strong>Selected User:</strong> {selectedUser.first_name}{" "}
              {selectedUser.last_name} --- {selectedUser.phone_number}{" "}
              {selectedUser.email}
            </div>
          ) : (
            <div>No user selected</div>
          )}
        </CCardHeader>
        <CCardBody>
          <CContainer>
            <CRow>
              <CCol>
                <CFormLabel>
                  <strong>Role Code</strong>
                </CFormLabel>
                <br />
                <CDropdown>
                  <CDropdownToggle color="secondary">
                    {roleCode ? roleCode : "Select Role Code"}
                  </CDropdownToggle>
                  <CDropdownMenu>
                    <CDropdownItem onClick={() => setRoleCode("L0")}>
                      L0
                    </CDropdownItem>
                    <CDropdownItem onClick={() => setRoleCode("L1")}>
                      L1
                    </CDropdownItem>
                    <CDropdownItem onClick={() => setRoleCode("L2")}>
                      L2
                    </CDropdownItem>
                  </CDropdownMenu>
                </CDropdown>
              </CCol>
              <CCol>
                <CFormLabel>
                  <strong>App Type</strong>
                </CFormLabel>
                <br />
                <CDropdown>
                  <CDropdownToggle color="secondary">
                    {appType ? appType : "Select App Type"}
                  </CDropdownToggle>
                  <CDropdownMenu>
                    <CDropdownItem onClick={() => setAppType("INPUT")}>
                      INPUT
                    </CDropdownItem>
                    <CDropdownItem onClick={() => setAppType("DASHBOARD")}>
                      DASHBOARD
                    </CDropdownItem>
                    <CDropdownItem onClick={() => setAppType("REVIEW")}>
                      REVIEW
                    </CDropdownItem>
                    <CDropdownItem onClick={() => setAppType("APP")}>
                      APP
                    </CDropdownItem>
                  </CDropdownMenu>
                </CDropdown>
              </CCol>
            </CRow>
            <br />

            <CButton disabled={loading} color="success" className="text-white" onClick={addUserRole}>
              {loading ? <CSpinner color="light" /> : "Add Role"}
            </CButton>
          </CContainer>
        </CCardBody>
      </CCard>
      <br />
      <GetUserRole />

      {/* User List Modal */}
      {modalVisible && (
        <div className="user-list-modal">
          <div className="user-list-modal-header">
            <h6>Select User</h6>
            <CFormInput
              className="search-input"
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={(event) => setSearchTerm(event.target.value)}
            />
            <CButton color="secondary" onClick={() => setModalVisible(false)}>
              Close
            </CButton>
          </div>
          <div className="user-list-modal-content">
            {filteredUserList.map((user, index) => (
              <div
                key={index}
                className="user-item"
                onClick={() => handleUserSelection(user)}
              >
                <div>{`${user.first_name} ${user.last_name} `}</div>
                <div>{user.phone_number}</div>
                <div>{user.email}</div>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Confirm Modal */}
      <AppConfirmModal
        isOpen={confirmModal}
        onCancel={() => setConfirmModal(false)}
        // onConfirm={title === "Delete User" ? deleteUser : null}
        title={title}
        message={message}
      />

      {/* Message Modal */}
      <AppMessageModal
        isOpen={messageModal}
        handleClose={() => setMessageModal(false)}
        title={title}
        message={message}
      />
    </>
  );
};

export default ManageRoles;
