import React, { useEffect, useState } from "react";
import { auth } from "../../../../firebase";
import axios from "axios";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCardTitle,
  CCol,
  CRow,
  CSpinner,
  CTable,
  CTableBody,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CFormInput,
  CForm,
} from "@coreui/react";
import { cilReload } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import AppConfirmModal from "../../../../components/AppConfirmModal";
import AppMessageModal from "../../../../components/AppMessageModal";

const CompanyUserApproval = ({isRefreshUserData, setIsRefreshUserData}) => {
  //States
  const [loading, setLoading] = useState(false);
  const [userList, setUserList] = useState(undefined);
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");
  const [confirmModal, setConfirmModal] = useState(false);
  const [messageModal, setMessageModal] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [activeCompany, setActiveCompany] = useState(undefined);
  const [activeFirebaseID, setActiveFirebaseID] = useState(undefined);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredUserList, setFilteredUserList] = useState([]);

  //Functions
  useEffect(() => {
    getUserList();
  }, []);

  useEffect(() => {
    if (isRefreshUserData) {
      getUserList(); 
      setIsRefreshUserData(false)
    }
  }, [isRefreshUserData]);

  useEffect(() => {
    handleSearch();
  }, [searchTerm]);

  async function getUserList() {
    setUserList(undefined);
    setLoading(true);
    let company_id = window.location.href.split("/").pop();
    try {
      await new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          } else {
            reject(new Error("User is not authenticated."));
          }
        });
      });

      let authToken = await auth.currentUser.getIdToken();
      let result = await axios.post(
        window.config.commonEndpoint + "company/get/users",
        { company_id: company_id },
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      setUserList(result.data.data.users);
      setFilteredUserList(result.data.data.users);
      // console.log(result.data.data.users);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  function handleApproveUser(firebaseID, companyID) {
    setActiveFirebaseID(firebaseID);
    setActiveCompany(companyID);
    setMessage("Are you sure you want to approve this user?");
    setTitle("Approve User");
    setConfirmModal(true);
  }

  function handleRejectUser(firebaseID, companyID) {
    setActiveFirebaseID(firebaseID);
    setActiveCompany(companyID);
    setMessage("Are you sure you want to reject this user?");
    setTitle("Reject User");
    setConfirmModal(true);
  }

  async function approveUser() {
    setApproveLoading(true);
    setConfirmModal(false);
    try {
      await new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          } else {
            reject(new Error("User is not authenticated."));
          }
        });
      });

      let authToken = await auth.currentUser.getIdToken();
      let result = await axios.post(
        window.config.commonEndpoint + "company/verify/user",
        { firebase_user_id: activeFirebaseID, company_id: activeCompany },
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      if (result.status === 200) {
        setMessage("User approved successfully.");
        setTitle("Success");
        setMessageModal(true);
        setApproveLoading(false);
      }
      console.log(result);
      getUserList();
    } catch (error) {
      setMessage("Something went wrong. Please try again later.");
      setTitle("Error");
      setMessageModal(true);
      setApproveLoading(false);
    }
  }

  async function rejectUser() {
    setRejectLoading(true);
    setConfirmModal(false);
    try {
      await new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          } else {
            reject(new Error("User is not authenticated."));
          }
        });
      });

      let authToken = await auth.currentUser.getIdToken();
      let result = await axios.post(
        window.config.commonEndpoint + "company/unverify/user",
        { firebase_user_id: activeFirebaseID, company_id: activeCompany },
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      if (result.status === 200) {
        setMessage("User rejected successfully.");
        setTitle("Success");
        setMessageModal(true);
        setRejectLoading(false);
      }
      console.log(result);
      getUserList();
    } catch (error) {
      setMessage("Something went wrong. Please try again later.");
      setTitle("Error");
      setMessageModal(true);
      setRejectLoading(false);
    }
  }

  const handleSearch = () => {
    if (userList) {
      const filteredUserList = userList.filter((user) => {
        console.log(user);
        const name = `${user.first_name || ""} ${user.last_name || ""}`;
        return (
          name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          (user.email &&
            user.email.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (user.phone_number && user.phone_number.includes(searchTerm))
        );
      });
      setFilteredUserList(filteredUserList);
    }
  };

  return (
    <>
      <div>
        <CCard>
          <CCardHeader>
            <CRow className="justify-items-between">
              <CCol xs={7}>
                <CCardTitle>
                  <h3>
                    <strong>Users Approval List</strong>
                  </h3>
                </CCardTitle>
              </CCol>
              <CCol xs={2}>
                <CButton color="light" onClick={() => getUserList()}>
                  <CIcon size="lg" icon={cilReload} />
                </CButton>
              </CCol>
              <CCol xs={3}>
                <CCol>
                  <CFormInput
                    className="mx-2"
                    type="text"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={(event) => setSearchTerm(event.target.value)}
                  />
                </CCol>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <div style={{ maxHeight: "360px", overflowY: "auto" }}>
              <CTable align="middle">
                <CTableHead>
                  <CTableRow>
                    <CTableHeaderCell>S.No</CTableHeaderCell>
                    <CTableHeaderCell>Name</CTableHeaderCell>
                    <CTableHeaderCell>Email/Phone</CTableHeaderCell>
                    <CTableHeaderCell>Action</CTableHeaderCell>
                    {/* <CTableHeaderCell>-</CTableHeaderCell> */}
                  </CTableRow>
                </CTableHead>
                {loading ? (
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
                  <CSpinner color="dark" />
                </div>
                ) : (
                  <CTableBody>
                    {filteredUserList === undefined ||
                      filteredUserList.length === 0 ? (
                      <CTableHeaderCell>No data to display</CTableHeaderCell>
                    ) : (
                      filteredUserList.map((user, index) => {
                        return (
                          <CTableRow key={index}>
                            <CTableHeaderCell>{index + 1}</CTableHeaderCell>
                            <CTableHeaderCell>
                              {user.first_name + " " + user.last_name}
                            </CTableHeaderCell>
                            <CTableHeaderCell>
                              {user.email === null
                                ? user.phone_number
                                : `${user.email} ${user.phone_number ? ' / ' + user.phone_number : ''}`}
                            </CTableHeaderCell>
                            {user.verified ? (
                              <CTableHeaderCell>
                                <CButton
                                  className="APP-BACKGROUND-2 APP-BORDER"
                                  onClick={() =>
                                    handleRejectUser(
                                      user.firebase_user_id,
                                      user.company_id
                                    )
                                  }
                                >
                                  {rejectLoading ? (
                                    <CSpinner color="light" />
                                  ) : (
                                    "Reject"
                                  )}
                                </CButton>
                              </CTableHeaderCell>
                            ) : (
                              <CTableHeaderCell>
                                <CButton
                                  className="APP-BACKGROUND APP-BORDER-2"
                                  onClick={() =>
                                    handleApproveUser(
                                      user.firebase_user_id,
                                      user.company_id
                                    )
                                  }
                                >
                                  {approveLoading ? (
                                    <CSpinner color="light" />
                                  ) : (
                                    "Approve"
                                  )}
                                </CButton>
                              </CTableHeaderCell>
                            )}
                            {/* <CTableHeaderCell>
                            <CButton color="danger" onClick={() => {console.log("delete clicked")}}>
                              Delete
                            </CButton>
                          </CTableHeaderCell> */}
                          </CTableRow>
                        );
                      })
                    )}
                  </CTableBody>
                )}
              </CTable>
            </div>
          </CCardBody>
        </CCard>
        {/* Confirm Modal */}
        <AppConfirmModal
          isOpen={confirmModal}
          onCancel={() => setConfirmModal(false)}
          onConfirm={
            title === "Approve User" ? () => approveUser() : () => rejectUser()
          }
          title={title}
          message={message}
        />
        {/* Message Modal */}
        <AppMessageModal
          isOpen={messageModal}
          handleClose={() => setMessageModal(false)}
          title={title}
          message={message}
        />
      </div>
    </>
  );
};

export default CompanyUserApproval;
