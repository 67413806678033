import React, { useState, useEffect } from "react";
import { auth } from "../../../../firebase";
import axios from "axios";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CSpinner,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CCol,
  CRow,
  CCardTitle,
  CImage,
  CFormSwitch,
  CFormLabel,
  CFormInput,
  CFormCheck,
} from "@coreui/react";
import { cilReload } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import AppConfirmModal from "../../../../components/AppConfirmModal";
import AppMessageModal from "../../../../components/AppMessageModal";

const PaymentHistory = ({ setIsRefreshAccessPayment }) => {
  //States
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [calculateLoading, setCaculateLoading] = useState(false);
  const [invoiceLoading, setInvoiceLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");
  const [confirmModal, setConfirmModal] = useState(false);
  const [messageModal, setMessageModal] = useState(false);
  const [paymentHistory, setPaymentHistory] = useState(undefined);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [paymentGst, setPaymentGst] = useState(0);
  const [paymentTotal, setPaymentTotal] = useState(0);
  const [paymentDescription, setPaymentDescription] = useState("");
  const [noOfScans, setNoOfScans] = useState(0);
  const [unlimitedScans, setUnlimitedScans] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [isAccessPayment, setIsAccessPayment] = useState(true);

  //Functions
  useEffect(() => {
    getPaymentHistory();
  }, []);

  // useEffect(() => {
  //   // calculatePayment();
  // }, [paymentAmount]);

  async function getPaymentHistory() {
    setLoading(true);
    let company_id = window.location.href.split("/").pop();
    try {
      await new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          } else {
            reject(new Error("User is not authenticated."));
          }
        });
      });

      let authToken = await auth.currentUser.getIdToken();
      let result = await axios.post(
        window.config.commonEndpoint + "company/payment/history",
        { company_id: company_id },
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      setPaymentHistory(result.data.data.paymentHistory);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  async function addAccessPayment() {
    if (startDate === '' || endDate === '' || (!unlimitedScans && noOfScans === 0)) {
      setMessage('Please fill all the fields');
      setTitle('Error');
      setMessageModal(true);
      return;
    }
    setButtonLoading(true);
    let company_id = window.location.href.split("/").pop();
    try {
      await calculatePayment();
      await new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          } else {
            reject(new Error("User is not authenticated."));
          }
        });
      });

      let authToken = await auth.currentUser.getIdToken();
      let result = await axios.post(
        window.config.commonEndpoint + "company/payment/access",
        {
          company_id: company_id,
          start_date: startDate,
          end_date: endDate,
          payment_amount: paymentAmount,
          payment_gst: paymentGst,
          payment_description: paymentDescription,
          number_of_scans: noOfScans,
          unlimited_scans: unlimitedScans,
        },
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      if (result.status === 200) {
        setTitle("Success");
        setMessage("Payment created successfully");
        setMessageModal(true);
        setPaymentAmount(0);
        setPaymentGst(0);
        setPaymentTotal(0);
        setPaymentDescription("");
        setStartDate("");
        setEndDate("");
        getPaymentHistory()
        setIsRefreshAccessPayment(true)
      }
    } catch (error) {
      setMessage(error.response.data.message);
      setTitle('Error');
      setMessageModal(true);
    } finally {
      setButtonLoading(false);
    }
  }

  async function addNormalPayment() {
    setButtonLoading(true);
    let company_id = window.location.href.split("/").pop();
    try {
      await calculatePayment();
      await new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          } else {
            reject(new Error("User is not authenticated."));
          }
        });
      });

      let authToken = await auth.currentUser.getIdToken();
      let result = await axios.post(
        window.config.commonEndpoint + "company/payment/others",
        {
          company_id: company_id,
          payment_amount: paymentAmount,
          payment_gst: paymentGst,
          payment_description: paymentDescription,
        },
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      if (result.status === 200) {
        setTitle("Success");
        setMessage("Payment created successfully");
        setMessageModal(true);
        setPaymentAmount(0);
        setPaymentGst(0);
        setPaymentTotal(0);
        setPaymentDescription("");
        getPaymentHistory()
        setIsRefreshAccessPayment(true)
      }
    } catch (error) {
      setMessage(error.response.data.message);
      setTitle('Error');
      setMessageModal(true);
    } finally {
      setButtonLoading(false);
    }
  }

  async function calculatePayment() {
    setCaculateLoading(true);
    let company_id = window.location.href.split("/").pop();
    try {
      await new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          } else {
            reject(new Error("User is not authenticated."));
          }
        });
      });

      let authToken = await auth.currentUser.getIdToken();
      let result = await axios.post(
        window.config.commonEndpoint + "company/payment/calculate",
        {
          payment_amount: paymentAmount,
        },
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      setCaculateLoading(false);
      // console.log(result.data.data);
      setPaymentTotal(result.data.data.total_payment);
      setPaymentGst(result.data.data.payment_gst);
    } catch (error) {
      setCaculateLoading(false);
    }
  }

  async function getInvoice(payment_id) {
    let company_id = window.location.href.split("/").pop();
    try {
      setInvoiceLoading(true);
      await new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          } else {
            reject(new Error("User is not authenticated."));
          }
        });
      });

      let authToken = await auth.currentUser.getIdToken();
      let result = await axios.post(
        window.config.commonEndpoint + "company/generate_invoice",
        {
          company_id: company_id,
          payment_id: payment_id,
        },
        { headers: { Authorization: `Bearer ${authToken}` }, responseType: "blob" }
      );
      setInvoiceLoading(false);
      window.open(URL.createObjectURL(result.data));
    } catch (error) {
      console.log(error);
      setInvoiceLoading(false);
    }
  }

  return (
    <CRow>
      <CCol>
        <CCard>
          <CCardHeader>
            <CRow className="justify-items-between">
              <CCol xs={7}>
                <CCardTitle>
                  <h3>
                    <strong>Payments</strong>
                  </h3>
                </CCardTitle>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CFormLabel>
                <strong>Select The Payment Method</strong>
              </CFormLabel>
            </CRow>
            <CRow>
              <CCol>
                <CFormCheck
                  type="radio"
                  name="accessPayment"
                  id="accessPayment"
                  label="Access Payment"
                  onChange={() => setIsAccessPayment(true)}
                  checked={isAccessPayment}
                />
              </CCol>
              <CCol>
                <CFormCheck
                  type="radio"
                  name="normalPayment"
                  id="normalPayment"
                  label="Normal Payment"
                  onChange={() => setIsAccessPayment(false)}
                  checked={!isAccessPayment}
                />
              </CCol>
            </CRow>
            <br />
            <br />
            {isAccessPayment ? (
              <>
                <CRow>
                  <CCol>
                    <CFormLabel>
                      <strong>Amount</strong>
                    </CFormLabel>
                    <CFormInput
                      type="number"
                      value={paymentAmount}
                      onChange={(e) =>
                        setPaymentAmount(parseFloat(e.target.value))
                      }
                      placeholder="Enter Payment Amount"
                    />
                  </CCol>
                  <CCol>
                    <CFormLabel>
                      <strong>GST</strong>
                    </CFormLabel>
                    <CFormInput
                      type="number"
                      value={paymentGst}
                      placeholder="GST Amount"
                      disabled
                    />
                  </CCol>
                  <CCol>
                    <CFormLabel>
                      <strong>Total</strong>
                    </CFormLabel>
                    <CFormInput
                      type="number"
                      value={paymentTotal}
                      placeholder="Total Amount"
                      disabled
                    />
                  </CCol>
                </CRow>
                <br />
                <CRow>
                  <CCol>
                    <CButton
                      style={{ marginRight: "10px" }}
                      className={paymentAmount ? "APP-BACKGROUND APP-BORDER-2" : "APP-FADE-BACKGROUND APP-FADE-BORDER"}
                      onClick={calculatePayment}
                      disabled={calculateLoading}
                    >
                      {calculateLoading ? (
                        <CSpinner color="dark" />
                      ) : (
                        "Calculate"
                      )}
                    </CButton>
                  </CCol>
                </CRow>
                <br />
                <CRow>
                  <CCol>
                    <CFormLabel>
                      <strong>Payment Description</strong>
                    </CFormLabel>
                    <CFormInput
                      type="text"
                      value={paymentDescription}
                      placeholder="payment description"
                      onChange={(e) => setPaymentDescription(e.target.value)}
                    />
                  </CCol>
                  <CCol>
                    <CFormLabel>
                      <strong>Scans</strong><span style={{ color: 'red' }}>*</span>
                    </CFormLabel>
                    <div className="d-flex">
                      <CFormSwitch
                        label="Unlimited?"
                        className="mx-2"
                        checked={unlimitedScans}
                        onChange={(e) => {
                          setUnlimitedScans(e.target.checked);
                          setNoOfScans(0);
                        }}
                      />
                    </div>
                  </CCol>
                  <CCol>
                    <CFormLabel>
                      <strong>No of scans</strong><span style={{ color: 'red' }}>*</span>
                    </CFormLabel>
                    <CFormInput
                      type="number"
                      className="mx-2"
                      value={noOfScans}
                      placeholder="No of scans"
                      onChange={(e) => setNoOfScans(parseFloat(e.target.value))}
                      disabled={unlimitedScans}
                    />
                  </CCol>
                </CRow>
                <br />
                <CRow>
                  <CCol>
                    <CFormLabel>Start Date<span style={{ color: 'red' }}>*</span>:</CFormLabel>
                    <CFormInput
                      type="date"
                      required
                      name="startDate"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </CCol>
                  <CCol>
                    <CFormLabel>End Date<span style={{ color: 'red' }}>*</span>:</CFormLabel>
                    <CFormInput
                      type="date"
                      required
                      name="endDate"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </CCol>
                </CRow>
                <br />
                <CButton
                  style={{ marginRight: "10px" }}
                  className={(paymentAmount && startDate && endDate) ? "APP-BACKGROUND APP-BORDER-2" : "APP-FADE-BACKGROUND APP-FADE-BORDER"}
                  onClick={addAccessPayment}
                  disabled={buttonLoading || !paymentAmount || !startDate || !endDate}
                >
                  {buttonLoading ? <CSpinner color="dark" /> : "Add Payment"}
                </CButton>
              </>
            ) : (
              <>
                <CRow>
                  <CCol>
                    <CFormLabel>
                      <strong>Amount</strong>
                    </CFormLabel>
                    <CFormInput
                      type="number"
                      value={paymentAmount}
                      onChange={(e) =>
                        setPaymentAmount(parseFloat(e.target.value))
                      }
                      placeholder="Enter Payment Amount"
                    />
                  </CCol>
                  <CCol>
                    <CFormLabel>
                      <strong>GST</strong>
                    </CFormLabel>
                    <CFormInput
                      type="number"
                      value={paymentGst}
                      placeholder="GST Amount"
                      disabled
                    />
                  </CCol>
                  <CCol>
                    <CFormLabel>
                      <strong>Total</strong>
                    </CFormLabel>
                    <CFormInput
                      type="number"
                      value={paymentTotal}
                      placeholder="Total Amount"
                      disabled
                    />
                  </CCol>
                </CRow>
                <br />
                <CRow>
                  <CCol>
                    <CButton
                      style={{ marginRight: "10px" }}
                      className={paymentAmount ? "APP-BACKGROUND APP-BORDER-2" : "APP-FADE-BACKGROUND APP-FADE-BORDER"}
                      onClick={calculatePayment}
                      disabled={calculateLoading}
                    >
                      {calculateLoading ? (
                        <CSpinner color="dark" />
                      ) : (
                        "Calculate"
                      )}
                    </CButton>
                  </CCol>
                </CRow>
                <br />
                <CRow>
                  <CCol>
                    <CFormLabel>
                      <strong>Payment Description</strong>
                    </CFormLabel>
                    <CFormInput
                      type="text"
                      value={paymentDescription}
                      placeholder="payment description"
                      onChange={(e) => setPaymentDescription(e.target.value)}
                    />
                  </CCol>
                </CRow>
                <br />
                <CButton
                  style={{ marginRight: "10px" }}
                  color="primary"
                  onClick={addNormalPayment}
                  disabled={buttonLoading}
                >
                  {buttonLoading ? <CSpinner color="dark" /> : "Add Payment"}
                </CButton>
              </>
            )}
          </CCardBody>
        </CCard>
      </CCol>
      <br />
      <CCol>
        <CCard>
          <CCardHeader>
            <CCardTitle>
              <CRow>
                <CCol>
                  <h3>
                    <strong>Payments History</strong>
                  </h3>
                </CCol>
                <CCol>
                  <CButton color="light" onClick={() => getPaymentHistory()}>
                    <CIcon size="lg" icon={cilReload} />
                  </CButton>
                </CCol>
              </CRow>
            </CCardTitle>
          </CCardHeader>
          <CCardBody>
            {loading ? (
              <CSpinner color="dark" />
            ) : (
              <div style={{ height: isAccessPayment ? "480px": "380px", overflowY: "auto" }}>
                <CTable align="middle">
                  <CTableHead>
                    <CTableRow>
                      <CTableHeaderCell>S.No</CTableHeaderCell>
                      <CTableHeaderCell>Description</CTableHeaderCell>
                      <CTableHeaderCell>Payment Amount</CTableHeaderCell>
                      <CTableHeaderCell>Payment GST</CTableHeaderCell>
                      <CTableHeaderCell>Payment Date</CTableHeaderCell>
                      <CTableHeaderCell>Total Amount</CTableHeaderCell>
                      <CTableHeaderCell>-</CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>
                  {loading ? (
                    <CSpinner color="dark" />
                  ) : (
                    <CTableBody>
                      {paymentHistory === undefined ||
                        paymentHistory.length === 0 ? (
                        <CTableHeaderCell>No data to display</CTableHeaderCell>
                      ) : (
                        paymentHistory.map((payment, index) => {
                          return (
                            <CTableRow key={index}>
                              <CTableHeaderCell>{index + 1}</CTableHeaderCell>
                              <CTableDataCell>
                                {payment.payment_description}
                              </CTableDataCell>
                              <CTableDataCell>
                                ₹{payment.payment_amount}
                              </CTableDataCell>
                              <CTableDataCell>
                                ₹{payment.payment_gst}
                              </CTableDataCell>
                              <CTableDataCell>
                                {payment.payment_date.substring(0, 10)}
                              </CTableDataCell>
                              <CTableDataCell>
                                ₹{payment.payment_total}
                              </CTableDataCell>
                              <CTableDataCell>
                                <CButton color="success" onClick={async () => {
                                  await getInvoice(payment.id);
                                }}>
                                  {invoiceLoading ? <CSpinner color="dark" /> : "Invoice"}
                                </CButton>
                              </CTableDataCell>
                            </CTableRow>
                          );
                        })
                      )}
                    </CTableBody>
                  )}
                </CTable>
              </div>
            )}
            <AppMessageModal isOpen={messageModal} handleClose={() => setMessageModal(false)} title={title} message={message} />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default PaymentHistory;
