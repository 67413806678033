import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CCardTitle,
  CImage,
  CFormSwitch,
  CFormLabel,
  CFormInput,
  CCollapse,
  CSpinner,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
  CContainer,
} from "@coreui/react";
import moment from 'moment';
import React, { useState, useEffect } from "react";
import CIcon from "@coreui/icons-react";
import { cilReload } from "@coreui/icons";
//HTTP Libraries
import axios from "axios";
//Firebase
import { auth } from "../../../../firebase";
import AddVersion from "./AddVersions";
import AddCropWiseWeightConfiguration from "./AddCropWiseWeightConfiguration"
import AppMessageModal from "../../../../components/AppMessageModal";
import AppConfirmModal from "../../../../components/AppConfirmModal";
const GetMachineByCode = () => {
  const [loading, setLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [machine, setMachine] = useState([]);
  const [machineModal, setMachineModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [machineModalForConfig, setMachineModalForConfig] = useState(false);
  const [confirmModalForConfig, setConfirmModalForConfig] = useState(false);
  const [messageModal, setMessageModal] = useState(false);
  const [messagetitle, setMessageTitle] = useState('');
  const [message, setMessage] = useState('');
  const [title, setTitle] = useState('');
  const machine_code = window.location.href.split("/").pop();
  useEffect(() => {
    getMachinesByCode();
  }, []);

  async function getMachinesByCode() {
    try {
      await new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          } else {
            reject(new Error("User is not authenticated."));
          }
        });
      });

      let authToken = await auth.currentUser.getIdToken();
      let result = await axios.get(
        window.config.commonEndpoint + "machines/" + machine_code,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      //   console.log(result.data.data.machine);
      setMachine(result.data.data.machine);
    } catch (error) {
      console.log(error);
    }
  }
  function handleConfirm() {
    setMachineModal(false);
    // getMachineList();
  }
  function handleConfirmForConfig () {
    setConfirmModalForConfig(false)
  }
  return (
    <div>
      <AppMessageModal message={message} title={messagetitle} handleClose={() => setMessageModal(false)} isOpen={messageModal} />
      <AppConfirmModal isOpen={confirmModal} title={title} message={message} onCancel={() => setConfirmModal(false)} />
      <CCard>
        <CCardHeader>
          <CCardTitle>
            <CRow>
              <CCol>
                <h3>
                  <strong>Machine</strong>
                </h3>
              </CCol>
              <CCol xs={2}></CCol>
            </CRow>
          </CCardTitle>
        </CCardHeader>
        <CCardBody>
          {loading ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
              <CSpinner color="dark" />
            </div>
          ) : (
            <CTable align="middle">
              <CTableHead style={{ background: "aliceblue" }}>
                <CTableRow>
                  <CTableHeaderCell>Machine Name</CTableHeaderCell>
                  <CTableHeaderCell>Machine Code</CTableHeaderCell>
                  <CTableHeaderCell>Created On</CTableHeaderCell>
                </CTableRow>
              </CTableHead>

              {loading ? (
                <CSpinner color="dark" />
              ) : (
                <CTableBody>
                  <CTableRow>
                    <CTableDataCell>{machine.machine_name}</CTableDataCell>
                    <CTableDataCell>{machine.machine_code}</CTableDataCell>
                    <CTableDataCell>{moment(machine.created_at).format('DD MMM, YYYY hh:mm A')}</CTableDataCell>
                  </CTableRow>
                </CTableBody>
              )}
            </CTable>
          )}
        </CCardBody>
      </CCard>
      {machine_code === 'M5' &&
        <>
          <CCard style={{ marginTop: "40px" }}>
            <CCardHeader>
              <CCardTitle className="title-with-button">
                <h3>
                  <strong>Machine Version</strong>
                </h3>
                <CButton
                  style={{ marginRight: "10px" }}
                  color="primary"
                  onClick={() => setMachineModal(true)}
                >
                  ADD Version
                </CButton>
              </CCardTitle>
            </CCardHeader>
            <CCardBody>
              {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
                  <CSpinner color="dark" />
                </div>
              ) : (
                <CTable align="middle">
                  <CTableHead style={{ background: "aliceblue" }}>
                    <CTableRow>
                      <CTableHeaderCell>Machine Name</CTableHeaderCell>
                      <CTableHeaderCell>Machine Version</CTableHeaderCell>
                      <CTableHeaderCell>Actions</CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>

                  {loading ? (
                    <CSpinner color="dark" />
                  ) : (
                    <CTableBody>
                      <CTableRow>
                        <CTableDataCell>{machine.machine_name}</CTableDataCell>
                        <CTableDataCell>{machine.machine_version}</CTableDataCell>
                        <CTableDataCell>
                          <div style={{ display: 'flex', gap: '10px' }}>
                            <CButton
                              color="info"
                              size="sm"
                            // onClick={() => handleUpdate(row.id)} // Replace with your update function
                            >
                              Update
                            </CButton>
                            <CButton
                              color="danger"
                              size="sm"
                            // onClick={() => handleDelete(row.id)} // Replace with your delete function
                            >
                              Delete
                            </CButton>
                          </div>
                        </CTableDataCell>
                      </CTableRow>
                    </CTableBody>
                  )}
                </CTable>
              )}
            </CCardBody>
          </CCard>
          <CCard style={{ marginTop: "40px" }}>
            <CCardHeader>
              <CCardTitle className="title-with-button">
                <h3>
                  <strong>Crop Wise Standard Weight Configuration Section</strong>
                </h3>
                <CButton
                  style={{ marginRight: "10px" }}
                  color="primary"
                  onClick={() => setMachineModalForConfig(true)}
                >
                  ADD
                </CButton>
              </CCardTitle>
            </CCardHeader>
            <CCardBody>
              {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
                  <CSpinner color="dark" />
                </div>
              ) : (
                <CTable align="middle">
                  <CTableHead style={{ background: "aliceblue" }}>
                    <CTableRow>
                      <CTableHeaderCell>Crop</CTableHeaderCell>
                      <CTableHeaderCell>Min Weight Range</CTableHeaderCell>
                      <CTableHeaderCell>Max Weight Range</CTableHeaderCell>
                      <CTableHeaderCell>Version</CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>

                  {loading ? (
                    <CSpinner color="dark" />
                  ) : (
                    <CTableBody>
                      <CTableRow>
                        <CTableDataCell></CTableDataCell>
                        <CTableDataCell></CTableDataCell>
                        <CTableDataCell>
                        </CTableDataCell>
                        <CTableDataCell>
                        </CTableDataCell>
                      </CTableRow>
                    </CTableBody>
                  )}
                </CTable>
              )}
            </CCardBody>
          </CCard>
        </>
      }
      <AddVersion
        isOpen={machineModal}
        handleCancel={() => setMachineModal(false)}
        handleConfirm={() => handleConfirm()}
      />
      <AddCropWiseWeightConfiguration
       isOpen={machineModalForConfig}
       handleCancel={() => setMachineModalForConfig(false)}
       handleConfirm={() => handleConfirmForConfig()}
     />
      <br />
      <br />
    </div>
  );
};

export default GetMachineByCode;
