import { CButton, CCol, CFormInput, CFormLabel, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle, CRow } from "@coreui/react";
import React, { useState } from "react";
import AppMessageModal from "../../../../components/AppMessageModal";
import AppConfirmModal from "../../../../components/AppConfirmModal";
import { auth } from "../../../../firebase";
import axios from "axios";

const AddMachine = ({ isOpen, handleCancel, handleConfirm }) => {
    //States
    const [machineName, setMachineName] = useState('');
    const [machineCode, setMachineCode] = useState('');
    const [message, setMessage] = useState('');
    const [title, setTitle] = useState('');
    const [messageModal, setMessageModal] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);

    //Functions

    const handleMachineName = (e) => {
        setMachineName(e.target.value);
    }

    const handleMachineCode = (e) => {
        setMachineCode(e.target.value);
    }

    function handleMachineConfirm() {
        if (machineName === '') {
            setTitle('Error');
            setMessage('Machine Name cannot be empty');
            setMessageModal(true);
            return;
        }
        if (machineCode === '') {
            setTitle('Error');
            setMessage('Machine Code cannot be empty');
            setMessageModal(true);
            return;
        }
        setTitle('Confirm');
        setMessage('Are you sure you want to add this machine?');
        setConfirmModal(true);
    }

    async function createMachine() {
        try {
            await new Promise((resolve, reject) => {
                auth.onAuthStateChanged((user) => {
                  if (user) {
                    resolve(user);
                  } else {
                    reject(new Error("User is not authenticated."));
                  }
                });
              });
              
            let authToken = await auth.currentUser.getIdToken();
            let result = await axios.post(window.config.commonEndpoint + 'machines/create', { machine_name: machineName, machine_code: machineCode },
                { headers: { Authorization: `Bearer ${authToken}` } });
            if (result.status == 200) {
                setTitle('Success');
                setMessage('Machine created successfully');
                setMessageModal(true);
                handleConfirm();
            }
        } catch (error) {
            setTitle('Error');
            setMessage('Error while creating machine');
            setMessageModal(true);
        }
    }

    return (
        <>
            <CModal alignment="center" backdrop='static' size="lg" visible={isOpen} onClose={() => handleCancel()}>
                <CModalHeader onClose={() => handleCancel()}>
                    <CModalTitle><h3><strong>Add a new machine</strong></h3></CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <CRow>
                        <CCol>
                            <CFormLabel>Machine Name</CFormLabel>
                            <CFormInput value={machineName} onChange={handleMachineName} placeholder="App, Easy etc." />
                        </CCol>
                        <CCol>
                            <CFormLabel>Machine Code</CFormLabel>
                            <CFormInput value={machineCode} onChange={handleMachineCode} placeholder="M1, M2 etc." />
                        </CCol>
                    </CRow>
                </CModalBody>
                <CModalFooter>
                    <CButton color="success" onClick={() => handleMachineConfirm()} >Confirm</CButton>{' '}
                    <CButton color="danger" onClick={() => handleCancel()}>Cancel</CButton>
                </CModalFooter>
            </CModal>
            {/* Message Modal */}
            <AppMessageModal isOpen={messageModal} title={title} message={message} handleClose={() => setMessageModal(false)} />
            {/* Confirm Modal */}
            <AppConfirmModal isOpen={confirmModal} title={title} message={message} onCancel={() => setConfirmModal(false)} onConfirm={() => createMachine()} />
        </>
    );
}

export default AddMachine;